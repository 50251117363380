@import '../../color_variables.scss';
@import '../../size_variables';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$content-max-width: 75em;

$background-dark-color: #212121;
$default-green-color: #809e5f;
$default-red-color: #e08283;
$default-dark-blue: #2c3e50;
$default-text-color: #333;
$default-light-blue: #3598dc;

.diet-sample {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 6em 0 0;
  // background: #212930;
  background: radial-gradient(#353535, $background-dark-color);
  flex: 1;
  min-height: 10em;

  @media (max-width: 1030px) {
    margin: 5em 0 0;
  }

  @media (max-width: 910px) {
    // margin: 8em 0 0;
    margin: 3.8em 0 0;
  }

  // @media (max-width: 750px) {
  //   margin: 4.3em 0 0;
  // }

  @media (max-width: 490px) {
    margin: 3.4em 0 0;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    z-index: 2;
    max-width: $content-max-width;
    padding: 3.5em 0.8em 2.5em;
    color: white;
    flex: 1;

    @media (max-width: $large-width-breakpoint) {
      padding: 3.5em 0.8em 2.5em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      padding: 3em 1.5em 2.5em;
    }
    @media (max-width: $high-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      padding: 2.5em 1.2em;
    }
    @media (max-width: $medium-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      padding: 2.5em 1em;
    }
    @media (max-width: $small-width-breakpoint) {
      padding: 1.5em 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      padding: 1.2em 0.8em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 1.2em 0.4em;
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100vh;
  }

  &__warning-container {
    margin: 0 0 0.6em;
    width: 100%;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 0.5em;
    width: 100%;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__cancel-exam-button {
    margin: 1em 0 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background: $site-red-color;
    cursor: pointer;
    padding: 0.6em 1em;
    font-size: 1.5em;
    color: white;
    border: none;
    border-radius: 5px;
    align-self: center;
    text-align: justify;
    outline: none;
    user-select: none;
    text-decoration: none;

    @media (max-width: 960px) {
      font-size: 1.3em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 1.1em;
      padding: 0.7em 1em;
    }

    &__icon {
      font-size: 2em;
      margin: 0 20px 0 0;
      color: $site-red-color;
    }
  }

  &__exam-canceld-icon {
    position: relative;
    font-size: 6em;
    color: $default-green-color;

    @media (max-width: $small-width-breakpoint) {
      font-size: 5em;
    }
  }

  &__delete-recipe-association-button,
  &__edit-recipe-association-button,
  &__delete-ingredient-button,
  &__add-food-recipe-button {
    font-family: 'Open Sans', sans-serif;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    align-self: stretch;

    &:disabled {
      background: #dcdcdc;
      color: #949494;
      cursor: default;
    }
  }

  &__add-food-recipe-button {
    margin: 0.8em 0 0;
    background: $default-green-color;
    color: white;

    &:hover:not(:disabled) {
      background: darken($default-green-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__delete-recipe-association-button,
  &__delete-ingredient-button {
    margin: 0.5em;
    background: $default-red-color;
    color: white;
    border-radius: 3px;

    &:hover:not(:disabled) {
      background: darken($default-red-color, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__edit-recipe-association-button {
    margin: 0.5em 0;
    background: $default-light-blue;
    color: white;
    border-radius: 3px;

    &:hover:not(:disabled) {
      background: darken($default-light-blue, 15);
    }

    &__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__recipe-association {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 2px 2px 3px 1px #949494;
    border-radius: 2px;
    background: white;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
    }

    & + & {
      margin: 0.6em 0 0;
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      padding: 0.4em 0.4em 0.4em 0;
      min-width: 0;
      flex: 1;

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }
    }

    &__content {
      font-size: 1.2em;
      font-weight: bold;
      margin: 0 0 0 0.8em;
      color: #5d5d5d;
      width: 10em;
    }

    &__detail {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      color: white;
      background: $default-dark-blue;
      padding: 0.2em 0.4em;
      align-self: stretch;
      border-radius: 2px 0 0 2px;
    }

    &__inputs-container {
      width: auto;
      align-self: stretch;
      margin: 0 0 0 0.8em;
    }

    &__ingredient-list {
      list-style: disc inside;
      font-size: 0.85em;
      margin: 0.4em 0 0 1.85em;
      padding: 0.2em 0.5em;
      background: #bdbdbd;
      color: #6f6f6f;
      border-radius: 0.2em;
      align-self: flex-start;

      @media (max-width: $small-width-breakpoint) {
        margin: 0.4em 0 0 1.35em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.9em;
      }

      &__item {
        color: #777;
        font-size: 1em;
      }
    }

    &__weekdays {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 0.1em 0 0 1.5em;

      @media (max-width: $small-width-breakpoint) {
        margin: 0.2em 0 0 1em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
      }
    }

    &__sub-title {
      margin: 0.5em 0 0 1.5em;
      color: $default-text-color;
      text-decoration: underline;

      @media (max-width: $small-width-breakpoint) {
        margin: 0.5em 0 0 1em;
      }
    }
  }

  &__food-allergens,
  &__meal-period-container,
  &__ingredients-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.8em;
    background: #f7f7f7;
    margin: 0;
    overflow: auto;

    &:not(:last-child) {
      margin: 0 0 1.4em;
    }

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2c3e50;
      border-radius: 0.4em;
    }
  }

  &__meal-period {
    &__description {
      margin: 0 0 0.5em;
      text-align: justify;
      background: #f5f5f5;
      color: #464646;
      padding: 0.4em;
      white-space: pre-wrap;

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 0.9em;
      }
    }
  }

  &__main-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
  }

  &__overlay {
    &,
    &--fill-height {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em 1.5em 0.8em;
      min-width: 90vw;
    }

    &--fill-height {
      width: 100%;
      height: 90%;
      margin: 0;
      align-self: center;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      &__title {
        font-family: 'Open Sans', sans-serif;
        // font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #3a3839;
        font-size: 2em;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }

      &__red-text {
        color: #a75050;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.25em;
          height: 0.25em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3a3839;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 1.3em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    &__input-label {
      color: $default-text-color;
    }

    &__input:focus {
      border-color: #606060;
    }

    &__reference-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex: 1;
      background: center no-repeat url(../../images/preloader.gif), white;
    }

    &__horizontal-rule {
      width: 100%;
      border: 0 solid #eee;
      border-width: 1px 0 0;
      margin: 0.5em 0 1em;
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid #3a3839;
    border-width: 2px 0 0;
    margin: 0.5em 0;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      border-width: 1px 0 0;
    }
  }

  &__ingredient {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 2px 2px 3px 1px #949494;
    border-radius: 2px;
    background: white;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
    }

    & + & {
      margin: 0.6em 0 0;
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      padding: 0.4em 0.4em 0.4em 0;
      min-width: 0;
      flex: 1;

      @media (max-width: $smaller-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }
    }

    &__content {
      font-size: 1.2em;
      font-weight: bold;
      margin: 0 0 0 0.8em;
      color: #5d5d5d;
      width: 10em;
    }

    &__detail {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      color: white;
      background: $default-dark-blue;
      padding: 0.2em 0.4em;
      align-self: stretch;
      border-radius: 2px 0 0 2px;
    }

    &__inputs-container {
      width: auto;
      align-self: stretch;
      margin: 0 0 0 0.8em;
    }
  }

  &__sub-section-title {
    color: white;
    margin: 0 0 0.8em;
  }

  &__weekday {
    background: #2c3e50;
    color: white;

    &,
    &--disabled {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.3em 0 0.3em 0.6em;
      border-radius: 3px;
      text-align: center;
      white-space: nowrap;
      margin: 0 0.5em 0.4em 0;
      width: 8em;
      font-size: 0.95em;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.1em 0 0.1em 0.4em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        width: auto;
      }

      @media (max-width: $tiny-width-breakpoint) {
        flex: 1;
      }
    }

    &--disabled {
      background: #dcdcdc;
      color: $default-gray-color;
    }

    &__text {

    }

    &__select-button {
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      outline: none;
      background: none;
      min-width: 35px;
      min-height: 30px;
      color: white;
      border: 1px solid white;
      border-radius: 3px;
      margin: 0 0.2em 0;
      font-size: 1.2em;
    }

    &--disabled &__select-button {
      color: $default-gray-color;
      border-color: $default-gray-color;
      color: $default-gray-color;
    }
  }

  &__food-allergens {
    &__instructions {
      font-size: 0.9em;
      text-transform: uppercase;
      color: #333;
      font-weight: bold;
      margin: 0 0 1.5em;
      text-align: justify;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }
    }

    &__list {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: flex-start;
      gap: 1em;
    }

    &__input-label {
      color: $default-text-color;
      font-size: 0.85em;
      margin: 0 0 0.1em;
    }

    &__input {
      margin: 0 !important;
      width: 8.5em;
      justify-content: space-between;
    }

    &__input .default-input__toggle-visual--inactive-neutral::before {
      background: rgba(255, 200, 0, 0.65);
    }
  }

  &__description {
    margin: 0 0 1.5em;

    &__input {
      background: #f7f7f7;
      color: $default-text-color;
    }
  }

  &__tutorial-video {
    position: absolute;
    border-radius: 2px;
  }

  &__tutorial-video-button {
    display: inline;
    background: none;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    outline: none;
    user-select: none;
    text-decoration: none;
    font-size: 1em;
    color: #a75050;
    font-weight: bold;
    text-decoration: underline;
    text-transform: uppercase;
  }
}
