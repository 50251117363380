@import '../color_variables.scss';

$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$default-animation-period: 0.6s;

@keyframes promotion-animation-close-button {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes promotion-animation-image {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes promotion-animation-background {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes promotion-animation-text {
  from {
    opacity: 0;
    transform: rotate(-8deg) translate(-5em, 0);
    animation-timing-function: ease-out;
  }

  20% {
    animation-timing-function: linear;
    opacity: 1;
    transform: rotate(-8deg) translate(-1em, 0);
  }

  80% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: rotate(-8deg) translate(1em, 0);
  }

  to {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: rotate(-8deg) translate(5em, 0);
  }
}

@keyframes promotion-animation-rect-top {
  from {
    transform: rotate(-8deg) translate(-130%, -10em);
  }

  30% {
    transform: rotate(-8deg) translate(0, -10em);
  }

  70% {
    transform: rotate(-8deg) translate(0, -10em);
  }

  to {
    transform: rotate(-8deg) translate(130%, -10em);
  }
}

@keyframes promotion-animation-rect-bottom {
  from {
    transform: rotate(-8deg) translate(130%, 10em);
  }

  30% {
    transform: rotate(-8deg) translate(0, 10em);
  }

  70% {
    transform: rotate(-8deg) translate(0, 10em);
  }

  to {
    transform: rotate(-8deg) translate(-130%, 10em);
  }
}

@keyframes promotion-animation-decoration-top {
  from {
    opacity: 0;
    transform: rotate(-8deg) translate(100%, -8em);
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate(-8deg) translate(10%, -8em);
  }
}

@keyframes promotion-animation-decoration-bottom {
  from {
    opacity: 0;
    transform: rotate(-8deg) translate(-100%, 8em);
  }

  30% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate(-8deg) translate(-10%, 8em);
  }
}

.promotion {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 6;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(#424242, #252525);
    animation: promotion-animation-background 0.3s ease-in both;
  }

  &__wrapper {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 2.5em 6em;

    @media (max-width: $medium-plus-width-breakpoint) {
      padding: 3.8em;
    }
    @media (max-width: $small-width-breakpoint) {
      padding: 3.6em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      padding: 3.2em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 3.2em 0.5em;
    }
  }

  &__image,
  &__video {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0.8em;
    border: 4px dashed #ff4444;
    animation: promotion-animation-image 0.3s ease-out 4.4s both;
    cursor: pointer;

    @media (max-width: $very-small-width-breakpoint) {
      border-radius: 0.4em;
      border-width: 3px;
    }
    @media (max-width: $tiny-width-breakpoint) {
      border-radius: 0.2em;
      border-width: 2px;
    }

    &--hidden {
      display: none;
    }
  }

  &__video {
    width: 100%;
    height: 80%;
  }

  &__close-button {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    top: 0.5em;
    right: 0.5em;
    background: none;
    font-size: 2.5em;
    border: 4px solid white;
    color: white;
    padding: 0.5em;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
    animation: promotion-animation-close-button 0.3s ease-out 4.4s both;

    @media (max-width: $medium-plus-width-breakpoint) {
      font-size: 2em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 1.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1.65em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 1.55em;
    }

    &:hover {
      color: #ff4444;
      border-color: #ff4444;
    }
  }

  &__animation-rect {
    &--top,
    &--bottom {
      position: absolute;
      width: 150vw;
      height: 45vh;
      background: #ff4444;
      will-change: transform;
      clip-path: polygon(100% 0, 85% 100%, 0 100%, 15% 0);

      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.8em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.7em;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 0.6em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.4em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.3em;
      }
    }

    &--top {
      bottom: 50vh;
      transform-origin: bottom center;
      animation: promotion-animation-rect-top 5s ease-out both;
    }
    &--bottom {
      top: 50vh;
      transform-origin: top center;
      animation: promotion-animation-rect-bottom 5s ease-out both;
    }
  }

  &__animation-decoration {
    &--top,
    &--bottom {
      position: absolute;
      width: 30vw;
      height: 0.3em;
      background: white;
      border-radius: 0.15em;
      transform-origin: center;

      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.8em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.7em;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 0.6em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.4em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.3em;
        height: 0.6em;
      }
    }

    &--top {
      animation: promotion-animation-decoration-top 3s linear both 1s;
      box-shadow: 3em 0.35em #ff4444;
    }
    &--bottom {
      animation: promotion-animation-decoration-bottom 3s linear both 1s;
      box-shadow: -3em -0.35em #ff4444;
    }
  }

  &__animation-text {
    font-family: 'Black Ops One', cursive;
    position: absolute;
    text-transform: uppercase;
    font-size: 6em;
    color: white;
    will-change: transform;
    text-shadow: 7px 6px #ff4444;
    animation: promotion-animation-text 4s both 0.5s;

    @media (max-width: $medium-plus-width-breakpoint) {
      font-size: 5.5em;
    }
    @media (max-width: $medium-width-breakpoint) {
      font-size: 5em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 4.5em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 4em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 3em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 2em;
    }
  }
}
