@import '../color_variables.scss';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$default-animation-period: 0.6s;
$default-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.31, 1.78);

$content-max-width: 75em;

$background-dark-color: #212121;

.training-hours {
  margin: 6em 0 0;

  @media (max-width: 1030px) {
    margin: 5em 0 0;
  }
  @media (max-width: 910px) {
    margin: 8em 0 0;
  }
  @media (max-width: 750px) {
    margin: 4.3em 0 0;
  }
  @media (max-width: 490px) {
    margin: 3.7em 0 0;
  }

  &,
  &--promotion-enalbed {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    // background: #212930;
    background: radial-gradient(#353535, $background-dark-color);
    flex: 1;
    min-height: 10em;
  }

  &--promotion-enalbed {
    margin: 11em 0 0;

    @media (max-width: 1030px) {
      margin: 10em 0 0;
    }
    @media (max-width: 910px) {
      margin: 13em 0 0;
    }
    @media (max-width: 750px) {
      margin: 9.3em 0 0;
    }
    @media (max-width: 490px) {
      margin: 8.7em 0 0;
    }
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    z-index: 1;
    max-width: $content-max-width;
    padding: 3.5em 0.8em 3em;
    color: white;
    flex: 1;

    @media (max-width: $large-width-breakpoint) {
      padding: 3.5em 0.8em 3em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      padding: 3em 1.8em;
    }
    @media (max-width: $high-width-breakpoint) {
      padding: 2.5em 1.8em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      padding: 2.5em 1.2em;
    }
    @media (max-width: $medium-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      padding: 2.5em 1em;
    }
    @media (max-width: $small-width-breakpoint) {
      padding: 1.5em 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      padding: 1.2em 0.8em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 1.2em 0.4em;
    }
  }

  &__title {
    font-size: 2.2em;
    font-weight: 700;
    // text-transform: uppercase;

    @media (max-width: $large-width-breakpoint) {
      font-size: 2.1em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      font-size: 2em;
    }
    @media (max-width: $high-width-breakpoint) {
      font-size: 1.8em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      font-size: 1.7em;
    }
    @media (max-width: $medium-width-breakpoint) {
      align-self: center;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.6em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 1.45em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1.3em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 1.2em;
      text-align: center;
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100vh;
  }

  &__entries-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 1.5em 0 0;

    @media (max-width: $high-width-breakpoint) {
      margin: 1em 0 0;
    }
  }

  &__warning-message {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    align-self: center;
    margin: 3em 0 0;
    max-width: 40em;
    background: white;
    border-radius: 5px;
    font-size: 1em;

    @media (max-width: $large-width-breakpoint) {
      font-size: 0.95em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      font-size: 0.9em;
      margin: 2.5em 0 0;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      margin: 2em 0 0;
    }
    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.85em;
      margin: 1.5em 0 0;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      margin: 1em 0 0;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 0.8em;
      margin: 0.5em 0 0;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.75em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: justify;
      font-size: 1.1em;
      font-weight: 600;
      flex: 1;
      color: $site-red-color;
      padding: 0.5em 1em;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: $site-red-color;
      color: white;
      padding: 0.8em;
      border-radius: 5px 0 0 5px;
      font-size: 1.8em;
    }
  }

  &__service-entry {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    $left-padding-width: 3.5em;

    &:not(:first-of-type) {
      margin: 1em 0 0;
    }

    &__title {
      padding: 0 0 0 0.4em;
      font-weight: 500;
      border: 0 solid $site-red-color;
      border-left-width: 5px;
      font-size: 1.5em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.4em;
      }
      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 1.35em;
      }
      @media (max-width: $high-width-breakpoint) {
        font-size: 1.3em;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 1.25em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.2em;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 1.1em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.05em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1em;
      }
    }

    &__info-container {
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      align-self: flex-start;
      background: white;
      margin: 1em 0 0;
      border-radius: 5px;
      max-width: 100%;
      overflow: auto;
      font-size: 1em;

      @media (max-width: $high-width-breakpoint) {
        margin: 0.6em 0 0;
        font-size: 0.95em;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.85em;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.75em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.7em;
      }

      &::-webkit-scrollbar {
        width: 1em;
        height: 1em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 0.2em;
      }
    }

    &__division-container {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
    }

    &__vertical-division {
      position: absolute;
      display: block;
      height: 100%;
      width: 1px;
      background: #28282840;
      margin: 0 0 0 $left-padding-width;
    }

    &__header-container,
    &__header-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
    }

    &__header-container {
      border: 0 solid $background-dark-color;
      border-bottom-width: 1px;
      padding: 0.3em 0;
    }

    &__left-padding {
      display: block;
      flex-shrink: 0;
      width: $left-padding-width;
    }

    &__time {
      position: absolute;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: #ff6879;
      border-radius: 1em;
      padding: 0.1em 0;
      z-index: 6;

      &__text {
        color: white;
        font-weight: 500;
      }
    }

    &__hour-label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      width: $left-padding-width;

      &__text {
        color: $background-dark-color;
        font-weight: 500;
        margin: 0 0 0 0.3em;
      }
    }

    &__day-row {
      padding: 0.3em 0;

      &:nth-child(odd) {
        background: #ff3d53c7;
      }

      &:not(:last-of-type) {
        border: 0 solid $background-dark-color;
        border-bottom-width: 1px;
      }

      &,
      &__days-container {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
      }

      &__title {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        width: $left-padding-width;

        &__text {
          font-weight: 500;
          color: $background-dark-color;
        }
      }

      &:nth-child(odd) &__title__text {
        color: white;
      }
    }

    &__day-row:nth-child(odd) &__time {
      background: white;

      &__text {
        color: $site-red-color;
      }
    }
  }
}
