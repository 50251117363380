@import '../color_variables.scss';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$default-animation-period: 0.6s;
$default-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.31, 1.78);

$content-max-width: 75em;

$background-dark-color: #212121;

.services {
  margin: 6em 0 0;

  @media (max-width: 1030px) {
    margin: 5em 0 0;
  }
  @media (max-width: 910px) {
    margin: 8em 0 0;
  }
  @media (max-width: 750px) {
    margin: 4.3em 0 0;
  }
  @media (max-width: 490px) {
    margin: 3.7em 0 0;
  }

  &,
  &--promotion-enalbed {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    // background: #212930;
    background: radial-gradient(#353535, $background-dark-color);
    flex: 1;
    min-height: 10em;
  }

  &--promotion-enalbed {
    margin: 11em 0 0;

    @media (max-width: 1030px) {
      margin: 10em 0 0;
    }
    @media (max-width: 910px) {
      margin: 13em 0 0;
    }
    @media (max-width: 750px) {
      margin: 9.3em 0 0;
    }
    @media (max-width: 490px) {
      margin: 8.7em 0 0;
    }
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    z-index: 1;
    max-width: $content-max-width;
    padding: 3.5em 0.8em 2em;
    color: white;
    flex: 1;

    @media (max-width: $large-width-breakpoint) {
      padding: 3.5em 0.8em 1.5em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      padding: 3em 1.5em 1.5em;
    }
    @media (max-width: $high-width-breakpoint) {
      padding: 2.5em 1.5em 1.5em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      padding: 2.5em 1.2em 1.5em;
    }
    @media (max-width: $medium-width-breakpoint) {
      padding: 2.5em 1.5em 1.5em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      padding: 2.5em 1em 1.5em;
    }
    @media (max-width: $small-width-breakpoint) {
      padding: 1.5em 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      padding: 1.2em 0.8em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 1.2em 0.4em;
    }
  }

  &__title,
  &__longer-title {
    font-size: 2.2em;
    font-weight: 700;
    // text-transform: uppercase;

    @media (max-width: $large-width-breakpoint) {
      font-size: 2.1em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      font-size: 2em;
    }
    @media (max-width: $high-width-breakpoint) {
      font-size: 1.8em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      font-size: 1.7em;
    }
    @media (max-width: $medium-width-breakpoint) {
      align-self: center;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.6em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 1.45em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1.3em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 1.2em;
      text-align: center;
    }
  }

  &__longer-title {
    text-align: center;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.5em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1.25em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 1.1em;
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100vh;
  }

  &__entries-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 1.5em 0 0;

    @media (max-width: $medium-width-breakpoint) {
      margin: 1em 0 0;
    }
  }

  &__category {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    &:not(:first-of-type) {
      margin: 1em 0 0;

      @media (max-width: $very-small-width-breakpoint) {
        margin: 0.7em 0 0;
      }
    }

    &__title {
      padding: 0 0 0 0.4em;
      font-weight: 500;
      border: 0 solid $site-red-color;
      border-left-width: 5px;
      font-size: 1.5em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.4em;
      }
      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 1.35em;
      }
      @media (max-width: $high-width-breakpoint) {
        font-size: 1.3em;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 1.25em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.2em;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 1.1em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.05em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1em;
      }
    }

    &__option-list {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: flex-start;
      list-style: none;
      margin: 1em 0 0;

      @media (max-width: $very-small-width-breakpoint) {
        flex-flow: column nowrap;
      }
    }

    &__option {
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 0 2.5em 2em 0;
      max-width: 26em;

      @media (max-width: $high-width-breakpoint) {
        font-size: 0.95em;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.82em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.75em;
        max-width: 23em;
      }
      @media (max-width: 660px) {
        max-width: none;
      }
      @media (max-width: $small-width-breakpoint) {
        margin: 0 2em 2em 0;
      }
      @media (max-width: $very-small-width-breakpoint) {
        margin: 0 0.3em 2em 0;
      }
      @media (max-width: $tiny-width-breakpoint) {
        margin: 0 0.7em 2em 0.5em;
      }

      &__background {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        transform-origin: center;
        transform: translate(-0.4em, 0.4em) rotate(-1.5deg);
        background: $site-red-color;
        border-radius: 3px;
        z-index: -1;
      }

      &__row {
        border: 0 solid $background-dark-color;
        border-bottom-width: 1px;

        &,
        &--no-border {
          display: flex;
          flex-flow: row nowrap;
          align-items: stretch;
          justify-content: space-between;
          flex: 1;
          border-radius: 3px 3px 0 0;
          background: white;
        }
      }

      &__name {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        color: $default-text-color;
        padding: 0.5em 1.5em 0.5em 0.8em;
        flex: 1;
        max-width: 15em;
        text-align: center;
        font-weight: 600;

        @media (max-width: 660px) {
          max-width: none;
        }
      }

      &__highlight-container {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        font-size: 1.5em;

        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 1.25em;
        }
      }

      &__highlight-background {
        position: absolute;
        display: block;
        z-index: 1;
        transform-origin: center;
        width: 120%;
        height: 110%;
        border-radius: 3px;
        background: $site-red-color;
        transform: rotate(4deg);
      }

      &__period,
      &__price {
        z-index: 2;
        font-weight: 700;
      }

      &__suffix {
        font-size: 0.5em;
      }

      &__generic-text {
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
        justify-content: center;
        text-align: center;
        padding: 0.8em 0 0.5em;
        border: 0 solid #a9a9a9;
        border-bottom-width: 1px;

        &,
        &--topic {
          font-size: 0.9em;
          background: white;
          color: $default-text-color;
        }

        &--topic {
          text-decoration: underline;
          padding: 0.4em 0 0.4em 1em;
        }
      }

      &__combo-items {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
        background: white;
        border-radius: 0 0 3px 3px;
        padding: 0 0.5em 0;
      }

      &__combo-item {
        &,
        &--gold {
          font-size: 0.9em;
          padding: 0.4em 0.8em;
          background: transparent;
          border: 1px solid $background-dark-color;
          color: $background-dark-color;
          margin: 0 0.5em 0.5em 0;
          border-radius: 1em;
        }

        &--gold {
          background: linear-gradient(161deg, #d9cc6e 20%, #fffedb, #d9cc6e 80%);
          border: 1px solid #b7ab6d;
          box-shadow: 1px -1px inset white;
          color: #7d7a49;
          font-weight: bold;
          box-shadow: 1px -1px 1px white;
        }
      }

      &__restriction-button {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
        font-size: 1.25em;
        padding: 0;
        margin: 0;
        font-weight: 400;
        background: none;
        border: none;
        color: $background-dark-color;
        min-width: 35px;
        min-height: 35px;
        transition: $default-transition;
        -webkit-tap-highlight-color:  transparent;
        box-shadow: none;
        outline: none;
        user-select: none;

        &:hover {
          color: $site-red-color;
          // transform: scale(1.05, 1.05);
        }
      }
    }

    &__experimental-link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.4em;
      padding: 0.4em 0;
      font-weight: 700;
      width: 24.5em;
      border-radius: 3px;
      min-width: 35px;
      min-height: 35px;
      transition: $default-animation-period;
      color: white;
      background: $site-red-color;

      &:hover {
        color: $default-text-color;
        background: white !important;
      }

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        width: auto;
        text-align: center;
        padding: 0.4em 1em;
      }
    }
  }

  &__time-restrictions {
    &--hidden {
      display: none;
    }

    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    z-index: 6;
    will-change: transform;
    top: 0;
    left: 0;
    opacity: 0;

    // @media (max-width: $smaller-width-breakpoint) {
    //   align-items: stretch;
    // }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      background: white;
      margin: 0.4em;
      border-radius: 3px;
      box-shadow: 3px 3px 3px #65656580;
      max-width: 98%;
      transform-origin: center;

      // @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
      //   font-size: 0.95em;
      // }
      //
      // @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      //   font-size: 0.9em;
      //   border-radius: 4px;
      // }
      //
      // @media (max-width: $smaller-width-breakpoint) {
      //   margin: 6.2em 0.4em 0;
      //   padding: 0.8em 0.5em !important;
      // }
    }

    &__restrictions-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      max-height: 70vh;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 1em;
        height: 1em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $site-red-color;
        border-radius: 0.2em;
      }
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      border: 0 solid $background-dark-color;
      border-bottom-width: 1px;
      padding: 0 0 0 1em;

      &__title {
        font-weight: 700;
        font-size: 1.3em;
        color: $site-red-color;
      }

      &__close-button {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
        font-size: 1.9em;
        padding: 0;
        border: 2px solid transparent;
        color: white;
        background: $site-red-color;
        border-radius: 50%;
        width: 1.5em;
        height: 1.5em;
        min-width: 35px;
        min-height: 35px;
        transition: $default-transition;
        -webkit-tap-highlight-color:  transparent;
        box-shadow: none;
        outline: none;
        user-select: none;
        transform-origin: center;
        margin: 0 0 0 0.6em;
        left: 0.6em;
        bottom: 0.6em;

        &:hover {
          // border: 2px solid $site-red-color;
          // color: $site-red-color;
          // background: white;
          transform: scale(1.15, 1.15);
        }
      }
    }

    &__restriction {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0.5em 0.8em;

      &:not(:first-of-type) {
        border: 0 solid #a9a9a9;
        border-top-width: 1px;
      }

      &__service-title {
        text-decoration: underline;
        font-weight: 400;
        font-size: 1.1em;
        margin: 0 0 0.4em;
        color: $background-dark-color;
      }

      &__weekday-list {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        list-style: none;
      }

      &__weekday {
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
        justify-content: flex-start;

        &__label {
          font-weight: 600;
          margin: 0 0.4em 0 0;
          color: $background-dark-color;
          width: 2.5em;
        }

        &__times {
          display: flex;
          flex-flow: row wrap;
          align-items: baseline;
          justify-content: flex-start;
        }

        &__time {
          font-size: 0.9em;
          padding: 0.4em 0.6em;
          color: white;
          background: $background-dark-color;
          border-radius: 1em;
          margin: 0 0.2em 0.2em 0;
          font-weight: 300;
        }
      }

      &__generic-text {
        margin: 0.2em 1em;
        text-align: center;
        font-size: 0.85em;
        font-weight: 700;
        color: $background-dark-color;
      }
    }
  }

  &__warning-message {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    align-self: center;
    margin: 3em 0 0;
    max-width: 40em;
    background: white;
    border-radius: 5px;
    font-size: 1em;

    @media (max-width: $large-width-breakpoint) {
      font-size: 0.95em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      font-size: 0.9em;
      margin: 2.5em 0 0;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      margin: 2em 0 0;
    }
    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.85em;
      margin: 1.5em 0 0;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      margin: 1em 0 0;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 0.8em;
      margin: 0.5em 0 0;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.75em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: justify;
      font-size: 1.1em;
      font-weight: 600;
      flex: 1;
      color: $site-red-color;
      padding: 0.5em 1em;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: $site-red-color;
      color: white;
      padding: 0.8em;
      border-radius: 5px 0 0 5px;
      font-size: 1.8em;
    }
  }

  &__header-message {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    align-self: center;
    background: white;
    border-radius: 5px;
    font-size: 1em;
    margin: 1.5em 0 0;

    @media (max-width: $large-width-breakpoint) {
      font-size: 0.95em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      font-size: 0.9em;
    }
    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.85em;
      margin: 1em 0 0;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.75em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }

    &__text-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
    }

    &__text {
      text-align: justify;
      font-size: 1.1em;
      font-weight: 600;
      flex: 1;
      color: $background-dark-color;
      padding: 0.5em 1em;

      & strong {
        text-decoration: underline;
        font-weight: inherit;
      }
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: linear-gradient(50deg, #eac724 0%, #fff7d3, #eac724);
      color: $background-dark-color;
      padding: 0.8em;
      border-radius: 5px 0 0 5px;
      font-size: 1.8em;
    }
  }

  &__group-description {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    background: white;
    font-size: 0.9em;
    margin: 0.4em 0 0;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.85em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.75em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: justify;
      font-size: 1.1em;
      font-weight: normal;
      flex: 1;
      color: #3c3c3c;
      padding: 0.5em 1em;
      white-space: pre-wrap;
    }
  }

  &__video {
    border: 1px solid $site-red-color;
    border-radius: 3px;
    height: 50vh;

    @media (max-width: $medium-plus-width-breakpoint) {
      height: 45vh;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      height: 40vh;
    }
    @media (max-width: $small-width-breakpoint) {
      height: 35vh;
    }
    @media (max-width: $very-small-width-breakpoint) {
      height: 30vh;
    }

    &,
    &--hidden {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0.5em 0;
      overflow: hidden;
      transition: all 0.5s cubic-bezier(0.65, -0.62, 0.6, 1);
    }

    &--hidden {
      height: 0;
      margin: 0;
      border: none;
    }
  }

  &__unlock-controls {
    &,
    &--locked,
    &--unlocked {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 1em 0.4em;
      overflow: hidden;
      transition: all 0.5s cubic-bezier(0.65, -0.62, 0.6, 1);
      background: transparent;

      @media (max-width: $medium-less-width-breakpoint) {
        padding: 0.8em 0.2em;
      }
    }

    &__button {
      &,
      &--gold,
      &--silver {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1.4em;
        padding: 0.4em 0;
        font-weight: 700;
        width: 24.5em;
        border-radius: 3px;
        min-width: 35px;
        min-height: 35px;
        transition: $default-animation-period;
        background: $site-red-color;

        &:not(:first-child) {
          margin: 0 0 0 0.4em;
        }

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $high-width-breakpoint) {
          font-size: 1.1em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 1.1em;
          align-self: stretch;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.9em;
        }
      }

      &--gold {
        color: #6a6a6a;
        text-shadow: 0 2px 2px rgb(250, 227, 133);
        background-image: linear-gradient(344deg, #8d8444, #a59a4f, #f7efb6, #a59a4f, #8d8444);
        background-position: center;
        background-size: 100% 100%;
        border: 1px outset #c5b33b;

        &:hover {
          background-size: 150% 150%;
          box-shadow: 0 0 6px #efd8359e;
        }
      }

      &--silver {
        color: #68654a;
        text-shadow: 0 2px 2px #ededed;
        background-image: linear-gradient(344deg, #6c6c6c, #9d9d9d, #f1f1f1, #9d9d9d, #6c6c6c);
        background-position: center;
        background-size: 100% 100%;
        border: 1px outset #afafaf;

        &:hover {
          background-size: 150% 150%;
          box-shadow: 0 0 6px #f1f1f19e;
        }
      }

      &__text {
        font-size: 1.15em;
        text-align: center;

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1em;
        }

        &--small {
          font-size: 0.8em;
        }
      }
    }

    &__lock-cover {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, transparent, #ffffffa3 20%, #ffffffa3 80%, transparent);
      will-change: transform;
      transform: translateY(0);
      opacity: 1;
      transition: transform 0.5s cubic-bezier(0.65, -0.62, 0.6, 1) 0.5s, opacity 0.5s linear 1s;

      &__text {
        text-transform: uppercase;
        text-decoration: underline;
        text-align: center;
        color: $background-dark-color;
        font-weight: bold;
        font-size: 0.9em;
        margin: 2.8em 0 0;

        @media (max-width: $high-width-breakpoint) {
          font-size: 0.85em;
        }

        @media (max-width: $small-width-breakpoint) {
          max-width: 20em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          max-width: 18em;
          font-size: 0.8em;
          margin: 3em 0 0;
        }
      }

      &__locked-icon,
      &__unlocked-icon {
        margin: 0 0 0.8em;
        font-size: 2.5em;
        color: $background-dark-color;
        text-shadow: 1px 1px 1px white;
        position: absolute;
        opacity: 1;
        transition: opacity 0.5s cubic-bezier(0.17, 0.67, 0.31, 1.78);

        @media (max-width: $large-width-breakpoint) {
          font-size: 2.2em;
        }

        @media (max-width: $high-width-breakpoint) {
          font-size: 2.0em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.6em;
        }
      }
    }

    &--unlocked &__lock-cover {
      transform: translateY(-101%);
      opacity: 0;
      pointer-events: none;
    }

    &--unlocked &__lock-cover__locked-icon {
      opacity: 0;
      transition-delay: 0;
    }
    &--unlocked &__lock-cover__unlocked-icon {
      opacity: 1;
      transition-delay: 0.1s;
    }

    &--locked &__lock-cover__locked-icon {
      opacity: 1;
      transition-delay: 0.1s;
    }
    &--locked &__lock-cover__unlocked-icon {
      opacity: 0;
      transition-delay: 0;
    }
  }
}
