@import '../../color_variables.scss';
@import '../../size_variables.scss';

$group-title-color: #9e9fa1;

.user-registration-questions {
  &__row-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    .text-question__wrapper,
    .text-question__wrapper--short,
    .date-question__wrapper--short,
    .date-question__wrapper,
    .select-question__wrapper--short,
    .select-question__wrapper {
      margin: 0;

      &:not(:first-of-type) {
        margin: 0 0 0 20px;
      }
    }

    &:not(:first-of-type) {
      margin: 15px 0 0;
    }

    @media (max-width: $small-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;

      .text-question__wrapper,
      .text-question__wrapper--short,
      .date-question__wrapper--short,
      .date-question__wrapper,
      .select-question__wrapper--short,
      .select-question__wrapper {
        &:not(:first-of-type) {
          margin: 15px 0 0 0;
        }
      }
    }
  }

  &__icon {
    margin: 0 0 5px;
    font-size: 3.05em;
  }

  &__question-group {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    &__group-title {
      color: $group-title-color;
      margin: 0 0 15px;
    }
  }

  &__disc-example-header {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: 0 0 15px;

    &__title {
      font-weight: bold;
      margin: 0 0 5px;
      text-align: justify;
    }

    &__image {
      margin: 5px 0 10px;
      max-width: 100%;
      align-self: center;
    }

    &__description {
      color: $default-gray-color;
      text-align: justify;
    }
  }
}

.hidden-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  &:not(:first-of-type) {
    margin: 15px 0 0;
  }

  @media (max-width: $small-width-breakpoint) {
    &:not(:first-of-type) {
      margin: 30px 0 0;
    }
  }
}
