@import '../../color_variables.scss';
@import '../../size_variables';

$contract-gray: #f3f3f3;
$contract-darker-gray: #d9d9d9;

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$content-max-width: 75em;

$background-dark-color: #212121;

.accept_contract {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 6em 0 0;
  // background: #212930;
  background: radial-gradient(#353535, $background-dark-color);
  flex: 1;
  min-height: 10em;

  @media (max-width: 1030px) {
    margin: 5em 0 0;
  }

  @media (max-width: 910px) {
    // margin: 8em 0 0;
    margin: 3.8em 0 0;
  }

  // @media (max-width: 750px) {
  //   margin: 4.3em 0 0;
  // }

  @media (max-width: 490px) {
    margin: 3.4em 0 0;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    z-index: 2;
    max-width: $content-max-width;
    padding: 3.5em 0.8em 2.5em;
    color: white;
    flex: 1;

    @media (max-width: $large-width-breakpoint) {
      padding: 3.5em 0.8em 2.5em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      padding: 3em 1.5em 2.5em;
    }
    @media (max-width: $high-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      padding: 2.5em 1.2em;
    }
    @media (max-width: $medium-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      padding: 2.5em 1em;
    }
    @media (max-width: $small-width-breakpoint) {
      padding: 1.5em 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      padding: 1.2em 0.8em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 1.2em 0.4em;
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100vh;
  }

  &__download-button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background: none;
    cursor: pointer;
    padding: 20px;
    font-size: 1.4em;
    color: $site-red-color;
    border: 2px solid $site-red-color;
    border-radius: 5px;
    align-self: center;
    width: 100%;
    text-align: justify;
    outline: none;
    user-select: none;
    text-decoration: none;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.3em;
      padding: 15px;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.2em;
      padding: 10px;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 1.1em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 1em;
    }

    &__icon {
      font-size: 2em;
      margin: 0 20px 0 0;
      color: $site-red-color;
    }
  }

  &__contract {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    padding: 30px 20px;;
    background: white;
    width: 100%;
    color: black;

    @media (max-width: 800px) {
      padding: 15px 10px;
    }

    @media (max-width: $tiny-height-breakpoint) {
      font-size: 0.92em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0 0 20px;

      @media (max-width: 800px) {
        margin: 0 0 10px;
      }
    }

    &__header-text {
      text-align: center;
      font-size: 1.1em;
      font-weight: bold;
      max-width: 51em;
    }

    &__section-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      border: 0 solid $contract-darker-gray;
      border-width: 1px 0 0 1px;

      &:not(:first-of-type) {
        margin: 20px 0 0;
      }
    }

    &__section-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: flex-start;
      font-size: 1em;
      background: $contract-gray;
      width: 100%;
    }

    &__section-header-attribute {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin: 0 0 0 15px;
      padding: 0 0 0 10px;
      text-transform: none;
      background: white;
      text-decoration: none;
      align-self: stretch;
      flex: 1;
    }

    &__section-header-text {
      padding: 10px 0 10px 8px;
      text-transform: uppercase;
      font-weight: bold;
      text-decoration: underline;
    }

    &__section-content {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 10px 0 10px 8px;
      width: 100%;
      text-align: justify;
    }

    &__section-paragraph {
      &:not(:first-of-type) {
        margin: 15px 0 0;
      }
    }

    &__field-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: space-between;
      width: 100%;
      font-size: 0.85em;

      &:not(:first-of-type) {
        margin: 20px 0 0;
      }

      @media (max-width: 960px) {
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;

        &:not(:first-of-type) {
          margin: 0;
        }
      }

      @media (max-width: 800px) {
        font-size: 0.95em;
      }
    }

    &__field-entry,
    &__field-entry--lose,
    &__field-entry--double {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      justify-content: flex-start;

      margin: 10px 0 0;

      @media (min-width: 960px) {
        margin: 0;

        &:not(:first-of-type):last-of-type {
          flex: 1.2;
        }
      }
    }

    &__field-entry {
      flex: 1;
    }

    &__field-entry--double {
      flex: 2;
    }

    &__field-entry &__field-label,
    &__field-entry--double &__field-label {
      min-width: 11.5em;
    }

    @media (min-width: 960px) {
      &__field-entry:not(:first-of-type) &__field-label,
      &__field-entry--double:not(:first-of-type) &__field-label {
        margin: 0 10px;
      }

      &__field-entry:nth-child(1) &__field-label,
      &__field-entry--double:nth-child(1) &__field-label {
        min-width: 9.8em;
      }

      &__field-entry + &__field-entry &__field-label {
        min-width: 8em;
      }

      &__field-entry--double + &__field-entry &__field-label,
      &__field-entry:nth-child(3) &__field-label {
        min-width: 11.5em;
      }
    }

    &__field-label {
      font-weight: bold;
      margin: 0 10px 0 0;
    }

    &__field-text {
      border: 0 dotted black;
      border-width: 0 0 1px 0;
      flex: 1;
    }

    &__table {
      border: 1px solid $contract-darker-gray;
      border-collapse: collapse;

      @media (max-width: $small-width-breakpoint) {
        width: 100%;
      }

      &__header {
        font-weight: bold;
      }

      &__header-cell,
      &__header-cell--centralized,
      &__cell {
        padding: 5px;
        border: 1px solid $contract-darker-gray;
      }

      &__header-cell,
      &__header-cell--centralized {
        min-width: 10em;
      }

      &__header-cell--centralized {
        text-align: center;
      }
    }
  }

  &__accept-input-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin: 30px 0 0;
    font-size: 1.2em;
    user-select: none;

    @media (max-width: $medium-less-width-breakpoint) {
      margin: 20px 0 0;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 15px 0 0;
    }
  }

  &__accept-input {
    margin: 0 15px 0 0;
    cursor: pointer;
    min-width: 25px;
    min-height: 25px;

    @media (max-width: 960px) {
      font-size: 1.1em;
    }
  }

  &__accept-input-label {
    cursor: pointer;
    text-transform: uppercase;
    text-align: justify;

    @media (max-width: 960px) {
      font-size: 0.9em;
    }
  }

  &__highlighted-text {
    font-size: 1.35em;
    font-weight: bold;
    color: $site-red-color;
  }

  &__instruction-text {
    color: #212731;
    border: 1px solid white;
    border-radius: 3px;
    padding: 0.4em 0.6em;
    background: white;
  }

  &__contract-container {
    margin: 30px 0 0;
    overflow: auto;
    overflow-x: hidden;
    max-height: 25em;
    border-radius: 3px 0 0 3px;

    @media (max-width: $medium-width-breakpoint) {
      margin: 25px 0 0;
    }

    @media (max-width: $small-width-breakpoint) {
      margin: 20px 0 0;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 15px 0 0;
      border-radius: 0;
    }

    @media (max-width: $tiny-width-breakpoint) {
      max-height: 15em;
    }

    &::-webkit-scrollbar {
      width: 1em;
      height: 1em;

      @media (max-width: $small-width-breakpoint) {
        width: 0.6em;
        height: 0.6em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        width: 0.4em;
        height: 0.4em;
      }
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.6);
      border-radius: 0.2em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $site-red-color;
      border-radius: 0.2em;
    }
  }
}
