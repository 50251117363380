@import '../color_variables.scss';

$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;


.youtube-player {
  &__container {
    background: center no-repeat url(../images/preloader.gif), white;
    max-height: none;
  }
}
