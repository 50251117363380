$site-red-color: #f0373d;
$site-light-red-color: #ff6266;
// $site-red-color: #ff3d53;
// $site-red-color: #e83f53;
// $site-background-color: #212731;
$site-background-color: #ffffff;
$default-gray-color: #9e9fa1;

$site-blue-color: #212930;

$default-golden-color: #d6af36;
$default-silver-color: #a7a7ad;
$default-bronze-color: #a77044;

$default-green-color: #57904f;

// $default-text-color: #131313;
$default-text-color: #2f2f2f;
$gray-text-color: #83808c;
$light-gray-color: #ececec;
