@import '../../color_variables.scss';
@import '../../size_variables';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$content-max-width: 75em;

$background-dark-color: #212121;
$default-green-color: #809e5f;
$default-red-color: #e08283;
$default-dark-blue: #2c3e50;
$default-text-color: #333;
$default-light-blue: #3598dc;

.customer-exit-survey {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 6em 0 0;
  // background: #212930;
  background: radial-gradient(#353535, $background-dark-color);
  flex: 1;
  min-height: 10em;

  @media (max-width: 1030px) {
    margin: 5em 0 0;
  }

  @media (max-width: 910px) {
    // margin: 8em 0 0;
    margin: 3.8em 0 0;
  }

  // @media (max-width: 750px) {
  //   margin: 4.3em 0 0;
  // }

  @media (max-width: 490px) {
    margin: 3.4em 0 0;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    z-index: 2;
    max-width: $content-max-width;
    padding: 3.5em 0.8em 2.5em;
    color: white;
    flex: 1;

    @media (max-width: $large-width-breakpoint) {
      padding: 3.5em 0.8em 2.5em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      padding: 3em 1.5em 2.5em;
    }
    @media (max-width: $high-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      padding: 2.5em 1.2em;
    }
    @media (max-width: $medium-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      padding: 2.5em 1em;
    }
    @media (max-width: $small-width-breakpoint) {
      padding: 1.5em 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      padding: 1.2em 0.8em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 1.2em 0.4em;
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100vh;
  }

  &__confirm-window-error-icon {
    position: relative;
    font-size: 6em;
    color: $default-red-color;

    @media (max-width: $small-width-breakpoint) {
      font-size: 5em;
    }
  }

  &__main-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
  }

  &__alert-text {
    text-align: center;
    background: #f5f5f5;
    color: $site-red-color;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0.5em 0;
    margin: 0 0 0.5em;

    &__icon {
      margin: 0 0.4em 0 0;
    }
  }

  &__fyd-form {
    &__question {
      &,
      &--required {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        align-self: stretch;
        border: none;
        padding: 0;
        border-radius: 5px;
        transition: all 0.4s cubic-bezier(0.22, 0.97, 0.58, 1);

        &:not(:last-child) {
          margin: 0 0 1.2em;

          @media (max-width: $medium-less-width-breakpoint) {
            margin: 0 0 1em;
          }
          @media (max-width: $small-width-breakpoint) {
            margin: 0 0 0.8em;
          }
          @media (max-width: $very-small-width-breakpoint) {
            margin: 0 0 0.6em;
          }
        }
      }

      &--required {
        border-color: $site-red-color;
      }

      &__content-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 0 0.2em;
        overflow: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          width: 0.4em;
          height: 0.4em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: white;
          border-radius: 0.4em;
        }
      }

      &__label {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.1em;
        font-weight: bold;
        color: white;
        margin: 0 0 0.5em;
        text-align: justify;

        @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.9em;
        }
      }

      &__answer-options {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        align-self: stretch;
        border-radius: 0.1em;

        &__item {
          display: flex;
          flex-flow: row nowrap;
          align-items: stretch;
          justify-content: stretch;
          list-style: none;
          flex: 1 1;

          &__button {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            padding: 0.4em 0.6em;
            outline: none;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            border: none;
            user-select: none;
            font-size: 1em;
            text-decoration: none;
            min-width: 35px;
            min-height: 35px;
            font-weight: bold;
            border: 1px solid white;
            background: white;
            color: white;
            flex: 1 1;

            @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
              border-right-width: 0;
            }

            &:disabled {
              cursor: default;
              color: white;
            }
          }

          &__value {
            font-family: 'Montserrat', sans-serif;
            font-size: 1.5em;

            @media (max-width: $small-width-breakpoint) {
              font-size: 1.2em;
            }

            @media (max-width: $very-small-width-breakpoint) {
              font-size: 1em;
            }
          }

          &__icon {
            margin: 0 0 5px;
            font-size: 2em;
          }
        }
      }

      &__long-text-input {
        align-self: stretch;
      }

      &__required-warning {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        align-self: flex-start;
        padding: 0.4em 0.6em;
        background-color: $site-red-color;
        border-radius: 4px;
        font-size: 0.8em;
        margin: 0.3em 0 0;

        &__icon {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: white;
          margin: 0 0.4em 0 0;
          font-size: 1.4em;
        }

        &__text {
          font-family: 'Montserrat', sans-serif;
          color: white;
        }
      }
    }

    &__user-note {
      font-family: 'Montserrat', sans-serif;
      margin: 0 0 1em;
      text-align: justify;
      color: #974c4c;
      font-weight: bold;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.9em;
        margin: 0 0 0.7em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.85em;
      }
    }
  }
}
