@import '../../color_variables.scss';
@import '../../size_variables';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$content-max-width: 75em;

$background-dark-color: #212121;
$default-text-color: #333;


.customer-feedback {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 6em 0 0;
  // background: #212930;
  background: radial-gradient(#353535, $background-dark-color);
  flex: 1;
  min-height: 10em;

  @media (max-width: 1030px) {
    margin: 5em 0 0;
  }

  @media (max-width: 910px) {
    // margin: 8em 0 0;
    margin: 3.8em 0 0;
  }

  // @media (max-width: 750px) {
  //   margin: 4.3em 0 0;
  // }

  @media (max-width: 490px) {
    margin: 3.4em 0 0;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    z-index: 2;
    max-width: $content-max-width;
    padding: 3.5em 0.8em 2.5em;
    color: white;
    flex: 1;

    @media (max-width: $large-width-breakpoint) {
      padding: 3.5em 0.8em 2.5em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      padding: 3em 1.5em 2.5em;
    }
    @media (max-width: $high-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      padding: 2.5em 1.2em;
    }
    @media (max-width: $medium-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      padding: 2.5em 1em;
    }
    @media (max-width: $small-width-breakpoint) {
      padding: 1.5em 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      padding: 1.2em 0.8em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 1.2em 0.4em;
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100vh;
  }

  &__warning-container {
    margin: 0 0 0.6em;
    width: 100%;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 0.5em;
    width: 100%;

    // @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    //   margin: 0 0 0.5em;
    // }
  }

  &__main-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
  }

  &__title-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
  }

  &__title,
  &__sub-title {
    text-transform: uppercase;
  }

  &__title {
    font-size: 1em;
    font-weight: bold;
  }

  &__sub-title {
    margin: 0.4em 0 0;
    font-size: 0.5em;
    text-decoration: underline;
  }

  &__description {
    margin: 0 0 1.5em;

    &__input {
      background: #f7f7f7;
      color: $default-text-color;
    }
  }

  &__feedback-type {
    &--row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: $very-small-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: flex-start;
      }
    }

    &__selected-content {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: $very-small-width-breakpoint) {
        margin: 0.4em 0 0 1em;
        border: 0 solid #ff3d53;
        border-left-width: 2px;
      }
    }

    &__title,
    &__selected-text {
      font-size: 1.2em;
      text-transform: uppercase;

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.15em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.1em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1.05em;
      }
      @media (max-width: 290px) {
        font-size: 1em;
      }
    }

    &__selected-text {
      color: #ff3d53;
      font-weight: bold;
      margin: 0 0 0 0.4em;
    }

    &__title {
      color: white;
      text-align: justify;
    }

    &__options-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }

    &__option-button {
      margin: 0.5em 0 0;
      font-size: 2em;
      padding: 0.2em;
      box-shadow: none;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 1.3em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1em;
      }
    }

    &__replace-button {
      font-size: 0.75em;
      width: 6em;
      max-width: 6em;
      box-shadow: none;
      border-radius: 3px;
      margin: 0 0 0 0.6em;
    }
  }

  &__personal-information-toggle {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin: 1em 0;
    font-size: 1.1em;
    user-select: none;

    @media (max-width: $high-width-breakpoint) {
      font-size: 1.05em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 1em;
    }
    @media (max-width: 300px) {
      font-size: 0.9em;
    }

    &__input {
      margin: 0 0.5em 0 0;
      cursor: pointer;
      min-width: 25px;
      min-height: 25px;
    }

    &__label {
      cursor: pointer;
      text-transform: uppercase;
      text-align: justify;

      @media (max-width: 960px) {
        font-size: 0.9em;
      }
    }
  }

  &__alert-text {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    text-align: center;
    background: transparent;
    border: 1px solid #ff3d53;
    border-radius: 3px;
    color: #ff3d53;
    text-transform: uppercase;
    font-weight: bold;
    margin: 1em 0;
    font-size: 1em;

    @media (max-width: $high-width-breakpoint) {
      font-size: 0.95em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 0.9em;
    }
    @media (max-width: $small-width-breakpoint) {
      text-align: justify;
      font-size: 0.85em;
    }
    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.7em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 0.5em;
      font-size: 0.95em;
    }

    &__icon {
      align-self: stretch;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: #ff3d53;
      color: white;
      width: 1.5em;
      margin: 0 0.5em 0 0;
    }
  }
}
