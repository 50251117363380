@import '../../color_variables.scss';
@import '../../size_variables';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$content-max-width: 75em;

$training-date-color: #383838;
// $training-content-background: #181e26;
$training-content-background: #2b2b2b;
$training-exercise-header-color: rgba(0, 0, 0, 0.38);
$training-exercise-reps-background: #b7ae44;

$link-button-normal-color: #66a24d;

$background-dark-color: #212121;

.training-calendar-view {
  margin: 6em 0 0;

  @media (max-width: 1030px) {
    margin: 5em 0 0;
  }
  @media (max-width: 910px) {
    margin: 8em 0 0;
  }
  @media (max-width: 750px) {
    margin: 4.3em 0 0;
  }
  @media (max-width: 490px) {
    margin: 3.7em 0 0;
  }

  &,
  &--promotion-enalbed {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    // background: #212930;
    background: radial-gradient(#353535, $background-dark-color);
    flex: 1;
    min-height: 10em;
  }

  &--promotion-enalbed {
    margin: 11em 0 0;

    @media (max-width: 1030px) {
      margin: 10em 0 0;
    }
    @media (max-width: 910px) {
      margin: 13em 0 0;
    }
    @media (max-width: 750px) {
      margin: 9.3em 0 0;
    }
    @media (max-width: 490px) {
      margin: 8.7em 0 0;
    }
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    z-index: 2;
    max-width: $content-max-width;
    padding: 3.5em 0.8em 2.5em;
    color: white;
    flex: 1;

    @media (max-width: $large-width-breakpoint) {
      padding: 3.5em 0.8em 2.5em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      padding: 3em 1.5em 2.5em;
    }
    @media (max-width: $high-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      padding: 2.5em 1.2em;
    }
    @media (max-width: $medium-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      padding: 2.5em 1em;
    }
    @media (max-width: $small-width-breakpoint) {
      padding: 1.5em 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      padding: 1.2em 0.8em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 1.2em 0.4em;
    }
  }

  &__title {
    font-size: 2.2em;
    font-weight: 700;
    // text-transform: uppercase;
    margin: 0 0 0.75em;

    @media (max-width: $large-width-breakpoint) {
      font-size: 2.1em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      font-size: 2em;
    }
    @media (max-width: $high-width-breakpoint) {
      font-size: 1.8em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      font-size: 1.7em;
    }
    @media (max-width: $medium-width-breakpoint) {
      margin: 0 0 0.5em;
      align-self: center;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.6em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 1.45em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1.3em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 1.2em;
      text-align: center;
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100vh;
  }

  &__trainings-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: 800px) {
      font-size: 0.9em;
    }
    @media (max-width: 480px) {
      font-size: 0.85em;
    }
  }

  &__training {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    align-self: stretch;
    background: linear-gradient(150deg, #cacaca, #797979);
    padding: 4px;
    border-radius: 4px;

    @media (max-width: $small-width-breakpoint) {
      padding: 2px;
    }

    &:not(:last-child) {
      margin: 0 0 1em;
    }

    &__header,
    &__content-container {
      background: linear-gradient(to right, lighten($training-content-background, 10%), $training-content-background);
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: space-between;

      @media (max-width: 680px) {
        font-size: 0.9em;
      }

      &__date {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.94em;
        }
      }

      &__date-text {
        font-family: 'Black Ops One', cursive;
        text-transform: uppercase;
        color: $training-date-color;
        padding: 0.2em 0.6em;
        font-size: 1.15em;
        text-shadow: 1px 1px 1px white;
        background: linear-gradient(150deg, #cacaca, #bababa);
      }

      &__separator {
        display: block;
        width: 2.5em;
        background: linear-gradient(136deg, #bdbdbd, #bababa 55%, transparent 56%);
      }

      &__name,
      &__not-today {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 0 1em;
        color: white;

        @media (max-width: $smaller-width-breakpoint) {
          padding: 0 0.4em 0 0.5em;
        }
      }

      &__name {
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.1em;
        }
      }

      &__main-name {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        color: $site-red-color;
        font-weight: bold;
        margin: 0 0.5em 0;
        font-size: 1.2em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.1em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 1em;
        }
      }

      &__not-today {
        background: linear-gradient(150deg, #8a8a8a, #7b7b7b);
        text-transform: uppercase;
        color: #dcdcdc;
        font-weight: bold;
        text-shadow: 1px 1px 1px #383838;

        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 0.95em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.85em;
        }
      }
    }

    &__content-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      align-self: stretch;
      padding: 0.5em;
    }

    &__toggle-button {
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      font-size: 0.9em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 3px;
      background: linear-gradient(to bottom, lighten($site-red-color, 9%), $site-red-color);
      font-weight: bold;
      text-transform: uppercase;
      color: $training-date-color;
      text-shadow: 1px 1px 1px lighten($site-red-color, 18%);

      &:active {
        background: linear-gradient(to top, lighten($site-red-color, 9%), $site-red-color);
        text-shadow: 1px 1px 1px $training-date-color;
        color: white;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.82em;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 0 0 0.8em;
    }

    &__note {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: stretch;
      text-align: justify;
      padding: 0;
      background: white;
      border-radius: 3px;
      margin: 0.5em 0 1.2em;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.92em;
      }
    }

    &__note-icon {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 0.5em 0.65em;
      font-size: 1.5em;
      background: $site-red-color;
      margin: 0 0.5em 0 0;
      border-radius: 3px 0 0 3px;
    }

    &__note-text {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      color: $default-text-color;
      font-weight: bold;
      text-align: justify;
      padding: 1em 1em 1em 0;
      flex: 1;
      white-space: pre-wrap;

      @media (max-width: $very-small-width-breakpoint) {
        padding: 0.6em 0.8em 0.6em 0;
      }
    }

    &__exercise-group {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      border: 2px solid white;
      border-radius: 4px;
      margin: 0 0 1.5em;
      overflow: auto;
      overflow-y: hidden;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.96em;
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $very-small-width-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: white;
        border-radius: 0.4em;
      }

      &__title {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 0.3em 0.8em 0;
        text-transform: uppercase;
        font-weight: bold;
      }

      &__exercises-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }

      &__note-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 0 0 0 0.8em;
        margin: 0.5em 0 0.2em;
      }

      &__note-label {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        margin: 0 0.4em 0 0;
      }

      &__note-text {
        background: white;
        padding: 0.5em;
        color: black;
        border-radius: 3px;
        flex: 1;
        white-space: pre-wrap;
      }
    }

    &__exercise {
      &__header {
        &,
        &--centered {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-end;
          justify-content: flex-start;
          height: 1.6em;
          min-height: 1.6em;
          font-weight: bold;
          color: $training-exercise-header-color;
          text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.16);
          padding: 0 1em;

          @media (max-width: $smaller-width-breakpoint) {
            padding: 0 0.7em;
          }
        }

        &--centered {
          justify-content: center;
          text-align: center;
        }
      }

      &__reps,
      &__intensities,
      &__intensities--collapsed,
      &__names,
      &__diffculties,
      &__easier-options,
      &__references {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        text-align: left;
      }

      &__intensities-wrapper {
        &--vertical,
        &--horizontal {
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
        }

        &--vertical {
          flex-flow: column nowrap;
        }

        &--horizontal {
          flex-flow: row nowrap;
        }
      }

      &__intensities-wrapper--horizontal &__intensities:not(:last-child) &__header {
        border-right: 1px solid $training-exercise-header-color;
      }

      &__intensities-wrapper--horizontal &__intensities:not(:first-child) &__header {
        border-left: 1px solid rgba(255, 255, 255, 0.16);
      }

      &__intensities,
      &__names,
      &__diffculties,
      &__easier-options {
        flex: 1;
      }

      &__reps-text {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        background: linear-gradient(to bottom, lighten($training-exercise-reps-background, 30%), $training-exercise-reps-background 30%, $training-exercise-reps-background 70%, darken($training-exercise-reps-background, 25%));
        font-weight: bold;
        color: white;
        text-shadow: 1px 1px 3px black;
        flex: 1;
        padding: 0 0.5em;

        @media (max-width: $smaller-width-breakpoint) {
          padding: 0 0.2em;
        }
      }

      &__reps-text-span {

      }

      &__reps-text,
      &__intensity-text,
      &__intensity-cycle-text,
      &__difficult-text,
      &__name-text,
      &__easier-option-text,
      &__reference-link {
        border: 0 solid white;
        white-space: nowrap;

        &:not(:last-child) {
          border-bottom-width: 1px;
        }
      }

      &__intensities-wrapper--horizontal &__intensities:not(:last-child) &__intensity-cycle-text {
        border-right: 1px solid rgba(255, 255, 255, 0.32);
      }

      &__intensity-text,
      &__intensity-cycle-text,
      &__difficult-text,
      &__name-text,
      &__easier-option-text,
      &__reference-link {
        padding: 0.2em 1em;
        background: rgba(0, 0, 0, 0.25);

        @media (max-width: $smaller-width-breakpoint) {
          padding: 0.2em 0.7em;
        }
      }

      &__reference-link {
        text-align: center;
        color: white;
      }

      &__first-column {
        width: 0.4em;
        min-width: 0.4em;
      }

      &__last-column {
        flex: 1;
        min-width: 0.4em;
      }
    }
  }

  &__link-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    margin: 0 0 0.5em;
  }

  &__student-app-link {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 0.6em 0.8em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    font-size: 0.9em;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    border-radius: 3px;
    background: linear-gradient(to bottom, lighten($link-button-normal-color, 9%), $link-button-normal-color);
    font-weight: bold;
    text-transform: uppercase;
    color: $training-date-color;
    text-shadow: 1px 1px 1px lighten($link-button-normal-color, 18%);
    width: 100%;

    &:active {
      background: linear-gradient(to top, lighten($link-button-normal-color, 9%), $link-button-normal-color);
      text-shadow: 1px 1px 1px $training-date-color;
      color: white;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.82em;
    }
  }

  &__warning-message {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    align-self: center;
    margin: 3em 0 0;
    max-width: 40em;
    background: white;
    border-radius: 5px;
    font-size: 1em;

    @media (max-width: $large-width-breakpoint) {
      font-size: 0.95em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      font-size: 0.9em;
      margin: 2.5em 0 0;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      margin: 2em 0 0;
    }
    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.85em;
      margin: 1.5em 0 0;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      margin: 1em 0 0;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 0.8em;
      margin: 0.5em 0 0;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.75em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: justify;
      font-size: 1.1em;
      font-weight: 600;
      flex: 1;
      color: $site-red-color;
      padding: 0.5em 1em;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: $site-red-color;
      color: white;
      padding: 0.8em;
      border-radius: 5px 0 0 5px;
      font-size: 1.8em;
    }
  }

  &__parsed-text {
    white-space: pre;
  }
}
