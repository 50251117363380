@import '../color_variables.scss';
@import '../size_variables.scss';

$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
// $small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;


.photo-gallery {
  &__main-container {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    z-index: 6;
    will-change: transform;
    top: 0;
    left: 0;
  }

  &__carousel {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    align-self: stretch;
    padding: 0.2em 0 0.6em;

    &__header {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      padding: 0.4em 3.5em;

      &__title {
        color: white;
        font-size: 1.1em;
        text-align: center;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 0.9em;
        }
        @media (max-width: $smaller-width-breakpoint) {
          font-size: 0.8em;
        }
      }

      &__close-button {
        position: absolute;
        top: 0;
        right: 0.4em;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        border: none;
        outline: none;
        user-select: none;
        background: none;
        padding: 0.2em;
        font-size: 1.6em;
        min-width: 35px;
        min-height: 35px;
        color: white;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
        text-decoration: none;
        z-index: 2;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.5em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 1.4em;
        }
        @media (max-width: $smaller-width-breakpoint) {
          font-size: 1.3em;
        }
      }
    }

    &__container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;
    }

    &__footer {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      margin: 0.4em 0 0;

      &__text {
        color: white;
        font-weight: bold;
        padding: 0.2em 0 0;
        font-size: 0.9em;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.8em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 0.75em;
        }
      }
    }

    &__side-button {
      &,
      &--left,
      &--right {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        border: none;
        outline: none;
        user-select: none;
        background: none;
        font-size: 2em;
        min-width: 35px;
        min-height: 35px;
        color: white;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
        text-decoration: none;
        flex: 1;
        z-index: 2;

        &:disabled {
          cursor: default;
          visibility: hidden;
        }

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.9em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 1.7em;
        }
        @media (max-width: $smaller-width-breakpoint) {
          font-size: 1.5em;
        }
      }

      &--left {
        justify-content: flex-end;
        padding: 0 0.5em 0 0;

        @media (max-width: $medium-less-width-breakpoint) {
          padding: 0 0.3em 0 0;
        }
      }

      &--right {
        justify-content: flex-start;
        padding: 0 0 0 0.5em;

        @media (max-width: $medium-less-width-breakpoint) {
          padding: 0 0 0 0.3em;
        }
      }
    }

    &__index-button {
      color: #ffffff47;

      &,
      &--selected {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        border: none;
        outline: none;
        user-select: none;
        background: none;
        padding: 0.4em;
        font-size: 0.6em;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
        text-decoration: none;

        &:disabled {
          cursor: default;
        }
      }

      &--selected {
        color: white;
      }
    }

    &__item-container {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
    }
  }

  &__responsive-image {
    -webkit-user-drag: none;
    max-width: 90vw;
    max-height: 85vh;
    object-fit: contain;
    border-radius: 3px;

    @media (max-width: $medium-less-width-breakpoint) {
      max-width: 87vw;
    }
    @media (max-width: $smaller-width-breakpoint) {
      max-width: 83vw;
    }

    &__wrapper {
      position: absolute;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      will-change: transform;
      transform-origin: center center;
      transform: translateX(0);
      transition: transform 0.4s, opacity 0.4s;
      opacity: 1;

      &--right,
      &--left {
        opacity: 0;
      }

      &--right {
        transform: translateX(80%);
      }
      &--left {
        transform: translateX(-80%);
      }
    }
  }
}
