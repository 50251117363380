@import '../color_variables';
@import '../size_variables';

$default-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.31, 1.78);
$toggle-transition: transform 0.4s cubic-bezier(0.17, 0.67, 0.51, 1.3);

$high-width-breakpoint: 1030px;
$medium-width-breakpoint: 910px;
$small-width-breakpoint: 750px;
$very-small-width-breakpoint: 490px;
$tiny-width-breakpoint: 380px;

$content-max-width: 75em;


@keyframes infinite-slide {
  from {
    transform: translateX(13.333%);
  }

  to {
    transform: translateX(-13.333%);
  }
}

.main-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  // position: sticky;
  top: 0;
  will-change: transform;
  z-index: 5;
  background: white;

  position: fixed;
  width: 100%;

  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    max-width: $content-max-width;
    padding: 0.9em 0 0.6em;
    z-index: 5;
  }

  &__logo {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    margin: 0 0 0 8px;
    text-decoration: none;
    cursor: pointer;

    @media (max-width: $very-small-width-breakpoint) {
      margin: 0 0 0 4px;
    }

    &__image {
      max-width: 100%;
      max-height: 4.5em;

      @media (max-width: $high-width-breakpoint) {
        max-height: 3.5em;
      }

      @media (max-width: $medium-width-breakpoint) {
        display: none;
      }
    }

    &__text {
      font-size: 2.4em;
      font-weight: bold;
      color: $default-text-color;
      white-space: nowrap;

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.9em;
      }

      @media (max-width: $medium-width-breakpoint) {
        margin: 0 0 0 0.1em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1.4em;
      }
    }
  }

  &__controls-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0 8px 0 0;

    @media (max-width: $medium-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: flex-end;
      margin: 0;
    }

    @media (max-width: $small-width-breakpoint) {
      flex-flow: row nowrap;
      align-items: center;
      z-index: 5;
    }
  }

  &__navigation {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    color: $gray-text-color;

    @media (max-width: $medium-width-breakpoint) {
      order: 2;
    }

    @media (max-width: $small-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      padding: 4.5em 0 0;
      background: white;
      height: 100%;
      z-index: 4;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding: 4em 0 0;
    }

    @media (max-width: $tiny-width-breakpoint) {
      padding: 3.6em 0 0;
    }

    &__links-container,
    &__social-links-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      list-style: none;
    }

    &__links-container {
      @media (max-width: $medium-width-breakpoint) {
        margin: 0 0.8em 0 0;
      }

      @media (max-width: $small-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
        margin: 0;
      }
    }

    &__social-links-container {
      margin: 0 0.4em 0 1em;
      padding: 0.3em 0 0.3em 1em;
      border: 0 solid $gray-text-color;
      border-width: 0 0 0 1px;

      @media (max-width: $medium-width-breakpoint) {
        border-width: 0 0 1px 0;
        padding: 0 0.8em 0.8em 2em;
        margin: 0 0 0.4em 0;
      }

      @media (max-width: $small-width-breakpoint) {
        border-width: 0;
        margin: 0;
        padding: 0 0.8em 0 0;
      }

      @media (max-width: $very-small-width-breakpoint) {
        padding: 0 0.42em 0 0;
      }
    }

    &__link-wrapper {
      &::after {
        display: block;
        position: relative;
        content: '';
        background: $site-red-color;
        bottom: 0;
        height: 2px;
        width: 0;
        transition: $default-transition;
      }

      &:hover::after {
        width: 80%;
      }

      &,
      &--selected {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        text-align: center;

        &:not(:last-child) {
          margin: 0 1em 0 0;
        }

        @media (max-width: $small-width-breakpoint) {
          align-items: flex-end;

          &:not(:last-child) {
            margin: 0;
          }
        }
      }

      &--selected {
        padding: 0 0 2px;
      }
    }

    &__link {
      transition: $default-transition;
      will-change: transform;
      color: inherit;
      cursor: pointer;

      &,
      &--selected {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-size: 0.95em;
        text-transform: uppercase;
        font-weight: 500;
        text-decoration: none;
        padding: 0.5em 0;
        margin: 2px 0 0;
        min-width: 35px;
        min-height: 35px;
        outline: none;
        -webkit-tap-highlight-color:  transparent;
        border: none;
        user-select: none;
        text-decoration: none;
        background: none;

        @media (max-width: $high-width-breakpoint) {
          font-size: 0.85em;
        }

        @media (max-width: $small-width-breakpoint) {
          padding: 0 0.8em 0 0;
        }
      }

      &--selected {
        color: $site-red-color;
        cursor: default;
        font-weight: 800;
      }
    }

    &__link-wrapper:hover &__link {
      transform: translateY(-0.3em);
      color: $default-text-color;
    }

    &__link-wrapper button.main-header__navigation__link:active {
      color: $site-red-color;
    }

    &__social-link-wrapper {
      &:not(:last-child) {
        margin: 0 0.5em 0 0;
      }

      @media (max-width: $very-small-width-breakpoint) {
        &:not(:last-child) {
          margin: 0 0.3em 0 0;
        }
      }
    }

    &__social-link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      border-radius: 50%;
      background: $gray-text-color;
      color: white;
      width: 1.6em;
      height: 1.6em;
      cursor: pointer;
      text-decoration: none;
      transition: $default-transition;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1em;
        min-width: 35px;
        min-height: 35px;
      }
    }

    &__social-link-wrapper:hover &__social-link {
      box-shadow: 0 0 1px 2px $site-red-color;
      background: $default-text-color;
    }
  }

  &__toggle-container {
    transform: translateX(-103%);

    &,
    &--visible {
      position: fixed;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      top: 0;
      left: 0;
      transition: $toggle-transition;
      will-change: transform;
      height: 130vh;
      min-width: 11.4em;
      z-index: 4;

      &::before {
        display: block;
        position: absolute;
        content: '';
        background: white;
        width: 5em;
        height: 100%;
        right: 99%;
        top: 0;
      }
    }

    &--visible {
      transform: translateX(0);
    }
  }

  &__toggle-background-block {
    transform: translateX(-103%);

    &,
    &--visible {
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100vw;
      will-change: transform;
      z-index: 3;
      background: #00000073;
      transition: $toggle-transition;
      cursor: default;
      outline: none;
      -webkit-tap-highlight-color:  transparent;
      user-select: none;
    }

    &--visible {
      transform: translateX(0);
      cursor: pointer;
    }
  }

  &__menu-toggler {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.6em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: 1px dashed #8f8f8f;
    border-radius: 3px;
    user-select: none;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    color: $gray-text-color;
    background: none; //linear-gradient(108deg, #919191, #f3f3f3, #919191)
    padding: 0.4em;
    margin: 0 0 0 0.4em;
    transition: $default-transition;
    box-shadow: 0 0 2px $gray-text-color

    & i {
      border: none;
    }

    &:hover {
      color: $default-text-color;
      border: 1px dashed $default-text-color;
    }

    &:active {
      color: $site-red-color;
      border: 1px dashed $site-red-color;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding: 0.2em;
      font-size: 1.4em;
      margin: 0 0.3em;
    }
  }

  &__promotion {
    background: linear-gradient(to right, #2f2f2f, #565656, #2f2f2f);

    &,
    &--inverted-colors {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-family: 'Montserrat', sans-serif;
      z-index: 3;
      position: fixed;
      width: 100%;
      height: 5em;
      top: 6em;
      overflow: hidden;

      @media (max-width: 1030px) {
        top: 5em;
      }

      @media (max-width: 910px) {
        top: 8em;
      }

      @media (max-width: 750px) {
        top: 4.3em;
      }

      @media (max-width: 490px) {
        top: 3.7em;
      }

      &::before {
        display: block;
        position: absolute;
        content: '';
        box-shadow: 0 0 10px 2px black;
        width: 100%;
        height: 0;
        top: 0;
      }

      &::after {
        display: block;
        position: absolute;
        content: '';
        box-shadow: 0 0 10px 2px black;
        width: 100%;
        height: 0;
        bottom: 0;
      }
    }

    &--inverted-colors {
      // background: linear-gradient(to right, $site-red-color, $site-light-red-color, $site-red-color);
      background: linear-gradient(169deg, #a6e98d, #bbb4d3, #bee6f9, #a6e98d);

      &::before,
      &::after {
        box-shadow: 0 0 10px 2px white;
      }
    }

    &__wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      max-width: $content-max-width;
      padding: 1em 0.8em;
      z-index: 2;
    }

    &__title {
      font-weight: bolder;
      text-shadow: 3px 1px 0px #000000a6, 0px 0px 15px #ffffff52, 0px 0px 6px #ffffffa3;
      color: #ffffff;
      font-size: 1.8em;
      text-transform: uppercase;
      flex: 1;

      @media (max-width: 910px) {
        font-size: 1.7em;
      }

      @media (max-width: 750px) {
        font-size: 1.5em;
        text-align: center;
      }

      @media (max-width: 490px) {
        font-size: 1em;
      }

      &--emphasis {
        font-size: 1.2em;
        // color: $site-red-color;
        // text-shadow: 3px 1px 0px #0000006e, 0px 0px 12px #ff737952, 0px 0px 5px #fb71756b;
        text-shadow: 3px 1px 0px #00a4006e, 0px 0px 12px #00a40052, 0px 0px 5px #00a4006b;
      }

      &--yellow {
        color: #f6ff68;
        text-shadow: 3px 1px 0px #0000006e, 0px 0px 12px #f6ff687a, 0px 0px 5px #f6ff687d;
      }
      &--green {
        color: #51d751;
        text-shadow: 3px 1px 0px #00000096, 0px 0px 12px #82ff827d, 0px 0px 5px #82ff827d;
      }
    }

    &--inverted-colors &__title--emphasis {
      // color: $default-text-color;
      // text-shadow: 3px 1px 0px #0000006e;
    }

    &__link-button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      text-decoration: none;
      padding: 0.3em 0.6em;
      // background: transparent;
      border: 2px dashed white;
      border-radius: 3px;
      // color: white;
      // font-weight: 500;
      font-size: 1.2em;
      text-transform: uppercase;
      margin: 0;
      outline: none;
      -webkit-tap-highlight-color:  transparent;
      user-select: none;
      text-align: center;
      background: white;
      color: #00a400;
      box-shadow: 0 0 3px white;
      font-weight: bolder;

      @media (max-width: 750px) {
        font-size: 1.1em;
      }

      @media (max-width: 490px) {
        font-size: 1em;
      }

      // &:hover {
      //   background: white;
      //   // color: $site-red-color;
      //   color: #00a400;
      //   box-shadow: 0 0 3px white;
      //   font-weight: bolder;
      // }
    }

    & &__link-button {
      background: transparent;
      color: white;
      font-weight: 500;
      box-shadow: none;

      &:hover {
        background: white;
        // color: $site-red-color;
        color: #00a400;
        box-shadow: 0 0 3px white;
        font-weight: bolder;
      }
    }

    &__background {
      position: absolute;
      background: linear-gradient(169deg, #a6e98d, #bbb4d3, #bee6f9, #a6e98d, #bbb4d3, #bee6f9, #a6e98d, #bbb4d3, #bee6f9, #a6e98d, #bbb4d3, #bee6f9, #a6e98d);
      height: 100%;
      width: 400%;
      will-change: transform;
      transform-origin: center center;
      transform: translateX(13.333%);
      animation: infinite-slide 9s linear 0.9s infinite backwards;
    }
  }
}

.lead-popup {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  max-height: 40em;
  max-width: 75em;
  background: #1d1d1b;

  @media (max-width: 1250px) {
    font-size: 0.89em;
  }
  @media (max-width: 1100px) {
    font-size: 0.8em;
  }
  @media (max-width: 990px) {
    max-width: 65em;
    max-height: 38em;
  }
  @media (max-width: 850px) {
    font-size: 0.7em;
    max-height: 40em;
  }
  @media (max-width: 750px) {
    max-width: 57em;
    max-height: 37em;
  }
  @media (max-width: 650px) {
    max-height: 42em;
  }

  &__image-wrapper {
    @media (max-width: 650px) {
      position: absolute;
    }
  }

  &__image {
    max-height: 100%;

    @media (max-width: 650px) {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__form {
    &,
    &--align-top {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;

      @media (max-width: 650px) {
        z-index: 1;
        padding: 1.5em 2em;
        background: #1d1d1bc9;
        font-size: 1.3em;
      }
      @media (max-width: 450px) {
        font-size: 1.1em;
      }
      @media (max-width: 380px) {
        font-size: 1em;
      }
    }

    &--align-top {
      justify-content: flex-start;
      margin: 4.5em 0 0;

      @media (max-width: 990px) {
        margin: 4em 0 0;
      }
      @media (max-width: 850px) {
        margin: 3.5em 0 0;
      }
      @media (max-width: 750px) {
        margin: 2.5em 0 0;
      }
      @media (max-width: 650px) {
        justify-content: center;
        margin: 0;
      }
    }

    &__header {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      margin: 0 0 1.4em;

      @media (max-width: 990px) {
        font-size: 0.85em;
      }

      &__text {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 1.8em;

        &--highlighted {
          font-size: 1.125em;
          color: #ff3d53;
          text-decoration: underline;
          font-weight: bold;
        }

        &--red {
          color: #ff3d53;
          font-weight: bold;
        }

        @media (max-width: 750px) {
          font-size: 1.45em;
        }
      }

      &__close-button-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        height: 1em;

        @media (max-width: 850px) {
          height: 1.5em;
        }
        @media (max-width: 750px) {
          height: 2em;
        }
      }

      &__close-button {
        position: absolute;
        top: 0.2em;
        right: 0.2em;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
        font-size: 1.8em;
        padding: 0;
        border: 2px solid white;
        color: white;
        background: none;
        border-radius: 50%;
        width: 1.5em;
        height: 1.5em;
        min-width: 30px;
        min-height: 30px;
        transition: $default-transition;
        -webkit-tap-highlight-color:  transparent;
        box-shadow: none;
        outline: none;
        user-select: none;
        transform-origin: center;
        margin: 0;

        &:hover {
          // border: 2px solid $site-red-color;
          // color: $site-red-color;
          // background: white;
          transform: scale(1.15, 1.15);
        }
      }
    }

    &__inputs-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 36.8em;

      @media (max-width: 990px) {
        width: 31.28em;
      }
      @media (max-width: 750px) {
        font-size: 0.8em;
      }

      & .default-input {
        @media (max-width: 420px) {
          font-size: 1em;
        }
      }
    }

    &__label {
      color: white;
      font-size: 1.25em;
      margin: 1em 0 0.2em;
    }

    &__checkboxes {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0.8em;
      column-gap: 2.27em;

      @media (max-width: 1250px) {
        column-gap: 2em;
      }
      @media (max-width: 1100px) {
        column-gap: 1.72em;
      }
      @media (max-width: 990px) {
        column-gap: 2.55em;
      }
      @media (max-width: 850px) {
        column-gap: 1.85em;
      }
      @media (max-width: 750px) {
        column-gap: 0.5em;
      }
      @media (max-width: 650px) {
        column-gap: 1.8em;
      }
      @media (max-width: 450px) {
        column-gap: 1.4em;
      }
      @media (max-width: 380px) {
        column-gap: 1em;
      }
    }

    &__checkbox-input-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      user-select: none;
      padding: 0;
    }

    &__checkbox-input {
      margin: 0 0.3em 0 0;
      cursor: pointer;
      width: 20px;
      height: 20px;
    }

    &__checkbox-input-label {
      cursor: pointer;
      text-align: justify;
      color: white;
      font-weight: lighter;
      font-size: 1.12em;

      @media (max-width: 850px) {
        font-weight: normal;
      }
    }

    &__footer {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      margin: 1.2em 0 0;

      @media (max-width: 750px) {
        font-size: 0.9em;
      }

      &__link {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        margin: 0.5em 0 0;
        text-transform: uppercase;
        text-align: center;
        max-width: 25em;
        color: white;
        text-decoration: none;

        &--highlight {
          text-decoration: none;
          font-weight: bold;
          font-size: 1.1em;
          margin: 0 0 0.2em;
        }
        &--underlined {
          text-decoration: underline;
        }
      }
    }

    &__submit-button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: #ff3d53;
      color: white;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.3em;
      padding: 0.4em 1.2em;
      margin: 0;
      font-weight: 400;
      border: None;
      border-radius: 3px;
      min-width: 35px;
      min-height: 35px;
      transition: $default-transition;
      outline: none;
      -webkit-tap-highlight-color:  transparent;
      user-select: none;
      transform-origin: center;

      &:hover {
        color: #ff3d53;
        background: white;
        transform: scale(1.05, 1.05);
      }
    }

    &__decorator {
      &--1,
      &--2,
      &--3 {
        position: absolute;
      }

      &--1 {
        max-width: 2em;
        right: 38em;
        bottom: 4em;

        @media (max-width: 990px) {
          right: 32em;
          bottom: 3em;
        }
        @media (max-width: 850px) {
          bottom: 2em;
        }
        @media (max-width: 750px) {
          max-width: 1.5em;
          right: 26.5em;
          bottom: 3em;
        }
        @media (max-width: 650px) {
          right: 25.5em;
          bottom: 4em;
        }
      }
      &--2 {
        max-width: 2.5em;
        right: 8em;
        top: 1.5em;

        @media (max-width: 1100px) {
          top: 1em;
        }
        @media (max-width: 990px) {
          right: 7em;
          top: 0.5em;
        }
        @media (max-width: 850px) {
          top: 0.3em;
        }
        @media (max-width: 750px) {
          max-width: 2em;
          right: 6em;
          top: 0.8em;
        }
        @media (max-width: 650px) {
          right: 5em;
        }
      }
      &--3 {
        max-width: 30em;
        right: -18em;
        bottom: -18em;

        @media (max-width: 990px) {
          right: -20em;
          bottom: -20em;
        }
        @media (max-width: 850px) {
          right: -20.5em;
          bottom: -20.5em;
        }
        @media (max-width: 750px) {
          max-width: 24em;
          right: -16em;
          bottom: -16em;
        }
        @media (max-width: 650px) {
          max-width: 23em;
          right: -16em;
          bottom: -16em;
        }
      }
    }

    &__warning-message {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      align-self: center;
      margin: 0 0 0.5em;
      width: 100%;
      background: white;
      border-radius: 3px;
      font-size: 1em;

      // @media (max-width: $large-width-breakpoint) {
      //   font-size: 0.95em;
      // }
      // @media (max-width: $extra-high-width-breakpoint) {
      //   font-size: 0.9em;
      //   margin: 2.5em 0 0;
      // }
      // @media (max-width: $medium-plus-width-breakpoint) {
      //   margin: 2em 0 0;
      // }
      // @media (max-width: $medium-width-breakpoint) {
      //   font-size: 0.85em;
      //   margin: 1.5em 0 0;
      // }
      // @media (max-width: $small-width-breakpoint) {
      //   font-size: 0.8em;
      // }
      // @media (max-width: $very-small-width-breakpoint) {
      //   font-size: 0.75em;
      // }
      // @media (max-width: $tiny-width-breakpoint) {
      //   font-size: 0.7em;
      // }

      & .warning-message__message {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        text-align: justify;
        font-weight: 600;
        flex: 1;
        color: #ff3d53;
        padding: 0 1em;

        @media (max-width: 850px) {
          font-size: 1.1em;
        }
      }

      & .warning-message__close-button {
        min-width: 32px;
        min-height: 32px;
      }
    }

    &__return-message {
      &,
      &--success {
        color: #ff3d53;
        background: white;
        border-radius: 3px;
        padding: 0.5em 1em;
        text-align: justify;

        @media (max-width: 750px) {
          font-size: 1.2em;
        }
        @media (max-width: 650px) {
          margin: 0 0 6em;
        }
      }

      &--success {
        color: #3a5d1b;

        @media (max-width: 650px) {
          margin: 0 0 1em;
        }
      }
    }
  }
}
