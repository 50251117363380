@import '../../color_variables.scss';
@import '../../size_variables';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$content-max-width: 75em;

$background-dark-color: #212121;

.schedule-appointment {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 6em 0 0;
  // background: #212930;
  background: radial-gradient(#353535, $background-dark-color);
  flex: 1;
  min-height: 10em;

  @media (max-width: 1030px) {
    margin: 5em 0 0;
  }

  @media (max-width: 910px) {
    // margin: 8em 0 0;
    margin: 3.8em 0 0;
  }

  // @media (max-width: 750px) {
  //   margin: 4.3em 0 0;
  // }

  @media (max-width: 490px) {
    margin: 3.4em 0 0;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    z-index: 2;
    max-width: $content-max-width;
    padding: 3.5em 0.8em 2.5em;
    color: white;
    flex: 1;

    @media (max-width: $large-width-breakpoint) {
      padding: 3.5em 0.8em 2.5em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      padding: 3em 1.5em 2.5em;
    }
    @media (max-width: $high-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      padding: 2.5em 1.2em;
    }
    @media (max-width: $medium-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      padding: 2.5em 1em;
    }
    @media (max-width: $small-width-breakpoint) {
      padding: 1.5em 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      padding: 1.2em 0.8em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 1.2em 0.4em;
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100vh;
  }

  &__warning-container {
    margin: 0 0 0.6em;
    width: 100%;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    margin: 0 0 0.5em;
    width: 100%;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.5em;
    }
  }

  &__cancel-button {
    margin: 1em 0 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background: $site-red-color;
    cursor: pointer;
    padding: 0.6em 1em;
    font-size: 1.5em;
    color: white;
    border: none;
    border-radius: 5px;
    align-self: center;
    text-align: justify;
    outline: none;
    user-select: none;
    text-decoration: none;

    @media (max-width: 960px) {
      font-size: 1.3em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 1.1em;
      padding: 0.7em 1em;
    }

    &__icon {
      font-size: 2em;
      margin: 0 20px 0 0;
      color: $site-red-color;
    }
  }

  &__canceld-icon {
    position: relative;
    font-size: 6em;
    color: $default-green-color;

    @media (max-width: $small-width-breakpoint) {
      font-size: 5em;
    }
  }
}
