@import '../constants.scss';
@import '../size_variables.scss';
@import '../color_variables.scss';


.dropdown-menu {
  position: absolute;
  background: white;
  border-radius: 0 0 3px 3px;
  box-shadow: 0px 0px 2px #7e7e7e;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  z-index: 10;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
    border-radius: 0.1em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $site-light-red-color;
    border-radius: 0.1em;
  }

  &__list-container {
    list-style: none;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__list-item {
    &,
    &--disabled {
      padding: 0.5em;
    }

    &--disabled {
      background: #adadad;
      color: #767676;
      font-weight: bold;
    }

    cursor: pointer;

    &:hover {
      background: #cbcbcb;
    }
  }
}
