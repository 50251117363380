@import '../color_variables.scss';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$default-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.31, 1.78);

$content-max-width: 75em;

$medium-height-breakpoint: 650px;

.main-footer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0;

  // @media (max-width: 1030px) {
  //   margin: 5em 0 0;
  // }
  //
  // @media (max-width: 910px) {
  //   margin: 8em 0 0;
  // }
  //
  // @media (max-width: 750px) {
  //   margin: 4.3em 0 0;
  // }
  //
  // @media (max-width: 490px) {
  //   margin: 3.7em 0 0;
  // }

  &__images-container {
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
  }

  &__red-filter {
    display: block;
    position: absolute;
    object-fit: cover;
    width: 100%;
    opacity: 0.93;
    height: 100%;
    // background: linear-gradient(to right, #ff2062, #ff4b00);
    background: linear-gradient(to right, $site-red-color, #ff4b00);
  }

  &__main-image {
    display: block;
    position: absolute;
    object-fit: cover;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
  }

  &__images-container,
  &__red-filter,
  &__main-image,
  &__content {
    min-height: 100px;
    height: 15.5em;


    @media (max-width: $medium-less-width-breakpoint) {
      height: 18em;
    }

    @media (max-width: $small-width-breakpoint) {
      height: 20.8em;
    }

    @media (max-width: 550px) {
      height: 22.4em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      height: 24.8em;
    }

    @media (max-width: $tiny-width-breakpoint) {
      height: 26em;
    }
  }

  &__content {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    z-index: 2;
    max-width: $content-max-width;
    padding: 2em 0.8em 0;
    color: white;

    // @media (max-width: $large-width-breakpoint) {
    //   padding: 8.4em 0.8em 0;
    // }

    // @media (max-width: $high-width-breakpoint) {
    //   padding: 12em 1.5em 0;
    // }

    @media (max-width: $medium-width-breakpoint) {
      align-items: center;
      justify-content: center;
      padding: 0 0.8em;
    }

    @media (max-width: $small-width-breakpoint) {
      align-items: stretch;
      justify-content: stretch;
    }
  }

  &__send-form-button {
    cursor: pointer;

    &,
    &--active {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin: 0.5em 0 0;
      text-decoration: none;
      text-transform: uppercase;
      outline: none;
      -webkit-tap-highlight-color:  transparent;
      user-select: none;
      font-size: 1em;
      padding: 0.4em 0;
      font-weight: 500;
      width: 10em;
      border-radius: 5px;
      min-width: 35px;
      min-height: 35px;
      transition: $default-transition;
      color: white;
      background: none;
      border: 1px solid white;
    }

    &--active {
      cursor: default;
    }

    &--active,
    &:hover {
      color: $default-text-color;
      background: white;
    }

    @media (max-width: $very-small-width-breakpoint) {
      align-self: center;
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    @media (max-width: $medium-less-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: center;
    }
  }

  &__column-group {
    &,
    &--1,
    &--2,
    &--3 {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      @media (max-width: $medium-less-width-breakpoint) {
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        &:not(:first-of-type) {
          margin: 1.5em 0 0;
        }
      }
    }

    &--1 {
      @media (max-width: $very-small-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
      }
    }

    &--2 {
      @media (max-width: $very-small-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
      }
    }

    &--3 {
      @media (max-width: 550px) {
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  &__logo {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    text-decoration: none;
    cursor: pointer;

    @media (max-width: $medium-less-width-breakpoint) {
      justify-content: center;
      margin: 0 1.5em;
    }

    @media (max-width: $small-width-breakpoint) {
      margin: 0 0.8em 0 0;
    }

    @media (max-width: $very-small-width-breakpoint) {
      margin: 0 0 0.8em;
    }

    &__image {
      max-width: 100%;
      max-height: 4.5em;

      @media (max-width: $high-width-breakpoint) {
        max-height: 3.5em;
      }

      @media (max-width: $medium-width-breakpoint) {
        display: none;
      }
    }

    &__text-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
    }

    &__hr {
      border: none;
      background: white;
      width: 100%;
      height: 1px;
      margin: 0;
    }

    &__text {
      color: white;

      &,
      &--red {
        font-size: 2em;
        font-weight: bold;
        font-weight: 500;
        white-space: nowrap;

        @media (max-width: $high-width-breakpoint) {
          font-size: 1.9em;
        }

        @media (max-width: $medium-width-breakpoint) {
          margin: 0 0 0 0.1em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.6em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          font-size: 1.4em;
        }
      }

      &--red {
        color: $site-red-color;
      }

      &--highlighted {
        border: 2px solid white;
        border-radius: 5px;
        padding: 0 0.1em;
        margin: 0 0.1em 0 0;
      }

      &--red &--highlighted {
        border-color: $site-red-color;
      }
    }

    &__sub-text {
      color: white;

      &,
      &--red {
        font-size: 1.08em;
        font-weight: 400;
        white-space: nowrap;

        @media (max-width: $high-width-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $medium-width-breakpoint) {
          margin: 0 0 0 0.1em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.85em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.75em;
        }
      }

      &--red {
        color: $site-red-color;
      }

      &--highlighted {
        border: 2px solid white;
        border-radius: 5px;
        padding: 0 0.1em;
        margin: 0 0.1em 0 0;
      }

      &--red &--highlighted {
        border-color: $site-red-color;
      }
    }
  }

  &__description {
    font-size: 1em;
    max-width: 23em;
    text-align: justify;
    margin: 1.5em 0 1.5em;

    @media (max-width: $medium-plus-width-breakpoint) {
      max-width: 16em;
      margin: 1.5em 0 0.5em;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      flex: 1;
      max-width: none;
      margin: 0;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }
  }

  &__social-links-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    list-style: none;

    @media (max-width: $medium-less-width-breakpoint) {
      margin: 0 0 0 0.6em;
    }

    @media (max-width: $small-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: $very-small-width-breakpoint) {
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
    }
  }

  &__social-link-wrapper {
    &:not(:last-child) {
      margin: 0 0.5em 0 0;

      @media (max-width: $small-width-breakpoint) {
        margin: 0 0 0.5em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        margin: 0 0.5em 0 0;
      }
    }
  }

  &__social-link {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    border-radius: 50%;
    // background: white;
    color: white;
    width: 1.6em;
    height: 1.6em;
    cursor: pointer;
    text-decoration: none;
    transition: $default-transition;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1em;
      min-width: 35px;
      min-height: 35px;
    }
  }

  &__social-link-wrapper:hover &__social-link {
    box-shadow: 0 0 1px 2px white;
    background: white;
    color: $site-red-color;
  }

  &__sub-title {
    text-transform: uppercase;

    @media (max-width: $medium-less-width-breakpoint) {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex: 1;
      border: 0 solid white;
      border-right-width: 2px;
      align-self: stretch;
    }

    @media (max-width: $very-small-width-breakpoint) {
      flex: none;
      border-right-width: 0;
      border-bottom-width: 2px;
      align-self: center;
      width: 65%;
      text-align: center;
      padding: 0 0 0.3em;
      margin: 0 0 0.4em;
    }
  }

  &__address {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 1em 0 0;
    max-width: 18em;

    @media (max-width: $medium-plus-width-breakpoint) {
      max-width: 15em;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      flex: 2;
      max-width: none;
      margin: 0;
      padding: 0.5em 1em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      flex: none;
      padding: 0.5em 0;
    }

    &__item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      font-size: 1em;

      &:not(:first-of-type) {
        margin: 1em 0 0;
      }

      &__icon {
        font-size: 1.3em;
        margin: 0 0.5em 0 0;
      }

      &__text {
        &,
        &--clickable {
          font-style: normal;
          flex: 1;
          text-align: justify;
          color: white;

          @media (max-width: $very-small-width-breakpoint) {
            text-align: center;
          }
        }
      }
    }
  }

  &__label {
    margin: 0 0 0.4em;
    max-width: 20em;

    @media (max-width: $medium-less-width-breakpoint) {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin: 0 1em 0 0;;
      max-width: 25em;
      text-align: justify;
    }

    @media (max-width: 550px) {
      margin: 0 0 0.6em;
      max-width: none;
    }
  }

  &__form-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    max-width: 20em;

    // @media (max-width: $medium-less-width-breakpoint) {
    //   flex: 2;
    // }

    @media (max-width: 550px) {
      width: 100%;
      max-width: none;
    }
  }

  &__input {
    & input {
      border: none;
      border-radius: 5px;
      font-size: 1em;
      color: $default-text-color;
      background: white;
      min-width: 20em;

      @media (max-width: $medium-less-width-breakpoint) {
        max-width: 20em;
      }

      @media (max-width: $small-width-breakpoint) {
        max-width: none;
      }
    }
  }

  &__copyright {
    text-align: center;
    padding: 0.3em 0;
    color: $site-red-color;
    font-weight: 500;
    font-size: 0.9em;
  }

  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }

    & .warning-message {
      border-radius: 5px;
    }

    & .warning-message__message {
      padding: 5px 10px;

      @media (max-width: 680px), (max-height: 480px) {
        padding: 4px 8px;
      }

      @media (max-width: 360px) {
        font-size: 0.82em;
        padding: 3px 8px;
      }
    }
  }

  &__link-button {
    text-decoration: none;
    padding: 0.3em 0.6em;
    background: white;
    border: 2px solid white;
    border-radius: 3px;
    color: #ff4215;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0.3em 0 0;
    transition: $default-transition;
    outline: none;
    -webkit-tap-highlight-color:  transparent;
    user-select: none;

    @media (max-width: $medium-less-width-breakpoint) {
      text-align: center;
    }

    &:hover {
      color: white;
      background: transparent;
    }
  }
}
