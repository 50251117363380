@import '../color_variables';

@keyframes on-pop-up {
  from {
    opacity: 0;
    transform: translateY(50%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.popup-window {
  &--hidden {
    display: none;
    opacity: 0;
  }

  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  z-index: 6;
  will-change: transform;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.2s;

  &__content {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0.4em;
    border-radius: 3px;
    box-shadow: 3px 2px 2px #1e1e1e91;
    max-width: 98%;
    transform-origin: center;
    animation: on-pop-up 0.3s cubic-bezier(0, 0, 0.31, 1.62) both;
    border-radius: 3px;
    overflow: hidden;
  }
}
