.horizontal-accordion-container,
.vertical-accordion-container,
.diagonal-accordion-container {
  overflow: hidden;
}

.horizontal-accordion-container,
.diagonal-accordion-container {
  white-space: nowrap;
}
