@import '../color_variables';
@import '../size_variables';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

.form-content {
  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 800px) {
      font-size: 0.9em;
    }

    @media (max-width: 480px) {
      font-size: 0.85em;
    }
  }

  &__container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    color: white;
    margin: 0 0 30px;
    width: 100%;
    // max-width: $max-default-width;

    @media (max-width: $medium-width-breakpoint) {
      margin: 0 0 25px;
      padding-left: $default-side-padding;
      padding-right: $default-side-padding;
    }

    @media (max-width: $small-width-breakpoint) {
      margin: 0 0 20px;
    }

    // @media (max-width: 800px) {
    //   margin-top: 25px;
    // }
  }

  &__title {
    text-align: left;
    font-size: 2.2em;
    font-weight: 700;
    margin: 0 0 0.75em;

    @media (max-width: $large-width-breakpoint) {
      font-size: 2.1em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      font-size: 2em;
    }
    @media (max-width: $high-width-breakpoint) {
      font-size: 1.8em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      font-size: 1.7em;
    }
    @media (max-width: $medium-width-breakpoint) {
      margin: 0 0 0.5em;
      align-self: center;
      text-align: center;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.6em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 1.45em / 0.9;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1.3em / 0.85;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 1.2em / 0.85;
    }
  }

  &__text {
    margin: 0 0 1.5em;
    text-align: justify;
    font-size: 1em;
    width: 100%;
    color: $default-gray-color;
    font-weight: bold;

    @media (max-width: $medium-plus-width-breakpoint) {
      margin: 0 0 1em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 0.95em;
    }
  }

  &__question-container {
    margin: 0;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    // position: absolute;
  }

  &__question-wrapper {
    position: relative;
    width: 100%;
  }

  &__controller-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: space-between;
    max-width: $max-default-width;
    width: 80%;
    z-index: 1;

    @media (max-width: $medium-width-breakpoint) {
      width: 100%;
      padding-left: $default-side-padding;
      padding-right: $default-side-padding;
    }
  }

  &__controller-button,
  &__controller-finish-button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25em;
    outline: none;
    border: 2px solid white;
    background: white;
    border-radius: 25px;
    color: $site-red-color;
    padding: 5px 15px;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    user-select: none;

    &:disabled {
      cursor: default;
      background: none;
      border: 2px solid $default-gray-color;
      color: $default-gray-color;
    }

    &:last-of-type {
      margin: 0 0 0 20px;
    }
  }

  &__controller-finish-button {
    background: $site-red-color;
    border: 2px solid $site-red-color;
    color: white;

    // &:disabled {
    //   cursor: default;
    //   background: none;
    //   border: 2px solid $default-gray-color;
    //   color: $default-gray-color;
    // }
  }

  &__controller-icon {
    font-size: 1.5em;

    &:first-child {
      margin: 0 15px 0 0;
    }

    &:last-child {
      margin: 0 0 0 15px;
    }
  }
}
