@import '../constants.scss';
@import '../size_variables.scss';


$alert-color: #f0ad4e;
$error-color: #d43f3a;
$loading-color: #5f5f5f;
$warning-color: #ea5460;
$default-text-color: #333;
$light-text-color: #777;

@keyframes SPIN_ANIMATION {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes PULSE_ANIMATION {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}


.confirmation-window {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 0;
  left: 0;

  @media (max-width: $smaller-width-breakpoint) {
    align-items: stretch;
  }

  &__container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    background: white;
    padding: 1em 2em 1.2em;
    margin: 0.4em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.95em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-height: $small-height-breakpoint) {
      margin: 4em 0 0;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 6.2em 0.4em 0;
      padding: 0.8em 0.5em;
    }
  }

  &__icon-wrapper {
    margin: 0.5em 0 1em;
  }

  &__alert-icon {
    font-size: 6em;
    color: $alert-color;
  }

  &__error-icon {
    position: relative;
    font-size: 6em;
    color: $error-color;
  }

  &__alert-icon,
  &__error-icon {
    @media (max-width: $small-width-breakpoint) {
      font-size: 5em;
    }
  }

  &__loading-icon {
    font-size: 3.5em;
    color: $loading-color;
    animation: SPIN_ANIMATION 3s linear infinite;

    @media (max-width: $small-width-breakpoint) {
      font-size: 3em;
    }
  }

  &__title--pulsing,
  &__title {
    color: $default-text-color;
    text-align: center;
    // font-weight: lighter;
    margin: 0 0 0.6em;
  }

  &__title--pulsing {
    animation: PULSE_ANIMATION 1s ease-in-out infinite alternate;
  }

  &__description {
    color: $light-text-color;
    text-align: center;
    min-width: 25em;
    max-width: 35em;
    // font-weight: lighter;
    margin: 0 0 1.5em;
    font-size: 0.95em;

    @media (max-width: $very-small-width-breakpoint) {
      min-width: auto;
    }
  }

  &__controls-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: center;
    overflow: hidden;
  }

  &__cancel-button,
  &__confirm-button {
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    user-select: none;
    padding: 0.6em 1em;
    font-size: 0.9em;
    min-width: 35px;
    min-height: 35px;
    color: white;
    transition: background $default-transition-duration;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    text-decoration: none;
  }

  &__cancel-button {
    border: 1px solid #ccc;
    color: $default-text-color;
    background: none;

    &:hover {
      background: #e6e6e6;
    }
  }

  &__confirm-button {
    border: 1px solid $warning-color;
    color: white;
    background: $warning-color;
    margin: 0 0 0 0.6em;

    &:hover {
      background: darken($warning-color, 15);
    }
  }
}
