@import '../color_variables.scss';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$default-animation-period: 0.6s;
$default-animation-function: cubic-bezier(0.17, 0.67, 0.31, 1.78);

$default-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.31, 1.78);
$service-transition-period: 0.2s;

$content-max-width: 75em;


$flow-circle-width: 62em;


@keyframes slide-in {
  from {
    opacity: 0;
    transform-origin: -30% center;
    transform: rotate(5deg) translateX(-20%);
  }

  to {
    opacity: 1;
    transform-origin: -30% center;
    transform: rotate(0) translateX(0);
  }
}

@keyframes pop-in {
  from {
    opacity: 0;
    transform: scale(0.8, 0.8) translateY(-2em);
  }

  to {
    opacity: 1;
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes hex-entry {
  from {
    transform-origin: center center;
    transform: translate(100%, -80%) rotate(120deg) scale(0.2);
    opacity: 0;
  }

  to {
    transform-origin: center center;
    transform: translate(0, 0) rotate(0) scale(1);
    opacity: 1;
  }
}

@keyframes hex-spin {
  from {
    transform-origin: center center;
    transform: rotate(0) scale(1);
  }

  25% {
    transform-origin: center center;
    transform: rotate(-90deg) scale(1.2);
  }

  50% {
    transform-origin: center center;
    transform: rotate(-180deg) scale(1);
  }

  75% {
    transform-origin: center center;
    transform: rotate(-270deg) scale(1);
  }

  to {
    transform-origin: center center;
    transform: rotate(-360deg) scale(1);
  }
}

@keyframes line-detail-animation-1 {
  from {
    transform: rotate(31deg) translateY(-30%);
  }

  to {
    transform: rotate(31deg) translateY(30%);
  }
}

@keyframes line-detail-animation-2 {
  from {
    transform: rotate(2deg) translateY(30%);
  }

  to {
    transform: rotate(2deg) translateY(-30%);
  }
}

@keyframes line-detail-animation-3 {
  from {
    transform: rotate(-1deg) translateY(-30%);
  }

  to {
    transform: rotate(-1deg) translateY(30%);
  }
}

@keyframes slow-bounce {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-75%);
  }
}

@keyframes rotated-slow-bounce {
  from {
    transform: rotate(180deg) translateY(0);
  }

  to {
    transform: rotate(180deg) translateY(-75%);
  }
}

@keyframes person-cycle {
  from {
    transform: translateY(6%);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  47% {
    opacity: 1;
  }

  49% {
    opacity: 0;
    transform: translateY(0);
  }

  to {
    transform: translateY(0);
    opacity: 0;
  }
}

@keyframes glowing-text {
  from {
    text-shadow: 0 0 0px white;
    color: white;
  }

  50% {
    text-shadow: 0 0 0px white;
    color: white;
  }

  51% {
    text-shadow: 0 0 2px #f6ff68;
    color: #f6ff68;
  }

  52% {
    text-shadow: 0 0 0px white;
    color: white;
  }

  54% {
    text-shadow: 0 0 2px #f6ff68;
    color: #f6ff68;
  }

  55% {
    text-shadow: 0 0 0px white;
    color: white;
  }

  56% {
    text-shadow: 0 0 2px #f6ff68;
    color: #f6ff68;
  }

  98% {
    text-shadow: 0 0 2px #f6ff68;
    color: #f6ff68;
  }

  to {
    text-shadow: 0 0 0px white;
    color: white;
  }
}

@keyframes slide-and-fade-in {
  from {
    transform: translateX(120%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-and-fade-out {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(120%);
    opacity: 0;
  }
}

@keyframes bump {
  from {
    transform: scale(1, 1);
  }

  85% {
    transform: scale(1, 1);
  }

  to {
    transform: scale(1.1, 1.1);
  }
}

$blink-duration: 0.6s;
$blink-delay-percentage: 0.3;

@keyframes blink-1 {
  from {
  }

  11.11% {
    color: $site-red-color;
  }

  22.22% {
  }

  to {
  }
}
@keyframes blink-2 {
  from {
  }

  13.89% {
    color: $site-red-color;
  }

  27.78% {
  }

  to {
  }
}
@keyframes background-blink-1 {
  from {
    font-size: 1em;
  }

  11.11% {
    font-size: 1.03em;
    background: $site-red-color;
  }

  22.22% {
    font-size: 1em;
  }

  to {
    font-size: 1em;
  }
}
@keyframes background-blink-2 {
  from {
    font-size: 1em;
  }

  13.89% {
    font-size: 1.03em;
    background: $site-red-color;
  }

  27.78% {
    font-size: 1em;
  }

  to {
    font-size: 1em;
  }
}


.main-page {
  // css parallax
  perspective: 1px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  &__main-banner {
    margin: 6em 0 0;

    @media (max-width: 1030px) {
      margin: 5em 0 0;
    }
    @media (max-width: 910px) {
      margin: 8em 0 0;
    }
    @media (max-width: 750px) {
      margin: 4.3em 0 0;
    }
    @media (max-width: 490px) {
      margin: 3.7em 0 0;
    }

    &,
    &--promotion-enalbed {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
    }

    &--promotion-enalbed {
      margin: 11em 0 0;

      @media (max-width: 1030px) {
        margin: 10em 0 0;
      }
      @media (max-width: 910px) {
        margin: 13em 0 0;
      }
      @media (max-width: 750px) {
        margin: 9.3em 0 0;
      }
      @media (max-width: 490px) {
        margin: 8.7em 0 0;
      }
    }

    &__images-container {
      position: absolute;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      overflow: hidden;
    }

    &__main-image {
      object-fit: cover;
      object-position: center center;
      width: 100%;
    }

    &__red-filter {
      display: block;
      position: absolute;
      object-fit: cover;
      width: 100%;
      opacity: 0.93;
      height: 100%;
      background: linear-gradient(to top, $site-red-color 85%, #d06d45);

      @media (max-width: $small-width-breakpoint) {
        opacity: 0.8;
      }
    }

    &__background-hex {
      display: block;
      position: absolute;
      object-fit: cover;
      object-position: center center;
      height: 84%;
      bottom: 1.5em;
      margin: 0 0 0 54em;
      animation: hex-entry 1.5s cubic-bezier(0.28, 1.29, 0.6, 0.98) both 0.5s, hex-spin 80s linear 2.5s infinite;

      @media (max-width: $large-width-breakpoint) {
        margin: 0 0 0 36em;
      }

      @media (max-width: $high-width-breakpoint) {
        margin: 0 0 0 31em;
        bottom: 1.2em;
      }

      @media (max-width: $medium-width-breakpoint) {
        // margin: 0 0 0 15.5em;
        // align-self: flex-end;
        right: -14em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        // margin: 0 0 0 13.5em;
        right: -13.5em;
      }

      @media (max-width: $small-width-breakpoint) {
        // margin: 0 0 0 12em;
        bottom: 0.9em;
        right: -12.5em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        // margin: 0 0 0 11em;
        right: -11.5em;
      }
    }

    &__background-line-detail {
      &--1,
      &--2,
      &--3 {
        display: block;
        position: absolute;
        height: 400%;
        width: 2px;
        background: linear-gradient(to top, #e6c89157 50%, #efe59f, #e6c89157 60%);
        transform-origin: center center;
      }

      &--1 {
        transform: rotate(31deg);
        // margin: 0 0 0 3.9em;
        margin: 0 0 0 -3em;
        animation: line-detail-animation-1 8s linear backwards 0.5s infinite;

        @media (max-width: $large-width-breakpoint) {
          // margin: 0 0 0 -1em;
          margin: 0 0 0 -9em;
        }

        @media (max-width: $medium-width-breakpoint) {
          margin: 0 0 0 5em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0;
        }

        @media (max-width: $small-width-breakpoint) {
          margin: 0 0 0 -2em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          margin: 0 0 0 -1.5em;
        }
      }

      &--2 {
        transform: rotate(2deg);
        // margin: 0 0 0 -27.5em;
        margin: 0 0 0 -55em;
        animation: line-detail-animation-2 4s linear backwards 3.8s infinite;

        @media (max-width: $high-width-breakpoint) {
          // margin: 0 0 0 -24em;
          margin: 0 0 0 -40em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0;
          left: 4em;
        }

        @media (max-width: $small-width-breakpoint) {
          left: 3em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          // margin: 0 0 0 -10em;
          left: 2em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          // margin: 0 0 0 -9em;
        }
      }

      &--3 {
        transform: rotate(-1deg);
        margin: 0 0 0 38em;
        animation: line-detail-animation-3 4s ease-in-out both 2s alternate infinite;

        @media (max-width: $large-width-breakpoint) {
          margin: 0 0 0 22em;
        }

        @media (max-width: $high-width-breakpoint) {
          margin: 0 0 0 19em;
        }

        @media (max-width: $medium-width-breakpoint) {
          margin: 0 0 0 43em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0;
          right: 5.5em;
        }

        @media (max-width: $small-width-breakpoint) {
          // margin: 0 0 0 12em;
          right: 4em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          // margin: 0 0 0 10em;
          right: 3em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          // margin: 0 0 0 9em;
        }
      }
    }

    &__background-arrow {
      &--1,
      &--2,
      &--3 {
        display: block;
        position: absolute;
        object-fit: cover;
        object-position: center center;
        will-change: transform;
      }

      &--1 {
        height: 3.4em;
        margin: 0 0 0 -75em;
        bottom: 1.7em;
        animation: slow-bounce 10s ease-in-out both alternate infinite;

        @media (max-width: $large-width-breakpoint) {
          height: 2.7em;
          margin: 0 0 0 -70em;
          bottom: 1.4em;
        }

        @media (max-width: $high-width-breakpoint) {
          height: 2.4em;
          margin: 0 0 0 -50em;
          bottom: 1.3em;
        }

        @media (max-width: $medium-width-breakpoint) {
          height: 2.2em;
          margin: 0 0 0 -35em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          height: 1.9em;
          margin: 0 0 0 -30em;
        }

        @media (max-width: $small-width-breakpoint) {
          margin: 0 0 0 -20em;
          height: 1.6em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          margin: 0 0 0 -12em;
          height: 1.4em;
        }
      }

      &--2 {
        height: 2.2em;
        margin: 0 0 0 -7em;
        bottom: 1.5em;
        animation: slow-bounce 6s ease-in-out both 4.5s alternate infinite;

        @media (max-width: $large-width-breakpoint) {
          height: 2em;
          margin: 0 0 0 -11em;
          bottom: 1.2em;
        }

        @media (max-width: $high-width-breakpoint) {
          height: 1.6em;
          margin: 0 0 0 -10em;
          bottom: 1em;
        }

        @media (max-width: $medium-width-breakpoint) {
          height: 1.6em;
          margin: 0 0 0 7em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          height: 1.4em;
          margin: 0 0 0 5em;
          bottom: 0.8em;
        }

        @media (max-width: $small-width-breakpoint) {
          height: 1.3em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          height: 1.2em;
        }
      }

      &--3 {
        transform-origin: center center;
        transform: rotate(180deg);
        height: 2.4em;
        margin: 0 0 0 24em;
        top: 1.5em;
        animation: rotated-slow-bounce 7s ease-in-out both 10s alternate infinite;

        @media (max-width: $large-width-breakpoint) {
          height: 2.2em;
          margin: 0 0 0 14em;
          top: 1.2em;
        }

        @media (max-width: $high-width-breakpoint) {
          height: 1.8em;
          margin: 0 0 0 12em;
          top: 1em;
        }

        @media (max-width: $medium-width-breakpoint) {
          height: 1.5em;
          margin: 0 0 0 32em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          height: 1.4em;
          margin: 0 0 0 23em;
          top: 0.8em;
        }

        @media (max-width: $small-width-breakpoint) {
          height: 1.3em;
          margin: 0 0 0 15em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          height: 1.2em;
          margin: 0 0 0 7em;
        }
      }
    }

    &__person-image {
      &--1,
      &--2 {
        display: block;
        position: absolute;
        height: 42em;
        bottom: 0;
        margin: 0 0 0 48.2em;
        margin: 0 0 0 54em;

        @media (max-width: $large-width-breakpoint) {
          height: 36em;
          margin: 0 0 0 36em;
        }

        @media (max-width: $high-width-breakpoint) {
          height: 31em;
          margin: 0 0 0 31em;
        }

        @media (max-width: $medium-width-breakpoint) {
          // align-self: flex-end;
          // margin: 0 0 0 14em;
          height: 30em;
          right: -13.5em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          height: 26em;
          // margin: 0 0 0 15em;
          right: -14.5em;
        }

        @media (max-width: $small-width-breakpoint) {
          height: 23em;
          // margin: 0 0 0 13.4em;
          right: -13em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          height: 21.5em;
          // margin: 0 0 0 12.6em;
          right: -12.3em;
        }
      }

      &--1 {
        animation: person-cycle 20s linear both 1.2s infinite;
      }

      &--2 {
        animation: person-cycle 20s linear both 11.2s infinite;
      }
    }

    &__images-container,
    &__main-image,
    &__red-filter,
    &__content {
      min-height: 100px;
      height: 44.25em;

      @media (max-width: $large-width-breakpoint) {
        height: 37.44em;
      }

      @media (max-width: $high-width-breakpoint) {
        height: 32.67em;
      }

      @media (max-width: $medium-width-breakpoint) {
        height: 30.63em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        height: 27.23em;
      }

      @media (max-width: $small-width-breakpoint) {
        height: 23.83em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        height: 21.7em;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      font-family: 'Montserrat', sans-serif;
      z-index: 2;
      max-width: $content-max-width;
      padding: 8.9em 0.8em 0;
      color: white;

      @media (max-width: $large-width-breakpoint) {
        padding: 8.4em 0.8em 0;
      }

      @media (max-width: $high-width-breakpoint) {
        padding: 8em 3em 0;
      }

      @media (max-width: $medium-width-breakpoint) {
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      @media (max-width: $small-width-breakpoint) {
        align-items: stretch;
        justify-content: stretch;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      @media (max-width: $medium-width-breakpoint) {
        align-items: center;
        justify-content: center;
        padding: 1.5em 1em;
        background: #350d116e;
        border-radius: 3px;
        box-shadow: 0px 0px 2px 1px #0000006b;
      }

      @media (max-width: $small-width-breakpoint) {
        flex: 1;
        box-shadow: none;
      }

      @media (max-width: $very-small-width-breakpoint) {
        padding: 0.8em 0.4em;
      }
    }

    &__header {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 4.1em;
      max-width: 11em;
      line-height: 1.1em;
      animation: slide-in $default-animation-period $default-animation-function 0.9s backwards;

      @media (max-width: $large-width-breakpoint) {
        font-size: 3.5em;
      }

      @media (max-width: $high-width-breakpoint) {
        font-size: 2.7em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 3.3em;
        text-align: center;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 2.7em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 2.4em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.9em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1.7em;
      }

      &__strong-text {
        font-size: 1.2em;
        font-weight: 700;
        animation: glowing-text 7s linear both 1.5s infinite;

        @media (max-width: $small-width-breakpoint) {
          font-weight: 800;
        }
      }
    }

    &__minor-specifier {
      font-size: 1.25em;
      text-transform: uppercase;
      font-weight: 400;
      margin: 0;
      animation: slide-in $default-animation-period $default-animation-function 1.3s backwards;
      text-decoration: underline;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.0671em;
      }

      @media (max-width: $high-width-breakpoint) {
        font-size: 0.8232em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.0061em;
        text-align: center;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.8232em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.7317em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.5793em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.5183em;
      }
    }

    &__specifier {
      font-size: 2em;
      text-transform: uppercase;
      font-weight: 100;
      margin: 0.5em 0 0;
      animation: slide-in $default-animation-period $default-animation-function 1.7s backwards;
      max-width: 20em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-weight: 300;
        text-align: center;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.9em;
        font-weight: 400;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.8em;
      }
    }

    &__links-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 4em 0 0;
      min-width: 0;

      @media (max-width: $large-width-breakpoint) {
        margin: 3.5em 0 0;
      }

      @media (max-width: $high-width-breakpoint) {
        margin: 3em 0 0;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        margin: 2em 0 0;
      }

      @media (max-width: $small-width-breakpoint) {
        margin: 2.5em 0 0;
      }

      @media (max-width: $tiny-width-breakpoint) {
        align-self: stretch;
        align-items: stretch;
      }
    }

    &__experimental-class-link,
    &__services-link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.4em;
      padding: 0.4em 0;
      font-weight: 700;
      width: 24.5em;
      border-radius: 3px;
      min-width: 35px;
      min-height: 35px;
      transition: $default-transition;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        width: auto;
        text-align: center;
        padding: 0.4em 1em;
      }
    }

    &__experimental-class-link {
      color: $default-text-color;
      background: white;
      animation: pop-in $default-animation-period $default-animation-function 2.1s backwards;

      &__icon {
        font-size: 1.4em;
        margin: 0 0.4em 0 0;

        @media (max-width: $tiny-width-breakpoint) {
          position: absolute;
          left: 1em;
        }
      }

      &:hover {
        color: white;
        background: $default-text-color;
        transform: scale(1.1, 1.1);
      }
    }

    &__services-link {
      margin: 0.7em 0 0;
      color: inherit;
      border: 1px dashed white;
      animation: pop-in $default-animation-period $default-animation-function 2.1s backwards;

      &:hover {
        border: 1px dashed $default-text-color;
        color: $default-text-color;
        transform: scale(1.1, 1.1);
      }
    }
  }

  &__objectives-section {
    $section-background-color: $light-gray-color;

    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: radial-gradient($section-background-color, $section-background-color);

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      max-width: $content-max-width;
      padding: 5.5em 0.8em 5em;
      overflow: hidden;

      @media (max-width: $extra-high-width-breakpoint) {
        // padding: 4.5em 1.8em 4.5em 3.8em;
        padding: 4.5em 1.8em 4.5em 1.8em;
      }

      @media (max-width: $high-width-breakpoint) {
        // padding: 4.7em 1.8em 4.7em 3.8em;
        padding: 4.7em 1.8em 4.7em 1.8em;
        width: 100%;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        padding: 4em 1.2em 4em 1.2em;
      }

      @media (max-width: $medium-width-breakpoint) {
        flex-flow: column nowrap;
        justify-content: flex-start;
        padding: 4em 1.5em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        padding: 3.2em 1em;
      }

      @media (max-width: $small-width-breakpoint) {
        padding: 2.8em 0.8em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        padding: 1.9em 0.4em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        padding: 1.7em 0.4em;
      }
    }

    &__article {
      $odd-background-color: #2f2f2f;

      // position: relative;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: 6.8em;

        @media (max-width: $medium-plus-width-breakpoint) {
          margin-bottom: 5.8em;
        }
        @media (max-width: $medium-width-breakpoint) {
          margin-bottom: 6.1em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          margin-bottom: 4.8em;
        }
        @media (max-width: $small-width-breakpoint) {
          margin-bottom: 4.2em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          margin-bottom: 2.5em;
        }
      }

      &:nth-child(odd) {
        justify-content: flex-start;
      }
      &:nth-child(even) {
        justify-content: flex-end;
      }

      &__content-wrapper {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        z-index: 2;

        @media (max-width: $medium-less-width-breakpoint) {
          height: 12.637em;
        }
        @media (max-width: $small-width-breakpoint) {
          height: 13.2em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          height: 14em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          height: 13.5em;
        }
      }

      &:nth-child(odd) &__content-wrapper {
        align-items: flex-start;
        margin: 0 12.5em 0 0;

        @media (max-width: $large-width-breakpoint) {
          margin: 0 18em 0 0;
        }
        @media (max-width: $extra-high-width-breakpoint) {
          margin: 0 20em 0 0;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          &:before {
            content: '';
            position: absolute;
            width: 102%;
            height: 110%;
            right: 0;
            background: linear-gradient(to right, #00000052 50%, transparent 95%);
            border-radius: 3px;
          }
        }
        @media (max-width: $small-width-breakpoint) {
          &:before {
            background: #00000052;
            width: 104%;
            right: -2%;
          }
        }
      }
      &:nth-child(even) &__content-wrapper {
        align-items: flex-end;
        margin: 0 0 0 13em;

        @media (max-width: $large-width-breakpoint) {
          margin: 0 0 0 18.5em;
        }
        @media (max-width: $extra-high-width-breakpoint) {
          margin: 0 0 0 21em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          &:before {
            content: '';
            position: absolute;
            width: 102%;
            height: 110%;
            left: 0;
            background: linear-gradient(to left, #00000052 50%, transparent 95%);
            border-radius: 3px;
          }
        }
        @media (max-width: $small-width-breakpoint) {
          &:before {
            background: #00000052;
            width: 104%;
            left: -2%;
          }
        }
      }

      &:nth-child(1) &__content-wrapper {
        margin: 0 12.5em 0 0;

        @media (max-width: $large-width-breakpoint) {
          margin: 0 18em 0 0;
        }
        @media (max-width: $extra-high-width-breakpoint) {
          margin: 0 20.5em 0 0;
        }
        @media (max-width: $high-width-breakpoint) {
          margin: 0 19em 0 0;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          margin: 0 16.8em 0 0;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0;
        }
      }
      &:nth-child(2) &__content-wrapper {
        margin: 0 0 0 11.5em;

        @media (max-width: $large-width-breakpoint) {
          margin: 0 0 0 17em;
        }
        @media (max-width: $extra-high-width-breakpoint) {
          margin: 0 0 0 19em;
        }
        @media (max-width: $high-width-breakpoint) {
          margin: 0 0 0 17.2em;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          margin: 0 0 0 15em;
        }
        @media (max-width: $medium-width-breakpoint) {
          margin: 0 0 0 15em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0;
        }
      }
      &:nth-child(3) &__content-wrapper {
        margin: 0 9em 0 0;

        @media (max-width: $large-width-breakpoint) {
          margin: 0 14.5em 0 0;
        }
        @media (max-width: $extra-high-width-breakpoint) {
          margin: 0 16em 0 0;
        }
        @media (max-width: $high-width-breakpoint) {
          margin: 0 14.5em 0 0;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          margin: 0 13.5em 0 0;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0;
        }
      }
      &:nth-child(4) &__content-wrapper {
        margin: 0 0 0 13.5em;

        @media (max-width: $large-width-breakpoint) {
          margin: 0 0 0 19em;
        }
        @media (max-width: $extra-high-width-breakpoint) {
          margin: 0 0 0 20.5em;
        }
        @media (max-width: $high-width-breakpoint) {
          margin: 0 0 0 19em;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          margin: 0 0 0 16.5em;
        }
        @media (max-width: $medium-width-breakpoint) {
          margin: 0 0 0 16.3em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0;
        }
      }

      &__title,
      &__text {
        z-index: 2;
      }

      &__title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 2.5em;
        margin: 0 0 0.5em;
        color: white;

        &--strong {
          font-size: 1.05em;
          font-weight: 900;
        }

        @media (max-width: $large-width-breakpoint) {
          font-size: 2.3em;
        }
        @media (max-width: $extra-high-width-breakpoint) {
          font-size: 2.1em;
        }
        @media (max-width: $high-width-breakpoint) {
          font-size: 1.8em;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          font-size: 1.6em;
        }
        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.5em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 1.4em;
        }
        @media (max-width: $small-width-breakpoint) {
          max-width: 12em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.25em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 1.2em;
        }
      }

      &:nth-child(odd) &__title {
        text-align: left;

        &--strong {
          color: $odd-background-color;
        }
      }
      &:nth-child(even) &__title {
        text-align: right;

        &--strong {
          color: $site-light-red-color;
        }
      }

      &__text {
        font-size: 1.3em;
        font-weight: 300;
        text-transform: uppercase;
        text-align: justify;
        color: white;
        height: 6.134em;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.2em;
        }
        @media (max-width: $extra-high-width-breakpoint) {
          font-size: 1.1em;
        }
        @media (max-width: $high-width-breakpoint) {
          font-size: 1.07em;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          font-size: 0.96em;
        }
        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.93em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          height: auto;
          max-width: 70%;
          font-weight: 400;
        }
        @media (max-width: $small-width-breakpoint) {
          height: auto;
          max-width: none;
          font-weight: 500;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.86em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.8em;
        }
      }

      // &:nth-child(odd) &__text {
      //   color: white;
      // }
      // &:nth-child(even) &__text {
      //   color: $odd-background-color;
      // }

      // &:nth-child(odd) &__text {
      //   text-align: left;
      // }
      // &:nth-child(even) &__text {
      //   text-align: right;
      // }

      &__hex {
        // display: none;
        position: absolute;
        z-index: 1;
        width: 15em;
      }

      &__background {
        align-items: flex-end;
        justify-content: center;

        &,
        &--centered {
          display: flex;
          flex-flow: row nowrap;
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 18em;
          left: 0;
          overflow: hidden;

          @media (max-width: $large-width-breakpoint) {
            height: 17em;
          }
          @media (max-width: $extra-high-width-breakpoint) {
            height: 16.5em;
          }
          @media (max-width: $high-width-breakpoint) {
            height: 15.5em;
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            height: 14em;
          }
          @media (max-width: $medium-less-width-breakpoint) {
            height: 17em;
          }
          @media (max-width: $very-small-width-breakpoint) {
            height: 16.2em;
          }
          @media (max-width: $tiny-width-breakpoint) {
            height: 15.7em;
          }
        }

        &--centered {
          align-items: center;
        }
      }

      &:nth-child(odd) &__background {
        &,
        &--centered {
          background: linear-gradient(to left, $site-red-color -15%, $site-light-red-color 15%, $site-red-color 100%);
          clip-path: polygon(99% 0,94% 101%,0 101%,0 0);

          @media (max-width: $medium-less-width-breakpoint) {
            clip-path: polygon(99% 0,90% 101%,0 101%,0 0);
          }
          @media (max-width: $small-width-breakpoint) {
            clip-path: none;
          }
        }

        &--centered {
          justify-content: flex-end;
        }
      }
      &:nth-child(even) &__background {
        &,
        &--centered {
          background: linear-gradient(to right, $odd-background-color -15%, #565656 15%, $odd-background-color 100%);
          clip-path: polygon(100% 0,100% 101%,6% 101%,1% 0);

          @media (max-width: $medium-less-width-breakpoint) {
            clip-path: polygon(100% 0,100% 101%,10% 101%,1% 0);
          }
          @media (max-width: $small-width-breakpoint) {
            clip-path: none;
          }
        }

        &--centered {
          justify-content: flex-start;
        }
      }

      &:nth-child(odd) &__hex {
        left: -7.5em;
      }
      &:nth-child(even) &__hex {
        right: -7.5em;
      }

      &__img {
        &-1,
        &-2,
        &-3,
        &-4 {
          position: relative;
          z-index: 2;

          @media (max-width: $large-width-breakpoint) {
            font-size: 0.95em;
          }
          @media (max-width: $extra-high-width-breakpoint) {
            font-size: 0.92em;
          }
          @media (max-width: $high-width-breakpoint) {
            font-size: 0.85em;
            position: absolute;
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            font-size: 0.76em;
          }
          @media (max-width: $medium-less-width-breakpoint) {
            font-size: 0.94em;
          }
          @media (max-width: $small-width-breakpoint) {
            opacity: 0.9;
          }
          @media (max-width: $very-small-width-breakpoint) {
            font-size: 0.86em;
            opacity: 0.85;
          }
          @media (max-width: $tiny-width-breakpoint) {
            font-size: 0.8em;
          }
        }

        &-1 {
          left: 33.5em;
          width: 17em;

          @media (max-width: $large-width-breakpoint) {
            left: 29em;
          }
          @media (max-width: $extra-high-width-breakpoint) {
            left: 26em;
          }
          @media (max-width: $high-width-breakpoint) {
            left: auto;
            right: 6em;
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            right: 5.3em;
          }
          @media (max-width: $medium-less-width-breakpoint) {
            right: 0;
          }
          @media (max-width: $small-width-breakpoint) {
            right: -1.5em;
          }
        }
        &-2 {
          height: 100%;

          @media (min-width: 1700px) {
            left: 1em;
          }

          // right: 34.5em;
          // width: 16em;
          //
          // @media (max-width: $large-width-breakpoint) {
          //   right: 30em;
          // }
          // @media (max-width: $extra-high-width-breakpoint) {
          //   right: 27.5em;
          // }
          // @media (max-width: $high-width-breakpoint) {
          //   right: auto;
          //   left: 5em;
          // }
          // @media (max-width: $medium-plus-width-breakpoint) {
          //   left: 4.1em;
          // }
          // @media (max-width: $medium-less-width-breakpoint) {
          //   left: 0;
          // }
          // @media (max-width: $small-width-breakpoint) {
          //   left: -1.5em;
          // }
        }
        &-3 {
          height: 100%;

          @media (min-width: 1700px) {
            right: 1em;
          }

          // left: 35em;
          // width: 12em;
          //
          // @media (max-width: $large-width-breakpoint) {
          //   left: 30.5em;
          // }
          // @media (max-width: $extra-high-width-breakpoint) {
          //   left: 28.5em;
          // }
          // @media (max-width: $high-width-breakpoint) {
          //   left: auto;
          //   right: 6em;
          // }
          // @media (max-width: $medium-plus-width-breakpoint) {
          //   // right: 6em;
          // }
          // @media (max-width: $medium-less-width-breakpoint) {
          //   right: 0;
          // }
          // @media (max-width: $small-width-breakpoint) {
          //   right: -1.5em;
          // }
        }
        &-4 {
          right: 33.5em;
          width: 18em;

          @media (max-width: $large-width-breakpoint) {
            right: 29em;
          }
          @media (max-width: $extra-high-width-breakpoint) {
            right: 26.5em;
          }
          @media (max-width: $high-width-breakpoint) {
            right: auto;
            left: 5em;
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            left: 4em;
          }
          @media (max-width: $medium-less-width-breakpoint) {
            left: 0;
          }
          @media (max-width: $small-width-breakpoint) {
            left: -1.5em;
          }
        }
      }

      // &:nth-child(odd) &__img {
      //   &-1,
      //   &-3 {
      //     left: -7.5em;
      //   }
      // }
    }
  }

  &__methodology-section {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: linear-gradient(to left, $site-red-color 30%, #ff4b00);
    background: linear-gradient(to bottom, $site-red-color, #ff4b00);

    @media (max-width: $small-width-breakpoint) {
      border: 0 solid $light-gray-color;
      border-bottom-width: 1px;
    }

    &__content {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: space-between;
      flex: 1;
      max-width: $content-max-width;
      padding: 2.6em 0.8em 3.2em;

      @media (max-width: $large-width-breakpoint) {
        padding: 2.5em 0.8em 3em;
      }

      @media (max-width: $extra-high-width-breakpoint) {
        padding: 2.3em 1.8em 2.7em 2.9em;
      }

      @media (max-width: $high-width-breakpoint) {
        padding: 2em 1.8em 2em 3.8em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        padding: 2em 1.2em 1.8em 3em;
      }

      @media (max-width: $medium-width-breakpoint) {
        padding: 2.5em 1.5em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        padding: 2.3em 1em;
      }

      @media (max-width: 656px) {
        padding: 2em 1em;
      }

      @media (max-width: $small-width-breakpoint) {
        padding: 1.5em 0.8em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        padding: 1.2em 0.8em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        padding: 1.2em 0.4em;
      }
    }

    &__illustration--wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;

      @media (max-width: $small-width-breakpoint) {
        align-items: flex-start;
        position: absolute;
      }
      @media (max-width: $tiny-width-breakpoint) {
        width: 100%;
        justify-content: center;
      }
    }

    &__illustration {
      $default-item-size: 8.5em;
      $default-illustration-height: 30em;
      $default-illustration-width: (1.1547 * $default-illustration-height) -  (0.1547 * $default-item-size);
      $default-center-dy: -1 * ((0.5 * $default-item-size) + (0.2887 * ($default-illustration-width - $default-item-size)) - (0.5 * $default-illustration-height));
      $default-arrow-size: 2.8em;
      $default-arrow-dy: 1em * ($default-center-dy) / $default-arrow-size;
      $default-arrow-inner-radius: 1em * -0.57735 * ($default-illustration-width - $default-item-size) / $default-arrow-size;

      $large-item-size: 8em;
      $large-illustration-height: 27em;
      $large-illustration-width: (1.1547 * $large-illustration-height) -  (0.1547 * $large-item-size);
      $large-center-dy: -1 * ((0.5 * $large-item-size) + (0.2887 * ($large-illustration-width - $large-item-size)) - (0.5 * $large-illustration-height));
      $large-arrow-size: 2.8em;
      $large-arrow-dy: 1em * ($large-center-dy) / $large-arrow-size;
      $large-arrow-inner-radius: 1em * -0.57735 * ($large-illustration-width - $large-item-size) / $large-arrow-size;

      $extra-high-item-size: 7.4em;
      $extra-high-illustration-height: 25em;
      $extra-high-illustration-width: (1.1547 * $extra-high-illustration-height) -  (0.1547 * $extra-high-item-size);
      $extra-high-center-dy: -1 * ((0.5 * $extra-high-item-size) + (0.2887 * ($extra-high-illustration-width - $extra-high-item-size)) - (0.5 * $extra-high-illustration-height));
      $extra-high-arrow-size: 2.5em;
      $extra-high-arrow-dy: 1em * ($extra-high-center-dy) / $extra-high-arrow-size;
      $extra-high-arrow-inner-radius: 1em * -0.57735 * ($extra-high-illustration-width - $extra-high-item-size) / $extra-high-arrow-size;

      $high-item-size: 6.8em;
      $high-illustration-height: 23em;
      $high-illustration-width: (1.1547 * $high-illustration-height) -  (0.1547 * $high-item-size);
      $high-center-dy: -1 * ((0.5 * $high-item-size) + (0.2887 * ($high-illustration-width - $high-item-size)) - (0.5 * $high-illustration-height));
      $high-arrow-size: 2.5em;
      $high-arrow-dy: 1em * ($high-center-dy) / $high-arrow-size;
      $high-arrow-inner-radius: 1em * -0.57735 * ($high-illustration-width - $high-item-size) / $high-arrow-size;

      $medium-plus-item-size: 6.2em;
      $medium-plus-illustration-height: 21.5em;
      $medium-plus-illustration-width: (1.1547 * $medium-plus-illustration-height) -  (0.1547 * $medium-plus-item-size);
      $medium-plus-center-dy: -1 * ((0.5 * $medium-plus-item-size) + (0.2887 * ($medium-plus-illustration-width - $medium-plus-item-size)) - (0.5 * $medium-plus-illustration-height));
      $medium-plus-arrow-size: 2.2em;
      $medium-plus-arrow-dy: 1em * ($medium-plus-center-dy) / $medium-plus-arrow-size;
      $medium-plus-arrow-inner-radius: 1em * -0.57735 * ($medium-plus-illustration-width - $medium-plus-item-size) / $medium-plus-arrow-size;

      $medium-item-size: 5.8em;
      $medium-illustration-height: 19em;
      $medium-illustration-width: (1.1547 * $medium-illustration-height) -  (0.1547 * $medium-item-size);
      $medium-center-dy: -1 * ((0.5 * $medium-item-size) + (0.2887 * ($medium-illustration-width - $medium-item-size)) - (0.5 * $medium-illustration-height));
      $medium-arrow-size: 2.2em;
      $medium-arrow-dy: 1em * ($medium-center-dy) / $medium-arrow-size;
      $medium-arrow-inner-radius: 1em * -0.57735 * ($medium-illustration-width - $medium-item-size) / $medium-arrow-size;

      $medium-less-item-size: 3.5em;
      $medium-less-illustration-height: 12em;
      $medium-less-illustration-width: (1.1547 * $medium-less-illustration-height) -  (0.1547 * $medium-less-item-size);
      $medium-less-center-dy: -1 * ((0.5 * $medium-less-item-size) + (0.2887 * ($medium-less-illustration-width - $medium-less-item-size)) - (0.5 * $medium-less-illustration-height));
      $medium-less-arrow-size: 1.8em;
      $medium-less-arrow-dy: 1em * ($medium-less-center-dy) / $medium-less-arrow-size;
      $medium-less-arrow-inner-radius: 1em * -0.57735 * ($medium-less-illustration-width - $medium-less-item-size) / $medium-less-arrow-size;

      $small-item-size: 3.5em;
      $small-illustration-height: 10.5em;
      $small-illustration-width: (1.1547 * $small-illustration-height) -  (0.1547 * $small-item-size);
      $small-center-dy: -1 * ((0.5 * $small-item-size) + (0.2887 * ($small-illustration-width - $small-item-size)) - (0.5 * $small-illustration-height));
      $small-arrow-size: 1.6em;
      $small-arrow-dy: 1em * ($small-center-dy) / $small-arrow-size;
      $small-arrow-inner-radius: 1em * -0.57735 * ($small-illustration-width - $small-item-size) / $small-arrow-size;

      $item-color-1: #a4c1a3;
      $item-color-2: #d7e08d;
      $item-color-3: #88b9c7;

      position: relative;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: $default-illustration-width;
      height: $default-illustration-height;
      flex-shrink: 0;
      font-size: 1em;
      bottom: 1.5em;
      right: 3em;
      flex-shrink: 0;

      @media (max-width: $large-width-breakpoint) {
        width: $large-illustration-width;
        height: $large-illustration-height;
        right: auto;
        left: 0.5em;
      }
      @media (max-width: $extra-high-width-breakpoint) {
        width: $extra-high-illustration-width;
        height: $extra-high-illustration-height;
      }
      @media (max-width: $high-width-breakpoint) {
        width: $high-illustration-width;
        height: $high-illustration-height;
        left: auto;
        right: 0.5em;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        width: $medium-plus-illustration-width;
        height: $medium-plus-illustration-height;
        right: 0.8em;
      }
      @media (max-width: $medium-width-breakpoint) {
        width: $medium-illustration-width;
        height: $medium-illustration-height;
        right: auto;
        left: 0.5em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        left: 1.3em;
        width: $medium-less-illustration-width;
        height: $medium-less-illustration-height;
      }
      @media (max-width: $small-width-breakpoint) {
        top: 1em;
        width: $small-illustration-width;
        height: $small-illustration-height;
      }
      @media (max-width: $very-small-width-breakpoint) {
        top: 3em;
        right: auto;
        left: 10vw;
      }
      @media (max-width: $tiny-width-breakpoint) {
        top: 4.7em;
        left: auto;
        right: 7vw;
      }

      &__item {
        &-1,
        &-2,
        &-3 {
          &::before {
            content: '';
            position: absolute;
            width: 1.06 * $default-item-size;
            height: 1.06 * $default-item-size;
            transform-origin: 48% center;
            border-radius: 50%;

            @media (max-width: $large-width-breakpoint) {
              width: 1.06 * $large-item-size;
              height: 1.06 * $large-item-size;
            }
            @media (max-width: $extra-high-width-breakpoint) {
              width: 1.06 * $extra-high-item-size;
              height: 1.06 * $extra-high-item-size;
            }
            @media (max-width: $high-width-breakpoint) {
              width: 1.06 * $high-item-size;
              height: 1.06 * $high-item-size;
            }
            @media (max-width: $medium-plus-width-breakpoint) {
              width: 1.06 * $medium-plus-item-size;
              height: 1.06 * $medium-plus-item-size;
            }
            @media (max-width: $medium-width-breakpoint) {
              width: 1.06 * $medium-item-size;
              height: 1.06 * $medium-item-size;
            }
            @media (max-width: $medium-less-width-breakpoint) {
              width: 1.06 * $medium-less-item-size;
              height: 1.06 * $medium-less-item-size;
            }
            @media (max-width: $small-width-breakpoint) {
              width: 1.06 * $small-item-size;
              height: 1.06 * $small-item-size;
            }
          }

          position: relative;
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          position: absolute;
          border-radius: 50%;
        }

        &-1 {
          &::before {
            animation: background-blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both 0s infinite;
            background: $item-color-1;
            transform: translate(2%, 0) rotate(-90deg);

            @media (max-width: $high-width-breakpoint) {
              animation: background-blink-2 (12 * $blink-duration * $blink-delay-percentage) linear both 0s infinite;
            }
          }

          top: 0;
        }
        &-2 {
          &::before {
            animation: background-blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both (5 * $blink-duration * $blink-delay-percentage) infinite;
            background: $item-color-2;
            transform: translate(2%, 0) rotate(30deg);

            @media (max-width: $high-width-breakpoint) {
              animation: background-blink-2 (12 * $blink-duration * $blink-delay-percentage) linear both (4 * $blink-duration * $blink-delay-percentage) infinite;
            }
          }

          right: 0;
          bottom: 0;
        }
        &-3 {
          &::before {
            animation: background-blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both (10 * $blink-duration * $blink-delay-percentage) infinite;
            background: $item-color-3;
            transform: translate(2%, 0) rotate(150deg);

            @media (max-width: $high-width-breakpoint) {
              animation: background-blink-2 (12 * $blink-duration * $blink-delay-percentage) linear both (8 * $blink-duration * $blink-delay-percentage) infinite;
            }
          }

          left: 0;
          bottom: 0;
        }

        &__image {
          &-1,
          &-2,
          &-3 {
            width: $default-item-size;
            height: $default-item-size;
            object-fit: cover;
            border-radius: 50%;
            z-index: 1;

            @media (max-width: $large-width-breakpoint) {
              width: $large-item-size;
              height: $large-item-size;
            }
            @media (max-width: $extra-high-width-breakpoint) {
              width: $extra-high-item-size;
              height: $extra-high-item-size;
            }
            @media (max-width: $high-width-breakpoint) {
              width: $high-item-size;
              height: $high-item-size;
            }
            @media (max-width: $medium-plus-width-breakpoint) {
              width: $medium-plus-item-size;
              height: $medium-plus-item-size;
            }
            @media (max-width: $medium-width-breakpoint) {
              width: $medium-item-size;
              height: $medium-item-size;
            }
            @media (max-width: $medium-less-width-breakpoint) {
              width: $medium-less-item-size;
              height: $medium-less-item-size;
            }
            @media (max-width: $small-width-breakpoint) {
              width: $small-item-size;
              height: $small-item-size;
            }
          }

          // &-1 {
          //   border: 4px solid $item-color-1;
          // }
          // &-2 {
          //   border: 4px solid $item-color-2;
          // }
          // &-3 {
          //   border: 4px solid $item-color-3;
          // }
        }

        &__text {
          position: absolute;
          text-align: center;
          font-weight: 700;
          text-transform: uppercase;
          font-size: 0.75em;
          top: ($default-item-size + 0.5em) / 0.75;
          color: $default-text-color;

          @media (max-width: $large-width-breakpoint) {
            top: ($large-item-size + 0.5em) / 0.75;
          }
          @media (max-width: $extra-high-width-breakpoint) {
            top: ($extra-high-item-size + 0.6em) / 0.75;
          }
          @media (max-width: $high-width-breakpoint) {
            top: ($high-item-size + 0.6em) / 0.75;
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            font-size: 0.72em;
            top: ($medium-plus-item-size + 0.6em) / 0.72;
          }
          @media (max-width: $medium-width-breakpoint) {
            top: ($medium-item-size + 0.6em) / 0.72;
          }
          @media (max-width: $medium-less-width-breakpoint) {
            font-size: 0.6em;
            top: ($medium-less-item-size + 0.5em) / 0.6;
          }
          @media (max-width: $small-width-breakpoint) {
            top: ($small-item-size + 0.3em) / 0.6;
          }
        }
      }

      &__flow-circle {
        display: block;
        width: $flow-circle-width;
        height: 1.2 * $flow-circle-width;
        border-radius: 50%;
        position: absolute;
        transform: translate(-10.6em, 3.333em);
        background: white;

        @media (max-width: $large-width-breakpoint) {
          transform: translate(-13em, 3.333em);
        }
        @media (max-width: $extra-high-width-breakpoint) {
          transform: translate(-14.5em, 3.333em);
        }
        @media (max-width: $high-width-breakpoint) {
          transform: translate(-16em, 3.333em);
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          transform: translate(-17.3em, 3.333em);
        }
        @media (max-width: $medium-width-breakpoint) {
          transform: translate(-19.2em, 3.333em);
        }
        @media (max-width: $medium-less-width-breakpoint) {
          height: 50em;
          transform: translate(-23.2em, 3.333em);
        }
        @media (max-width: $small-width-breakpoint) {
          height: 13.5em;
          width: 18.5em;
          border-radius: 0 7.25em 7.25em 0;
          transform: translate(0em, 0.7em);
        }
        @media (max-width: $very-small-width-breakpoint) {
          width: 25em;
          transform: translate(0em, 0.7em);
        }
        @media (max-width: $tiny-width-breakpoint) {
          width: 100vw;
        }
      }

      &__flow {
        &-1,
        &-2,
        &-3,
        &-4,
        &-5,
        &-6,
        &-7,
        &-8,
        &-9,
        &-10,
        &-11,
        &-12 {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          position: absolute;
          font-size: $default-arrow-size;
          transform-origin: center;

          @media (max-width: $large-width-breakpoint) {
            font-size: $large-arrow-size;
          }
          @media (max-width: $extra-high-width-breakpoint) {
            font-size: $extra-high-arrow-size;
          }
          @media (max-width: $high-width-breakpoint) {
            font-size: $high-arrow-size;
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            font-size: $medium-plus-arrow-size;
          }
          @media (max-width: $medium-width-breakpoint) {
            font-size: $medium-arrow-size;
          }
          @media (max-width: $medium-less-width-breakpoint) {
            font-size: $medium-less-arrow-size;
          }
          @media (max-width: $small-width-breakpoint) {
            font-size: $small-arrow-size;
          }
        }

        &-1 {
          animation: blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both ($blink-duration*$blink-delay-percentage) infinite;
          color: mix($item-color-2, $item-color-1, 100*33/120);
          transform: translateY($default-arrow-dy) rotate(33deg) translateY($default-arrow-inner-radius);

          @media (max-width: $large-width-breakpoint) {
            transform: translateY($large-arrow-dy) rotate(33deg) translateY($large-arrow-inner-radius);
          }
          @media (max-width: $extra-high-width-breakpoint) {
            transform: translateY($extra-high-arrow-dy) rotate(33deg) translateY($extra-high-arrow-inner-radius);
          }
          @media (max-width: $high-width-breakpoint) {
            animation: blink-2 (12 * $blink-duration * $blink-delay-percentage) linear both ($blink-duration*$blink-delay-percentage) infinite;
            color: mix($item-color-2, $item-color-1, 100*40/120);
            transform: translateY($high-arrow-dy) rotate(40deg) translateY($high-arrow-inner-radius);
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            transform: translateY($medium-plus-arrow-dy) rotate(40deg) translateY($medium-plus-arrow-inner-radius);
          }
          @media (max-width: $medium-width-breakpoint) {
            transform: translateY($medium-arrow-dy) rotate(40deg) translateY($medium-arrow-inner-radius);
          }
          @media (max-width: $medium-less-width-breakpoint) {
            transform: translateY($medium-less-arrow-dy) rotate(40deg) translateY($medium-less-arrow-inner-radius);
          }
          @media (max-width: $small-width-breakpoint) {
            transform: translateY($small-arrow-dy) rotate(40deg) translateY($small-arrow-inner-radius);
          }
        }
        &-2 {
          animation: blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both (2 * $blink-duration*$blink-delay-percentage) infinite;
          color: mix($item-color-2, $item-color-1, 100*51/120);
          transform: translateY($default-arrow-dy) rotate(51deg) translateY($default-arrow-inner-radius);

          @media (max-width: $large-width-breakpoint) {
            transform: translateY($large-arrow-dy) rotate(51deg) translateY($large-arrow-inner-radius);
          }
          @media (max-width: $extra-high-width-breakpoint) {
            transform: translateY($extra-high-arrow-dy) rotate(51deg) translateY($extra-high-arrow-inner-radius);
          }
          @media (max-width: $high-width-breakpoint) {
            animation: blink-2 (12 * $blink-duration * $blink-delay-percentage) linear both (2 * $blink-duration*$blink-delay-percentage) infinite;
            color: mix($item-color-2, $item-color-1, 100*60/120);
            transform: translateY($high-arrow-dy) rotate(60deg) translateY($high-arrow-inner-radius);
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            transform: translateY($medium-plus-arrow-dy) rotate(60deg) translateY($medium-plus-arrow-inner-radius);
          }
          @media (max-width: $medium-width-breakpoint) {
            transform: translateY($medium-arrow-dy) rotate(60deg) translateY($medium-arrow-inner-radius);
          }
          @media (max-width: $medium-less-width-breakpoint) {
            transform: translateY($medium-less-arrow-dy) rotate(60deg) translateY($medium-less-arrow-inner-radius);
          }
          @media (max-width: $small-width-breakpoint) {
            transform: translateY($small-arrow-dy) rotate(60deg) translateY($small-arrow-inner-radius);
          }
        }
        &-3 {
          animation: blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both (3 * ($blink-duration*$blink-delay-percentage)) infinite;
          color: mix($item-color-2, $item-color-1, 100*69/120);
          transform: translateY($default-arrow-dy) rotate(69deg) translateY($default-arrow-inner-radius);

          @media (max-width: $large-width-breakpoint) {
            transform: translateY($large-arrow-dy) rotate(69deg) translateY($large-arrow-inner-radius);
          }
          @media (max-width: $extra-high-width-breakpoint) {
            transform: translateY($extra-high-arrow-dy) rotate(69deg) translateY($extra-high-arrow-inner-radius);
          }
          @media (max-width: $high-width-breakpoint) {
            animation: blink-2 (12 * $blink-duration * $blink-delay-percentage) linear both (3 * $blink-duration*$blink-delay-percentage) infinite;
            color: mix($item-color-2, $item-color-1, 100*80/120);
            transform: translateY($high-arrow-dy) rotate(80deg) translateY($high-arrow-inner-radius);
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            transform: translateY($medium-plus-arrow-dy) rotate(80deg) translateY($medium-plus-arrow-inner-radius);
          }
          @media (max-width: $medium-width-breakpoint) {
            transform: translateY($medium-arrow-dy) rotate(80deg) translateY($medium-arrow-inner-radius);
          }
          @media (max-width: $medium-less-width-breakpoint) {
            transform: translateY($medium-less-arrow-dy) rotate(80deg) translateY($medium-less-arrow-inner-radius);
          }
          @media (max-width: $small-width-breakpoint) {
            transform: translateY($small-arrow-dy) rotate(80deg) translateY($small-arrow-inner-radius);
          }
        }
        &-4 {
          animation: blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both (4 * ($blink-duration*$blink-delay-percentage)) infinite;
          color: mix($item-color-2, $item-color-1, 100*87/120);
          transform: translateY($default-arrow-dy) rotate(87deg) translateY($default-arrow-inner-radius);

          @media (max-width: $large-width-breakpoint) {
            transform: translateY($large-arrow-dy) rotate(87deg) translateY($large-arrow-inner-radius);
          }
          @media (max-width: $extra-high-width-breakpoint) {
            transform: translateY($extra-high-arrow-dy) rotate(87deg) translateY($extra-high-arrow-inner-radius);
          }
          @media (max-width: $high-width-breakpoint) {
            display: none;
            // transform: translateY($high-arrow-dy) rotate(87deg) translateY($high-arrow-inner-radius);
          }
        }

        &-5 {
          animation: blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both (6 * ($blink-duration*$blink-delay-percentage)) infinite;
          color: mix($item-color-3, $item-color-2, 100*33/120);
          transform: translateY($default-arrow-dy) rotate(153deg) translateY($default-arrow-inner-radius);

          @media (max-width: $large-width-breakpoint) {
            transform: translateY($large-arrow-dy) rotate(153deg) translateY($large-arrow-inner-radius);
          }
          @media (max-width: $extra-high-width-breakpoint) {
            transform: translateY($extra-high-arrow-dy) rotate(153deg) translateY($extra-high-arrow-inner-radius);
          }
          @media (max-width: $high-width-breakpoint) {
            animation: blink-2 (12 * $blink-duration * $blink-delay-percentage) linear both (5 * $blink-duration*$blink-delay-percentage) infinite;
            color: mix($item-color-3, $item-color-2, 100*40/120);
            transform: translateY($high-arrow-dy) rotate(160deg) translateY($high-arrow-inner-radius);
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            transform: translateY($medium-plus-arrow-dy) rotate(160deg) translateY($medium-plus-arrow-inner-radius);
          }
          @media (max-width: $medium-width-breakpoint) {
            transform: translateY($medium-arrow-dy) rotate(160deg) translateY($medium-arrow-inner-radius);
          }
          @media (max-width: $medium-less-width-breakpoint) {
            transform: translateY($medium-less-arrow-dy) rotate(160deg) translateY($medium-less-arrow-inner-radius);
          }
          @media (max-width: $small-width-breakpoint) {
            transform: translateY($small-arrow-dy) rotate(160deg) translateY($small-arrow-inner-radius);
          }
        }
        &-6 {
          animation: blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both (7 * ($blink-duration*$blink-delay-percentage)) infinite;
          color: mix($item-color-3, $item-color-2, 100*51/120);
          transform: translateY($default-arrow-dy) rotate(171deg) translateY($default-arrow-inner-radius);

          @media (max-width: $large-width-breakpoint) {
            transform: translateY($large-arrow-dy) rotate(171deg) translateY($large-arrow-inner-radius);
          }
          @media (max-width: $extra-high-width-breakpoint) {
            transform: translateY($extra-high-arrow-dy) rotate(171deg) translateY($extra-high-arrow-inner-radius);
          }
          @media (max-width: $high-width-breakpoint) {
            animation: blink-2 (12 * $blink-duration * $blink-delay-percentage) linear both (6 * $blink-duration*$blink-delay-percentage) infinite;
            color: mix($item-color-3, $item-color-2, 100*60/120);
            transform: translateY($high-arrow-dy) rotate(180deg) translateY($high-arrow-inner-radius);
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            transform: translateY($medium-plus-arrow-dy) rotate(180deg) translateY($medium-plus-arrow-inner-radius);
          }
          @media (max-width: $medium-width-breakpoint) {
            transform: translateY($medium-arrow-dy) rotate(180deg) translateY($medium-arrow-inner-radius);
          }
          @media (max-width: $medium-less-width-breakpoint) {
            transform: translateY($medium-less-arrow-dy) rotate(180deg) translateY($medium-less-arrow-inner-radius);
          }
          @media (max-width: $small-width-breakpoint) {
            transform: translateY($small-arrow-dy) rotate(180deg) translateY($small-arrow-inner-radius);
          }
        }
        &-7 {
          animation: blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both (8 * ($blink-duration*$blink-delay-percentage)) infinite;
          color: mix($item-color-3, $item-color-2, 100*69/120);
          transform: translateY($default-arrow-dy) rotate(189deg) translateY($default-arrow-inner-radius);

          @media (max-width: $large-width-breakpoint) {
            transform: translateY($large-arrow-dy) rotate(189deg) translateY($large-arrow-inner-radius);
          }
          @media (max-width: $extra-high-width-breakpoint) {
            transform: translateY($extra-high-arrow-dy) rotate(189deg) translateY($extra-high-arrow-inner-radius);
          }
          @media (max-width: $high-width-breakpoint) {
            animation: blink-2 (12 * $blink-duration * $blink-delay-percentage) linear both (7 * $blink-duration*$blink-delay-percentage) infinite;
            color: mix($item-color-3, $item-color-2, 100*80/120);
            transform: translateY($high-arrow-dy) rotate(200deg) translateY($high-arrow-inner-radius);
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            transform: translateY($medium-plus-arrow-dy) rotate(200deg) translateY($medium-plus-arrow-inner-radius);
          }
          @media (max-width: $medium-width-breakpoint) {
            transform: translateY($medium-arrow-dy) rotate(200deg) translateY($medium-arrow-inner-radius);
          }
          @media (max-width: $medium-less-width-breakpoint) {
            transform: translateY($medium-less-arrow-dy) rotate(200deg) translateY($medium-less-arrow-inner-radius);
          }
          @media (max-width: $small-width-breakpoint) {
            transform: translateY($small-arrow-dy) rotate(200deg) translateY($small-arrow-inner-radius);
          }
        }
        &-8 {
          animation: blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both (9 * ($blink-duration*$blink-delay-percentage)) infinite;
          color: mix($item-color-3, $item-color-2, 100*97/120);
          transform: translateY($default-arrow-dy) rotate(207deg) translateY($default-arrow-inner-radius);

          @media (max-width: $large-width-breakpoint) {
            transform: translateY($large-arrow-dy) rotate(207deg) translateY($large-arrow-inner-radius);
          }
          @media (max-width: $extra-high-width-breakpoint) {
            transform: translateY($extra-high-arrow-dy) rotate(207deg) translateY($extra-high-arrow-inner-radius);
          }
          @media (max-width: $high-width-breakpoint) {
            display: none;
            // transform: translateY($high-arrow-dy) rotate(207deg) translateY($high-arrow-inner-radius);
          }
        }

        &-9 {
          animation: blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both (11 * ($blink-duration*$blink-delay-percentage)) infinite;
          color: mix($item-color-1, $item-color-3, 100*33/120);
          transform: translateY($default-arrow-dy) rotate(273deg) translateY($default-arrow-inner-radius);

          @media (max-width: $large-width-breakpoint) {
            transform: translateY($large-arrow-dy) rotate(273deg) translateY($large-arrow-inner-radius);
          }
          @media (max-width: $extra-high-width-breakpoint) {
            transform: translateY($extra-high-arrow-dy) rotate(273deg) translateY($extra-high-arrow-inner-radius);
          }
          @media (max-width: $high-width-breakpoint) {
            animation: blink-2 (12 * $blink-duration * $blink-delay-percentage) linear both (9 * $blink-duration*$blink-delay-percentage) infinite;
            color: mix($item-color-1, $item-color-3, 100*40/120);
            transform: translateY($high-arrow-dy) rotate(280deg) translateY($high-arrow-inner-radius);
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            transform: translateY($medium-plus-arrow-dy) rotate(280deg) translateY($medium-plus-arrow-inner-radius);
          }
          @media (max-width: $medium-width-breakpoint) {
            transform: translateY($medium-arrow-dy) rotate(280deg) translateY($medium-arrow-inner-radius);
          }
          @media (max-width: $medium-less-width-breakpoint) {
            transform: translateY($medium-less-arrow-dy) rotate(280deg) translateY($medium-less-arrow-inner-radius);
          }
          @media (max-width: $small-width-breakpoint) {
            transform: translateY($small-arrow-dy) rotate(280deg) translateY($small-arrow-inner-radius);
          }
        }
        &-10 {
          animation: blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both (12 * ($blink-duration*$blink-delay-percentage)) infinite;
          color: mix($item-color-1, $item-color-3, 100*51/120);
          transform: translateY($default-arrow-dy) rotate(291deg) translateY($default-arrow-inner-radius);

          @media (max-width: $large-width-breakpoint) {
            transform: translateY($large-arrow-dy) rotate(291deg) translateY($large-arrow-inner-radius);
          }
          @media (max-width: $extra-high-width-breakpoint) {
            transform: translateY($extra-high-arrow-dy) rotate(291deg) translateY($extra-high-arrow-inner-radius);
          }
          @media (max-width: $high-width-breakpoint) {
            animation: blink-2 (12 * $blink-duration * $blink-delay-percentage) linear both (10 * $blink-duration*$blink-delay-percentage) infinite;
            color: mix($item-color-1, $item-color-3, 100*60/120);
            transform: translateY($high-arrow-dy) rotate(300deg) translateY($high-arrow-inner-radius);
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            transform: translateY($medium-plus-arrow-dy) rotate(300deg) translateY($medium-plus-arrow-inner-radius);
          }
          @media (max-width: $medium-width-breakpoint) {
            transform: translateY($medium-arrow-dy) rotate(300deg) translateY($medium-arrow-inner-radius);
          }
          @media (max-width: $medium-less-width-breakpoint) {
            transform: translateY($medium-less-arrow-dy) rotate(300deg) translateY($medium-less-arrow-inner-radius);
          }
          @media (max-width: $small-width-breakpoint) {
            transform: translateY($small-arrow-dy) rotate(300deg) translateY($small-arrow-inner-radius);
          }
        }
        &-11 {
          animation: blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both (13 * ($blink-duration*$blink-delay-percentage)) infinite;
          color: mix($item-color-1, $item-color-3, 100*67/120);
          transform: translateY($default-arrow-dy) rotate(309deg) translateY($default-arrow-inner-radius);

          @media (max-width: $large-width-breakpoint) {
            transform: translateY($large-arrow-dy) rotate(309deg) translateY($large-arrow-inner-radius);
          }
          @media (max-width: $extra-high-width-breakpoint) {
            transform: translateY($extra-high-arrow-dy) rotate(309deg) translateY($extra-high-arrow-inner-radius);
          }
          @media (max-width: $high-width-breakpoint) {
            animation: blink-2 (12 * $blink-duration * $blink-delay-percentage) linear both (11 * $blink-duration*$blink-delay-percentage) infinite;
            color: mix($item-color-1, $item-color-3, 100*80/120);
            transform: translateY($high-arrow-dy) rotate(320deg) translateY($high-arrow-inner-radius);
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            transform: translateY($medium-plus-arrow-dy) rotate(320deg) translateY($medium-plus-arrow-inner-radius);
          }
          @media (max-width: $medium-width-breakpoint) {
            transform: translateY($medium-arrow-dy) rotate(320deg) translateY($medium-arrow-inner-radius);
          }
          @media (max-width: $medium-less-width-breakpoint) {
            transform: translateY($medium-less-arrow-dy) rotate(320deg) translateY($medium-less-arrow-inner-radius);
          }
          @media (max-width: $small-width-breakpoint) {
            transform: translateY($small-arrow-dy) rotate(320deg) translateY($small-arrow-inner-radius);
          }
        }
        &-12 {
          animation: blink-1 (15 * $blink-duration * $blink-delay-percentage) linear both (14 * ($blink-duration*$blink-delay-percentage)) infinite;
          color: mix($item-color-1, $item-color-3, 100*97/120);
          transform: translateY($default-arrow-dy) rotate(327deg) translateY($default-arrow-inner-radius);

          @media (max-width: $large-width-breakpoint) {
            transform: translateY($large-arrow-dy) rotate(327deg) translateY($large-arrow-inner-radius);
          }
          @media (max-width: $extra-high-width-breakpoint) {
            transform: translateY($extra-high-arrow-dy) rotate(327deg) translateY($extra-high-arrow-inner-radius);
          }
          @media (max-width: $high-width-breakpoint) {
            display: none;
            // transform: translateY($high-arrow-dy) rotate(327deg) translateY($high-arrow-inner-radius);
          }
        }
      }

      &__main-text {
        position: absolute;
        text-align: center;
        font-size: 1.4em;
        transform: translateY(1em * $default-center-dy / 1.4em);
        font-weight: 900;
        color: #b7b7b7;
        max-width: 8em;
        text-transform: uppercase;
        text-shadow: 1px 1px 0px #868585;

        @media (max-width: $large-width-breakpoint) {
          transform: translateY(1em * $large-center-dy / 1.4em);
        }
        @media (max-width: $extra-high-width-breakpoint) {
          transform: translateY(1em * $extra-high-center-dy / 1.4em);
        }
        @media (max-width: $high-width-breakpoint) {
          font-size: 1.3em;
          transform: translateY(1em * $high-center-dy / 1.3em);
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          font-size: 1.2em;
          transform: translateY(1em * $medium-plus-center-dy / 1.2em);
        }
        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.1em;
          transform: translateY(1em * $medium-center-dy / 1.1em);
        }
        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 0.85em;
          transform: translateY(1em * $medium-less-center-dy / 0.85em);
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 0.75em;
          transform: translateY(1em * $small-center-dy / 0.75em);
        }
      }
    }

    &__article {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
      justify-content: flex-start;

      &__title-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 1.8em;

        @media (max-width: $large-width-breakpoint) {
          margin: 0 0 1.5em;
        }

        @media (max-width: $extra-high-width-breakpoint) {
          margin: 0 0 1.2em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0 0 1em;
        }
      }

      &__title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 2.15em;
        color: white;
        text-shadow: 3px 3px 0px $default-text-color;

        @media (max-width: $large-width-breakpoint) {
          font-size: 2.1em;
        }
        @media (max-width: $extra-high-width-breakpoint) {
          font-size: 2em;
        }
        @media (max-width: $high-width-breakpoint) {
          font-size: 1.8em;
          font-weight: 700;
          text-align: right;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          font-size: 1.6em;
          text-align: right;
        }
        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.56em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 1.45em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 1.4em;
          margin: 0 0 0 10.5em;
        }
        @media (max-width: 520px) {
          max-width: 8em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.2em;
          max-width: none;
          margin: 0 0 11.6em;
          text-align: center;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 1.3em;
          margin: 0 0 10.8em;
        }
      }

      &__text {
        font-size: 1.3em;
        font-weight: 400;
        text-transform: uppercase;
        color: white;
        text-align: justify;
        line-height: 1.3em;
        margin: 0 0 0 1em;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.25em;
          max-width: 30em;
          margin: 0;
        }
        @media (max-width: $extra-high-width-breakpoint) {
          font-size: 1.2em;
        }
        @media (max-width: $high-width-breakpoint) {
          font-size: 1.15em;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          font-size: 1em;
          margin: 0;
        }
        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.95em;
          margin: 0 0 0 0.8em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          font-weight: 500;
          font-size: 0.85em;
          margin: 0;
          max-width: 40em;
        }
        @media (max-width: $small-width-breakpoint) {
          max-width: none;
          font-size: 0.8em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.85em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.8em;
        }

        &::before {
          content: '';
          float: left;
          height: 111%;
          width: 2em;
          shape-outside: ellipse(23.84615em 28.61538em at -22em 10.2em);

          @media (max-width: $extra-high-width-breakpoint) {
            shape-outside: ellipse(23.84615em 35em at -22em 10.2em);
          }
          @media (max-width: $medium-less-width-breakpoint) {
            width: 3.7em;
            shape-outside: ellipse(23.84615em 25em at -20.2em 9em);
          }
          @media (max-width: 677px) {
            height: 121%;
            shape-outside: ellipse(23.84615em 25em at -20.2em 11.5em);
          }
          @media (max-width: $small-width-breakpoint) {
            height: 11.5em;
            width: 21.2em;
            shape-outside: circle(9em at 12.1em 3em);
          }
          @media (max-width: 520px) {
            height: 10em;
            shape-outside: circle(9em at 12.1em 0.9em);
          }
          @media (max-width: $very-small-width-breakpoint) {
            display: none;
          }
        }
      }
    }

    &__buttons-wrapper {
      display: flex;
      flex-flow: row wrap;
      align-content: center;
      justify-content: flex-end;
      max-width: 30em;
      margin: 1em 0 0;

      @media (max-width: $large-width-breakpoint) {
        margin: 0.8em 0 0;
      }
      @media (max-width: $extra-high-width-breakpoint) {
        margin: 0.6em 0 0;
      }
      @media (max-width: $medium-width-breakpoint) {
        margin: 1.2em 0 0;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        margin: 1.5em 0 0;
      }
      @media (max-width: 721px) {
        margin: 3em 0 0;
      }
      @media (max-width: 656px) {
        margin: 3.6em 0 0;
      }
      @media (max-width: $small-width-breakpoint) {
        margin: 0;
      }
    }

    &__button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.5em;
      padding: 0.4em 1.2em;
      margin: 0;
      font-weight: 500;
      border-radius: 1em;
      background: white;
      border: 2px solid white;
      color: #ff4215;
      min-width: 35px;
      min-height: 35px;
      transition: $default-transition;

      &:not(:first-of-type) {
        margin: 0.5em 0 0 0.5em;
      }

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 1.32em;
      }
      @media (max-width: $high-width-breakpoint) {
        font-size: 1.25em;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 1.2em;
      }
      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.15em;
        border-radius: 0.4em;
        text-align: center;

        &:not(:first-of-type) {
          margin: 0 0 0 0.5em;
        }
      }
      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.95em;
        border-radius: 0.2em;
        padding: 0.4em 0.8em;
      }
      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em 1.2em;
        border-radius: 1em;
        margin-top: 0.8em;
        font-size: 1em;

        &:not(:first-of-type) {
          margin: 0.8em 0 0 0.5em;
        }
      }
      @media (max-width: $tiny-width-breakpoint) {
        align-self: stretch;
      }

      &:hover {
        color: white;
        border-width: 2px;
        background: transparent;
        // transform: scale(1.05, 1.05);
      }

      &__icon {
        font-size: 1.2em;
        margin: 0 0.4em 0 0;

        @media (max-width: $tiny-width-breakpoint) {
          position: absolute;
          left: 1em;
        }
      }
    }
  }

  &__services-section {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    background: $light-gray-color;
    height: 45em;

    @media (max-width: $large-width-breakpoint) {
      height: 43.5em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      height: 43em;
    }
    @media (max-width: $high-width-breakpoint) {
      height: 34.5em;
      // height: 39em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      height: 33em;
      // height: 38.5em;
    }
    @media (max-width: $medium-width-breakpoint) {
      height: 32em;
      // height: 37.5em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      height: 31em;
      // height: 36.5em;
    }
    @media (max-width: 780px) {
      height: 36em;
    }
    @media (max-width: $small-width-breakpoint) {
      height: 41.7em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      height: 43em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      height: 44.5em;
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;
      max-width: $content-max-width;
      padding: 2.5em 0.8em 2em;
      align-self: stretch;

      @media (max-width: $extra-high-width-breakpoint) {
        padding: 3em 0 2em 1.8em;
      }

      @media (max-width: $high-width-breakpoint) {
        padding: 2.5em 0 1.5em 1.8em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        padding: 2.5em 0 1.5em 1.2em;
      }

      @media (max-width: $medium-width-breakpoint) {
        flex-flow: column nowrap;
        justify-content: flex-start;
        padding: 2.5em 0 1.2em 1.5em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        padding: 2.5em 0 1em 1em;
      }

      @media (max-width: $small-width-breakpoint) {
        padding: 1.5em 0.8em 1em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        padding: 0.8em 0.8em 1em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        padding: 0.8em 0.4em 0.5em;
      }
    }

    &__title {
      font-size: 2.15em;
      z-index: 2;
      text-transform: uppercase;
      font-weight: 700;
      text-shadow: 2px 1px $light-gray-color;
      margin: 0 0 0.5em;
      color: $default-text-color;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2.1em;
      }
      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 2em;
        margin: 0 0 0.5em;
      }
      @media (max-width: $high-width-breakpoint) {
        font-size: 1.8em;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 1.6em;
      }
      @media (max-width: $medium-width-breakpoint) {
        max-width: 14em;
        font-size: 1.56em;
        margin: 0 0 0.4em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.45em;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 1.4em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.2em;
        max-width: none;
        text-align: justify;
        align-self: center;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1.2em;
      }
    }

    &__service-panel {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      height: 19em;
      // height: 27.5em;
      font-size: 0.85em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 0.8em;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 0.8em;
      }
      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.7em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.65em;
      }
      @media (max-width: 780px) {
        height: 27.5em;
      }
      @media (max-width: $small-width-breakpoint) {
        height: 35.8em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.45em;
        margin: 32.5em 0 0;
        align-self: stretch;
        align-items: stretch;
        height: 19em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        margin: 27.5em 0 0;
        font-size: 0.5em;
        height: 27.5em;
      }

      &__row {
        &,
        &--1,
        &--2,
        &--3,
        &--4 {
          position: relative;
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-start;
          justify-content: flex-start;
        }

        &--1 {
          @media (max-width: $small-width-breakpoint) {
            left: 4.9em;
          }
          @media (max-width: $very-small-width-breakpoint) {
            left: 0;
            justify-content: space-between;
            margin: 0 4.9em;
          }
          @media (max-width: $tiny-width-breakpoint) {
            justify-content: space-between;
            margin: 0;
          }
        }
        &--2 {
          left: 4.9em;
          bottom: 2.1em;

          @media (max-width: $small-width-breakpoint) {
            left: 0;
          }
          @media (max-width: $very-small-width-breakpoint) {
            left: auto;
            justify-content: space-between;
          }
          @media (max-width: $tiny-width-breakpoint) {
            margin: 0 4.9em;
          }
        }
        &--3 {
          bottom: 4.2em;

          @media (max-width: $small-width-breakpoint) {
            left: 4.9em;
          }
          @media (max-width: $tiny-width-breakpoint) {
            left: 0;
            justify-content: space-between;
          }
        }
        &--4 {
          bottom: 6.3em;
        }
      }
    }

    &__service-button,
    &__fake-service-button {
      font-size: 1em;
      min-width: 35px;
      min-height: 35px;
      width: 9.8em;
      height: 10.5em;
      margin: 0;
      outline: none;
      -webkit-tap-highlight-color:  transparent;
      user-select: none;
    }

    &__service-button {
      font-family: 'Montserrat', sans-serif;
      background: center / contain no-repeat url("../images/hexagon_border.svg");
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      border: none;
      color: $default-text-color;

      &:hover {
        background: center / contain no-repeat url("../images/hexagon_border_red.svg");
        color: #ff4b00;
      }

      &:disabled {
        background: center / contain no-repeat url("../images/hexagon_border_white.svg");
        color: white;
      }
    }

    &__image-background {
      background: #ff4b00;
      transform: scale(1.02);

      &,
      &--inner {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 38em;
        clip-path: polygon(100% 0,40.5% 0,0 19.5%,0 74.5%, 52% 100%, 100% 100%);
        transform-origin: center;

        @media (max-width: $large-width-breakpoint) {
          width: 34.6em;
        }
        @media (max-width: $extra-high-width-breakpoint) {
          width: 32.9em;
        }
        @media (max-width: $high-width-breakpoint) {
          $image-frame-width: 27em;
          $image-frame-height: 32em;
          $vertical-frame-offset: 8%;
          height: $image-frame-height;
          width: $image-frame-width;
          clip-path: polygon(100% 0,
                             (1.732051*$vertical-frame-offset*$image-frame-height/$image-frame-width) 0,
                             0 $vertical-frame-offset,
                             0 ((2 * (100% - $vertical-frame-offset) / 3) + $vertical-frame-offset),
                             (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width) 100%,
                             100% (100% - (0.57735 * $image-frame-width * (100% - (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width)) / $image-frame-height)));
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          $image-frame-width: 25em;
          $image-frame-height: 30em;
          $vertical-frame-offset: 8%;
          height: $image-frame-height;
          width: $image-frame-width;
          clip-path: polygon(100% 0,
                             (1.732051*$vertical-frame-offset*$image-frame-height/$image-frame-width) 0,
                             0 $vertical-frame-offset,
                             0 ((2 * (100% - $vertical-frame-offset) / 3) + $vertical-frame-offset),
                             (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width) 100%,
                             100% (100% - (0.57735 * $image-frame-width * (100% - (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width)) / $image-frame-height)));
        }
        @media (max-width: $medium-width-breakpoint) {
          $image-frame-width: 25em;
          $image-frame-height: 29em;
          $vertical-frame-offset: 8%;
          height: $image-frame-height;
          width: $image-frame-width;
          clip-path: polygon(100% 0,
                             (1.732051*$vertical-frame-offset*$image-frame-height/$image-frame-width) 0,
                             0 $vertical-frame-offset,
                             0 ((2 * (100% - $vertical-frame-offset) / 3) + $vertical-frame-offset),
                             (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width) 100%,
                             100% (100% - (0.57735 * $image-frame-width * (100% - (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width)) / $image-frame-height)));
        }
        @media (max-width: $medium-less-width-breakpoint) {
          $image-frame-width: 23em;
          $image-frame-height: 29em;
          $vertical-frame-offset: 8%;
          height: $image-frame-height;
          width: $image-frame-width;
          clip-path: polygon(100% 0,
                             (1.732051*$vertical-frame-offset*$image-frame-height/$image-frame-width) 0,
                             0 $vertical-frame-offset,
                             0 ((2 * (100% - $vertical-frame-offset) / 3) + $vertical-frame-offset),
                             (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width) 100%,
                             100% (100% - (0.57735 * $image-frame-width * (100% - (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width)) / $image-frame-height)));
        }
        @media (max-width: 780px) {
          $image-frame-width: 20em;
          top: 4em;
          right: auto;
          align-self: center;
          width: $image-frame-width;
          height: 1.1547 * $image-frame-width;
          margin-left: 18.5em;
          clip-path: polygon(50% 0,
                             0 25%,
                             0 75%,
                             50% 100%,
                             100% 75%,
                             100% 25%);
        }
        @media (max-width: 700px) {
          $image-frame-width: 18em;
          width: $image-frame-width;
          height: 1.1547 * $image-frame-width;
        }
        @media (max-width: 665px) {
          $image-frame-width: 16.5em;
          width: $image-frame-width;
          height: 1.1547 * $image-frame-width;
        }
        @media (max-width: $small-width-breakpoint) {
          $image-frame-width: 15em;
          top: 4em;
          right: auto;
          align-self: center;
          width: $image-frame-width;
          height: 1.1547 * $image-frame-width;
          margin-left: 11em;
          clip-path: polygon(50% 0,
                             0 25%,
                             0 75%,
                             50% 100%,
                             100% 75%,
                             100% 25%);
        }
        // @media (max-width: $very-small-width-breakpoint) {
        //   $image-frame-width: 240px;
        //   top: 2.9em;
        //   right: auto;
        //   align-self: center;
        //   width: $very-small-width-breakpoint;
        //   height: 1.1547 * $image-frame-width;
        //   margin-left: 0em;
        //   clip-path: polygon(50% 0,
        //                      (50% * ($very-small-width-breakpoint - $image-frame-width) / $very-small-width-breakpoint) 25%,
        //                      0 25%,
        //                      0 75%,
        //                      (50% * ($very-small-width-breakpoint - $image-frame-width) / $very-small-width-breakpoint) 75%,
        //                      50% 100%,
        //                      ((100% * $image-frame-width / $very-small-width-breakpoint) + (50% * ($very-small-width-breakpoint - $image-frame-width) / $very-small-width-breakpoint)) 75%,
        //                      100% 75%,
        //                      100% 25%,
        //                      ((100% * $image-frame-width / $very-small-width-breakpoint) + (50% * ($very-small-width-breakpoint - $image-frame-width) / $very-small-width-breakpoint)) 25%);
        // }
        @media (max-width: $very-small-width-breakpoint) {
          $image-frame-width: 15em;
          top: 2.9em;
          right: auto;
          align-self: center;
          width: $image-frame-width;
          height: 1.1547 * $image-frame-width;
          margin-left: 0em;
          clip-path: polygon(50% 0,
                             0 25%,
                             0 75%,
                             50% 100%,
                             100% 75%,
                             100% 25%);
        }
        @media (max-width: $tiny-width-breakpoint) {
          $image-frame-width: 14.5em;
          top: 4em;
          right: auto;
          align-self: center;
          width: $image-frame-width;
          height: 1.1547 * $image-frame-width;
          margin-left: 0em;
          clip-path: polygon(50% 0,
                             0 25%,
                             0 75%,
                             50% 100%,
                             100% 75%,
                             100% 25%);
        }
      }

      &--inner {
        background: $light-gray-color;
      }
    }

    &__image-frame {
      background: white;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 38em;
      clip-path: polygon(100% 0,40.5% 0,0 19.5%,0 74.5%, 52% 100%, 100% 100%);
      overflow: hidden;

      @media (max-width: $large-width-breakpoint) {
        width: 34.6em;
      }
      @media (max-width: $extra-high-width-breakpoint) {
        width: 32.9em;
      }
      @media (max-width: $high-width-breakpoint) {
        $image-frame-width: 27em;
        $image-frame-height: 32em;
        $vertical-frame-offset: 8%;
        height: $image-frame-height;
        width: $image-frame-width;
        clip-path: polygon(100% 0,
                           (1.732051*$vertical-frame-offset*$image-frame-height/$image-frame-width) 0,
                           0 $vertical-frame-offset,
                           0 ((2 * (100% - $vertical-frame-offset) / 3) + $vertical-frame-offset),
                           (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width) 100%,
                           100% (100% - (0.57735 * $image-frame-width * (100% - (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width)) / $image-frame-height)));
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        $image-frame-width: 25em;
        $image-frame-height: 30em;
        $vertical-frame-offset: 8%;
        height: $image-frame-height;
        width: $image-frame-width;
        clip-path: polygon(100% 0,
                           (1.732051*$vertical-frame-offset*$image-frame-height/$image-frame-width) 0,
                           0 $vertical-frame-offset,
                           0 ((2 * (100% - $vertical-frame-offset) / 3) + $vertical-frame-offset),
                           (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width) 100%,
                           100% (100% - (0.57735 * $image-frame-width * (100% - (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width)) / $image-frame-height)));
      }
      @media (max-width: $medium-width-breakpoint) {
        $image-frame-width: 25em;
        $image-frame-height: 29em;
        $vertical-frame-offset: 8%;
        height: $image-frame-height;
        width: $image-frame-width;
        clip-path: polygon(100% 0,
                           (1.732051*$vertical-frame-offset*$image-frame-height/$image-frame-width) 0,
                           0 $vertical-frame-offset,
                           0 ((2 * (100% - $vertical-frame-offset) / 3) + $vertical-frame-offset),
                           (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width) 100%,
                           100% (100% - (0.57735 * $image-frame-width * (100% - (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width)) / $image-frame-height)));
      }
      @media (max-width: $medium-less-width-breakpoint) {
        $image-frame-width: 23em;
        $image-frame-height: 29em;
        $vertical-frame-offset: 8%;
        height: $image-frame-height;
        width: $image-frame-width;
        clip-path: polygon(100% 0,
                           (1.732051*$vertical-frame-offset*$image-frame-height/$image-frame-width) 0,
                           0 $vertical-frame-offset,
                           0 ((2 * (100% - $vertical-frame-offset) / 3) + $vertical-frame-offset),
                           (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width) 100%,
                           100% (100% - (0.57735 * $image-frame-width * (100% - (0.866025 * $image-frame-height * (2 * (100% - $vertical-frame-offset) / 3) / $image-frame-width)) / $image-frame-height)));
      }
      @media (max-width: 780px) {
        $image-frame-width: 20em;
        top: 4em;
        right: auto;
        align-self: center;
        width: $image-frame-width;
        height: 1.1547 * $image-frame-width;
        margin-left: 18.5em;
        clip-path: polygon(50% 0,
                           0 25%,
                           0 75%,
                           50% 100%,
                           100% 75%,
                           100% 25%);
      }
      @media (max-width: 700px) {
        $image-frame-width: 18em;
        width: $image-frame-width;
        height: 1.1547 * $image-frame-width;
      }
      @media (max-width: 665px) {
        $image-frame-width: 16.5em;
        width: $image-frame-width;
        height: 1.1547 * $image-frame-width;
      }
      @media (max-width: $small-width-breakpoint) {
        $image-frame-width: 15em;
        top: 4em;
        right: auto;
        align-self: center;
        width: $image-frame-width;
        height: 1.1547 * $image-frame-width;
        margin-left: 11em;
        clip-path: polygon(50% 0,
                           0 25%,
                           0 75%,
                           50% 100%,
                           100% 75%,
                           100% 25%);
      }
      // @media (max-width: $very-small-width-breakpoint) {
      //   $image-frame-width: 240px;
      //   top: 2.9em;
      //   right: auto;
      //   align-self: center;
      //   width: $very-small-width-breakpoint;
      //   height: 1.1547 * $image-frame-width;
      //   margin-left: 0em;
      //   clip-path: polygon(50% 0,
      //                      (50% * ($very-small-width-breakpoint - $image-frame-width) / $very-small-width-breakpoint) 25%,
      //                      0 25%,
      //                      0 75%,
      //                      (50% * ($very-small-width-breakpoint - $image-frame-width) / $very-small-width-breakpoint) 75%,
      //                      50% 100%,
      //                      ((100% * $image-frame-width / $very-small-width-breakpoint) + (50% * ($very-small-width-breakpoint - $image-frame-width) / $very-small-width-breakpoint)) 75%,
      //                      100% 75%,
      //                      100% 25%,
      //                      ((100% * $image-frame-width / $very-small-width-breakpoint) + (50% * ($very-small-width-breakpoint - $image-frame-width) / $very-small-width-breakpoint)) 25%);
      // }
      @media (max-width: $very-small-width-breakpoint) {
        $image-frame-width: 15em;
        top: 2.9em;
        right: auto;
        align-self: center;
        width: $image-frame-width;
        height: 1.1547 * $image-frame-width;
        margin-left: 0em;
        clip-path: polygon(50% 0,
                           0 25%,
                           0 75%,
                           50% 100%,
                           100% 75%,
                           100% 25%);
      }
      @media (max-width: $tiny-width-breakpoint) {
        $image-frame-width: 14.5em;
        top: 4em;
        right: auto;
        align-self: center;
        width: $image-frame-width;
        height: 1.1547 * $image-frame-width;
        margin-left: 0em;
        clip-path: polygon(50% 0,
                           0 25%,
                           0 75%,
                           50% 100%,
                           100% 75%,
                           100% 25%);
      }
    }

    &__service-image {
      // transform: translateX(0);
      transform: scale(1);
      opacity: 1;
      z-index: 2;

      &,
      &--hidden {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        // transition: transform 1s cubic-bezier(0.26, 0.39, 0.46, 1), opacity 1s cubic-bezier(0.26, 0.39, 0.46, 1);
        transition: transform 9s ease-out, opacity 1.5s ease-in-out;
        transform-origin: 25% 25%;
      }

      &--hidden {
        // transform: translateX(40%);
        transform: scale(1.1);
        opacity: 0;
        z-index: 1;
      }
    }

    &__detail {
      position: absolute;
      display: block;
      background: #ff4b00;
      width: $flow-circle-width;
      height: 1.2 * $flow-circle-width;
      top: -54.8em;
      border-radius: 50%;
      transform: translateX(-34.22em);

      @media (max-width: $large-width-breakpoint) {
        transform: translateX(-34.3em);
      }
      @media (max-width: $extra-high-width-breakpoint) {
        transform: translateX(-33.99em);
      }
      @media (max-width: $high-width-breakpoint) {
        transform: translateX(-29.7em);
        left: 0;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        top: -52.8em;
        transform: translateX(-33.2em);
      }
      @media (max-width: $medium-width-breakpoint) {
        transform: translateX(-38.3em);
        top: -53.8em;
        border-radius: 40%;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        height: 50em;
        width: 62em;
        top: -34.6em;
        border-radius: 50%;
        transform: translateX(-45.25em);
      }
      @media (max-width: 758px) {
        top: -35.2em;
      }
      @media (max-width: 721px) {
        top: -35.8em;
      }
      @media (max-width: 708px) {
        top: -36.2em;
      }
      @media (max-width: 676px) {
        top: -36.8em;
      }
      @media (max-width: 667px) {
        top: -37.7em;
      }
      @media (max-width: 637px) {
        top: -38.3em;
      }
      @media (max-width: 620px) {
        top: -38.8em;
      }
      @media (max-width: 608px) {
        top: -39.3em;
      }
      @media (max-width: $small-width-breakpoint) {
        height: 60em;
        width: 55em;
        top: -36em;
        transform: translateX(10.25em);
      }
      @media (max-width: $very-small-width-breakpoint) {
        left: auto;
        top: -34em;
        transform: translateX(24.4em);
      }
      @media (max-width: 416px) {
        top: -30em;
        transform: translateX(26.7em);
      }
      @media (max-width: 346px) {
        top: -28em;
        transform: translateX(29em);
      }
    }

    &__description {
      text-shadow: 13px -12px 0px #ff421524;

      &,
      &--selected {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        color: #ff4215;
        font-weight: 600;
        font-size: 1.25em;
        text-transform: uppercase;
        text-align: justify;
        margin: 0.8em 0 0;
        max-width: 35em;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.18em;
          max-width: 34em;
        }
        @media (max-width: $extra-high-width-breakpoint) {
          font-size: 1.1em;
          max-width: 33em;
        }
        @media (max-width: $high-width-breakpoint) {
          max-width: none;
          margin: 1em 10em 0 0;
          font-size: 1.05em;
          font-weight: 500;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          font-size: 1em;
          margin: 0.8em 8em 0 0;
        }
        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.95em;
          margin: 0.6em 8em 0 0;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 0.9em;
          margin: 0.4em 10em 0 0;
        }
        @media (max-width: 780px) {
          margin: 0.4em 1em 0 0;
        }
        @media (max-width: $small-width-breakpoint) {
          margin: 0.6em 0 0;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.85em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.8em;
        }
      }

      @media (max-width: $small-width-breakpoint) {
        align-self: stretch;
        justify-content: center;
      }

      &--selected {
        text-indent: 2em;

        @media (max-width: $high-width-breakpoint) {
          display: inline;

          &::before {
            $text-height: 10em;
            content: '';
            float: right;
            height: $text-height;
            width: ($text-height * 1.732051);
            shape-outside: polygon(100% 100%, 100% 0, 0 0);
          }
        }
        @media (max-width: $medium-width-breakpoint) {
          &::before {
            $text-height: 10.5em;
            height: $text-height;
            width: ($text-height * 1.732051);
            shape-outside: polygon(100% 100%, 100% 0, 0 0);
          }
        }
        @media (max-width: 780px) {
          &::before {
            margin: 0 40% 0 0;
            $text-width-offset: -5em;
            $text-height: 10.5em;
            $polygon-height: 3em;
            $block-width: ((1.732051 * $polygon-height) + $text-width-offset);
            height: $text-height;
            width: $block-width;
            shape-outside: polygon(100% 0,
                                   0 0,
                                   (1.732051 * $polygon-height) ($polygon-height),
                                   100% (100% * $polygon-height / $text-height));
          }
        }
        @media (max-width: $small-width-breakpoint) {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  &__video-section {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: white;

    &__content {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      max-width: $content-max-width;
      padding: 5em 0.8em 4em;
      overflow: hidden;

      @media (max-width: $extra-high-width-breakpoint) {
        padding: 4.5em 1.8em 3.5em 3.8em;
      }

      @media (max-width: $high-width-breakpoint) {
        padding: 4em 1.8em 3em 3.8em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        padding: 3.5em 1.2em 2.5em 3em;
      }

      @media (max-width: $medium-width-breakpoint) {
        flex-flow: column nowrap;
        justify-content: flex-start;
        padding: 2.5em 1.5em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        padding: 2.5em 1em;
      }

      @media (max-width: $small-width-breakpoint) {
        padding: 1.5em 0.8em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        padding: 0.2em 0.8em 1em;
      }
    }

    &__video-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin: 0 5.5em 0 0;

      @media (max-width: $large-width-breakpoint) {
        margin: 0 5em 0 0;
      }

      @media (max-width: $high-width-breakpoint) {
        margin: 0 4em 0 0;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        margin: 0 3.2em 0 0;
      }

      @media (max-width: $medium-width-breakpoint) {
        margin: 0 0 2em;
      }

      @media (max-width: $small-width-breakpoint) {
        margin: 0 0 1.2em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        margin: 0 0 0.8em;
      }
    }

    &__video {
      border: 1px solid $default-text-color;
      background: $default-text-color;
      border-radius: 3px;
      z-index: 2;

      @media (max-width: $very-small-width-breakpoint) {
        border-radius: 0;
      }
    }

    &__decoration-container {
      position: absolute;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      height: 28em;
      width: 43em;
      overflow: hidden;

      @media (max-width: $large-width-breakpoint) {
        height: 24em;
      }

      @media (max-width: $extra-high-width-breakpoint) {
        width: 39em;
      }

      @media (max-width: $high-width-breakpoint) {
        height: 21em;
        width: 37em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        width: 33em;
        height: 21em;
      }

      @media (max-width: $medium-width-breakpoint) {
        width: 100%;
        height: 24em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        height: 20em;
      }

      @media (max-width: $small-width-breakpoint) {
        height: 20em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        display: none;
      }
    }

    &__decorative-line {
      &--1,
      &--2 {
        display: block;
        position: absolute;
        height: 20em;

        @media (max-width: $large-width-breakpoint) {
          height: 18em;
        }

        @media (max-width: $small-width-breakpoint) {
          height: 16em;
        }
      }

      &--1 {
        margin: 0 0 0 15em;
        top: 0;

        @media (max-width: $extra-high-width-breakpoint) {
          margin: 0 0 0 13.5em;
        }

        @media (max-width: $high-width-breakpoint) {
          margin: 0 0 0 12.5em;
        }

        @media (max-width: $medium-plus-width-breakpoint) {
          margin: 0 0 0 10.5em;
        }

        @media (max-width: $medium-width-breakpoint) {
          margin: 0 0 0 17.5em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0 0 0 15em;
        }

        @media (max-width: $small-width-breakpoint) {
          margin: 0 0 0 15.5em;
        }
      }

      &--2 {
        margin: 0 0 0 -15.5em;
        bottom: 0;

        @media (max-width: $extra-high-width-breakpoint) {
          margin: 0 0 0 -13.5em;
        }

        @media (max-width: $high-width-breakpoint) {
          margin: 0 0 0 -12.5em;
        }

        @media (max-width: $medium-plus-width-breakpoint) {
          margin: 0 0 0 -10.5em;
        }

        @media (max-width: $medium-width-breakpoint) {
          margin: 0 0 0 -17.5em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0 0 0 -15em;
        }

        @media (max-width: $small-width-breakpoint) {
          margin: 0 0 0 -15.5em;
        }
      }
    }

    &__description-container {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      color: $default-text-color;

      @media (max-width: $medium-width-breakpoint) {
        max-width: 53em;
        border: 0 solid $site-red-color;
        border-width: 0 0 0 6px;
        padding: 0 0 0 0.5em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        max-width: 48em;
        border-width: 0 0 0 4px;
      }

      @media (max-width: $small-width-breakpoint) {
        padding: 0 0 0 0.3em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        padding: 0;
        border: none;
        align-items: center;
      }
    }

    &__title {
      font-size: 2em;
      font-weight: 800;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.8em;
      }

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.55em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.65em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.55em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.45em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.3em;
        text-align: center;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1.25em;
      }
    }

    &__semi-title {
      font-size: 1.6em;
      padding: 0 0 0 0.4em;
      margin: 0.2em 0 0.8em;
      font-weight: 600;
      border: 0 solid $site-red-color;
      border-width: 0 0 0 6px;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.45em;
      }

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.25em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.35em;
        border-width: 0;
        padding: 0;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.25em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.15em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        border-width: 0 4px;
        padding: 0 0.4em;
        font-size: 1.05em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        border-width: 0 2px;
      }
    }

    &__paragraph {
      color: $gray-text-color;
      text-align: justify;
      font-size: 1.21em;
      font-weight: 500;

      &:not(:last-of-type) {
        margin: 0 0 1em;
      }

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $high-width-breakpoint) {
        font-size: 1em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.95em;
      }
    }

    &__experimental-class-link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-decoration: none;
      text-transform: capitalize;
      font-size: 1.3em;
      padding: 0 0.8em;
      margin: 1.2em 0 0;
      font-weight: 400;
      border-radius: 1em;
      background: $site-red-color;
      border: 0 solid $site-red-color;
      color: white;
      min-width: 35px;
      min-height: 35px;
      transition: $default-transition;

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.2em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.2em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        border-radius: 2px;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1em;
      }

      &:hover {
        color: $site-red-color;
        border-width: 1px;
        background: white;
        transform: scale(1.05, 1.05);
      }
    }
  }

  &__location-section {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    transform: translateZ(0);
    height: 34.25vw;

    @media (max-width: $large-width-breakpoint) {
      height: 34.2vw;
    }

    @media (max-width: $extra-high-width-breakpoint) {
      height: 34.15vw;
    }

    @media (max-width: $high-width-breakpoint) {
      height: 34.05vw;
    }

    @media (max-width: $medium-width-breakpoint) {
      height: 33.9vw;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      height: 17.5em;
    }

    @media (max-width: $small-width-breakpoint) {
      height: 14.5em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      height: 10.5em;
    }

    @media (max-width: $tiny-width-breakpoint) {
      height: 8.5em;
    }

    &__images-container {
      position: absolute;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      overflow: hidden;
    }

    &__mask {
      &--1,
      &--2 {
        object-fit: cover;
        width: 100%;
      }

      &--1 {
        object-position: center center;
      }

      &--2 {
        object-position: left center;
        height: 17.5em;

        @media (max-width: $small-width-breakpoint) {
          height: 14.5em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          height: 10.5em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          height: 8.5em;
        }
      }
    }

    &__background {
      position: absolute;
      top: 189em;
      right: 45%;
      width: 100%;
      transform: translateZ(-1px) scale(1.2);
      z-index: -1;

      @media (max-width: $large-width-breakpoint) {
        top: 179em;
      }

      @media (max-width: $extra-high-width-breakpoint) {
        transform: translateZ(-1px) scale(1.3);
        right: 45%;
        top: 172em;
      }

      @media (max-width: $high-width-breakpoint) {
        transform: translateZ(-1px) scale(1.4);
        top: 158.5em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        right: 42%;
        top: 144em;
      }

      @media (max-width: $medium-width-breakpoint) {
        transform: translateZ(-1px) scale(1.5);
        right: 46%;
        top: 142em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        transform: translateZ(-0.35px) scale(1.2);
        width: 35em;
        left: -16em;
        top: 152.5em;
      }

      @media (max-width: 780px) {
        transform: translateZ(-0.3px) scale(1.2);
        top: 161em;
      }

      @media (max-width: $small-width-breakpoint) {
        transform: translateZ(-0.3px) scale(1.2);
        top: 153.5em;
      }

      @media (max-width: 515px) {
        top: 162.5em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        transform: translateZ(-0.32px) scale(1.05);
        top: 164em;
      }

      @media (max-width: 450px) {
        transform: translateZ(-0.32px) scale(1);
        top: 164em;
      }

      // @media (max-width: 405px) {
      //   top: 164em;
      // }

      // @media (max-width: 390px) {
      //   top: 170.2em;
      // }

      @media (max-width: $tiny-width-breakpoint) {
        transform: translateZ(-0.3px) scale(1);
        top: 163em;
      }

      @media (max-width: 330px) {
        top: 164em;
      }
    }

    &__wrapper {
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      height: 30vw;
      padding: 0 0 0 56vw;

      @media (max-width: $medium-plus-width-breakpoint) {
        // height: 30vw;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        height: 100%;
        width: 100%;
        padding: 0 0 0 13em;
        align-items: center;
      }

      @media (max-width: $small-width-breakpoint) {
        padding: 0 0 0 11em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        padding: 0 0 0 8em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        padding: 0 0 0 6.5em;
      }
    }

    &__title {
      font-size: 2.5em;
      font-weight: 600;
      max-width: 10em;
      text-transform: uppercase;
      color: white;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2.3em;
      }

      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 2em;
      }

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.8em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.4em;
        max-width: 12em;
        text-align: center;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1em;
      }
    }

    &__link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.5em;
      padding: 0 0.8em;
      margin: 1.2em 0 0;
      font-weight: 700;
      border-radius: 1em;
      background: white;
      border: 0 solid $default-text-color;
      color: $site-red-color;
      min-width: 35px;
      min-height: 35px;
      transition: all 0.1s ease-out;

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.2em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.2em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        border-radius: 2px;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1em;
      }

      &:hover {
        color: white;
        border-width: 1px;
        background: $default-text-color;
      }
    }

    &__link-icon {
      margin: 0 0.3em 0 0;
    }
  }

  &__blog-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 4.5em 0;

    @media (max-width: $extra-high-width-breakpoint) {
      padding: 3em 0;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      padding: 2em 0;
    }

    @media (max-width: $small-width-breakpoint) {
      padding: 1em 0;
    }

    &__wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: stretch;
      max-width: $content-max-width;
      width: 100%;
      padding: 0 0 0 1em;

      @media (max-width: $extra-high-width-breakpoint) {
        padding: 0 1em;
      }
      @media (max-width: $small-width-breakpoint) {
        padding: 0 0.8em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        padding: 0 0.4em;
      }
    }

    &__title {
      font-size: 2.15em;
      font-weight: 800;
      color: $default-text-color;
      margin: 0 0 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2em;
      }

      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 1.8em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1.3em;
      }
    }

    &__sub-title {
      font-size: 1.6em;
      font-weight: 600;
      color: $default-text-color;
      border: 0 solid $site-red-color;
      border-width: 0 0 0 8px;
      padding: 0 0 0 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.2em;
        border-width: 0 0 0 6px;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.1em;
        border-width: 0 0 0 4px;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1em;
      }
    }

    &__articles-container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: stretch;
      margin: 0.6em 0 0;
      width: 100%;

      @media (max-width: $medium-less-width-breakpoint) {
        flex-flow: column nowrap;
        justify-content: flex-start;
      }

      @media (max-width: $very-small-width-breakpoint) {
        margin: 1em 0 0;
      }
    }

    &__link {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      max-width: 28em;
      cursor: pointer;
      text-decoration: none;

      @media (max-width: $large-width-breakpoint) {
        font-size: 0.95em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: none;
        width: 100%;
      }

      @media (max-width: $very-small-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
      }
    }

    &__article {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: stretch;
      transform: scale(1, 1);
      transition: $default-transition;

      @media (max-width: $medium-less-width-breakpoint) {
        padding: 0.5em;
        border: 1px solid $default-text-color;
        border-radius: 2px;
      }

      @media (max-width: $very-small-width-breakpoint) {
        padding: 0;
        border: none;
      }

      &:not(:last-of-type) {
        margin: 0 2em 0 0;

        @media (max-width: $extra-high-width-breakpoint) {
          margin: 0 1em 0 0;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0;
        }
      }

      @media (min-width: $medium-less-width-breakpoint) {
        &:hover {
          transform: scale(1.1, 1.1);

          @media (max-width: $extra-high-width-breakpoint) {
            transform: scale(1.05, 1.05);
          }
        }
      }

      &__image-wrapper {
        &--1,
        &--2,
        &--3 {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          height: 18em;

          @media (max-width: $high-width-breakpoint) {
            height: 22vw;
          }

          @media (max-width: $medium-less-width-breakpoint) {
            height: 11em;
            margin: 0 1em 0 0;
          }

          @media (max-width: $small-width-breakpoint) {
            height: 8em;
            margin: 0 0.5em 0 0;
          }

          @media (max-width: $very-small-width-breakpoint) {
            height: 12em;
            margin: 0 0 0.6em;
          }

          @media (max-width: $tiny-width-breakpoint) {
            height: 11em;
          }
        }

        &--2 {
          @media (max-width: $medium-less-width-breakpoint) {
            order: 2;
            margin: 0 0 0 1em;
          }

          @media (max-width: $very-small-width-breakpoint) {
            order: 1;
            margin: 0 0 0.6em;
          }
        }
      }

      &__image {
        width: 100%;
        border-radius: 2px;

        @media (max-width: $medium-less-width-breakpoint) {
          height: 100%;
          width: auto;
        }
      }

      &__title {
        font-size: 1.2em;
        font-weight: 600;
        color: $default-text-color;
        text-align: justify;

        @media (max-width: $extra-high-width-breakpoint) {
          font-size: 1.1em;
        }

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          max-width: 25em;
          text-align: left;
        }

        @media (max-width: $very-small-width-breakpoint) {
          text-align: center;
        }
      }

      &__date {
        margin: 0.3em 0 0;
        font-size: 0.9em;
        font-weight: 600;
        color: $gray-text-color;
      }

      &__text-wrapper {
        &--1,
        &--2,
        &--3 {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;

          @media (max-width: $medium-less-width-breakpoint) {
            flex: 1;
          }

          @media (max-width: $very-small-width-breakpoint) {
            align-items: center;
          }
        }

        &--2 {
          order: 1;
          text-align: right;

          @media (max-width: $medium-less-width-breakpoint) {
            align-items: flex-end;
          }

          @media (max-width: $very-small-width-breakpoint) {
            order: 2;
            align-items: center;
          }
        }
      }

      &__text-wrapper--2 &__title {
        @media (max-width: $medium-less-width-breakpoint) {
          text-align: right;
        }

        @media (max-width: $very-small-width-breakpoint) {
          text-align: center;
        }
      }
    }

    &__hr {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      width: 30%;
      background: $site-red-color;
      height: 1px;
      border: none;
      margin: 0.6em 0;
      align-self: center;

      @media (max-width: $very-small-width-breakpoint) {
        margin: 1.2em 0;
      }

      @media (max-width: $tiny-width-breakpoint) {
        margin: 1em 0;
      }

      &::after {
        position: absolute;
        display: block;
        content: '';
        height: 3px;
        width: 30px;
        border-radius: 4px;
        background: $site-red-color;
      }
    }
  }

  &__features-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background: $site-blue-color;
    padding: 4.5em 0 2em;

    @media (max-width: $extra-high-width-breakpoint) {
      padding: 3em 0 1.5em;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      padding: 2em 0 1em;
    }

    @media (max-width: $small-width-breakpoint) {
      padding: 1.8em 0 1em;
    }

    &__wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: stretch;
      max-width: $content-max-width;
      width: 100%;
      padding: 0 0 0 1em;

      @media (max-width: $extra-high-width-breakpoint) {
        padding: 0 1em;
      }
      @media (max-width: $small-width-breakpoint) {
        padding: 0 0.8em;
      }
    }

    &__title {
      font-size: 2.15em;
      font-weight: 800;
      color: white;
      margin: 0 0 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2em;
      }

      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 1.8em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1.2em;
      }
    }

    &__sub-title {
      font-size: 1.6em;
      font-weight: 600;
      color: white;
      border: 0 solid $site-red-color;
      border-width: 0 0 0 8px;
      padding: 0 0 0 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.2em;
        border-width: 0 0 0 6px;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.1em;
        border-width: 0 0 0 4px;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1em;
      }
    }

    &__list {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: space-around;
      margin: 6em 0 0;

      @media (max-width: $large-width-breakpoint) {
        margin: 5em 0 0;
      }

      @media (max-width: $extra-high-width-breakpoint) {
        margin: 3.5em 0 0;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        margin: 3em 0 0;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        margin: 2.5em 0 0;
      }

      @media (max-width: $very-small-width-breakpoint) {
        margin: 1.5em 0 0;
      }

      @media (max-width: $tiny-width-breakpoint) {
        margin: 1em 0 0;
      }
    }

    &__item {
      &,
      &--right {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        max-width: 32%;
        margin: 0 0 3em;

        @media (max-width: $large-width-breakpoint) {
          margin: 0 0 2.5em;
        }

        @media (max-width: $extra-high-width-breakpoint) {
          margin: 0 0 2em;
        }

        @media (max-width: $medium-plus-width-breakpoint) {
          margin: 0 0 1.5em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          max-width: 48%;
        }

        @media (max-width: $small-width-breakpoint) {
          max-width: 99.5%;
        }

        @media (max-width: $very-small-width-breakpoint) {
          margin: 0 0 1em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          margin: 0 0 0.6em;
          border: 1px dashed white;
          border-radius: 2px;
          padding: 0.6em 0.3em;
        }
      }

      &--right &__image-wrapper {
        order: 2;
        margin: 0 0 0 1em;

        @media (max-width: $medium-width-breakpoint) {
          margin: 0 0 0 0.6em;
        }

        @media (max-width: $small-width-breakpoint) {
          margin: 0 0 0 0.4em;
        }
      }

      &__image-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        margin: 0 1em 0 0;

        @media (max-width: $medium-width-breakpoint) {
          margin: 0 0.6em 0 0;
        }

        @media (max-width: $small-width-breakpoint) {
          padding: 0 0.5em;
          margin: 0 0.4em 0 0;
          border: 1px dashed white;
          border-radius: 3px;
          justify-content: center;
        }

        @media (max-width: $very-small-width-breakpoint) {
          padding: 0 0.4em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          display: none;
        }
      }

      &__image {
        width: 3.2em;

        @media (max-width: $extra-high-width-breakpoint) {
          width: 3em;
        }

        @media (max-width: $medium-plus-width-breakpoint) {
          width: 2.5em;
        }

        @media (max-width: $medium-width-breakpoint) {
          width: 2em;
        }

        @media (max-width: $small-width-breakpoint) {
          width: 2.6em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          width: 2em;
        }
      }

      &__content {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &--right &__title {
        text-align: right;
        align-self: flex-end;
      }

      &__title {
        font-size: 1.5em;
        font-weight: 500;
        color: white;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.4em;
        }

        @media (max-width: $extra-high-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $medium-plus-width-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1em;
          font-weight: 600;
        }

        &::after {
          display: block;
          content: '';
          margin: 0.1em 0 0;
          width: 9em;
          background: $site-red-color;
          height: 2px;
        }
      }

      &__text {
        font-size: 1.2em;
        font-weight: 300;
        margin: 0.4em 0 0;
        color: white;
        text-align: justify;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.15em;
        }

        @media (max-width: $extra-high-width-breakpoint) {
          font-size: 1.1em;
        }

        @media (max-width: $medium-plus-width-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.95em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          margin: 0.2em 0 0;
          font-size: 0.95em;
        }
      }
    }
  }

  &__gallery-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 4.5em 0 2em;

    @media (max-width: $extra-high-width-breakpoint) {
      padding: 3em 0 1.5em;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      padding: 2em 0 1em;
    }

    @media (max-width: $small-width-breakpoint) {
      padding: 1.8em 0 1em;
    }

    &__wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: stretch;
      max-width: $content-max-width;
      width: 100%;
      padding: 0 0 0 1em;

      @media (max-width: $extra-high-width-breakpoint) {
        padding: 0 1em;
      }
      @media (max-width: $small-width-breakpoint) {
        padding: 0 0.8em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        padding: 0 0.4em;
      }
    }

    &__title {
      font-size: 2.15em;
      font-weight: 800;
      color: $default-text-color;
      margin: 0 0 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2em;
      }

      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 1.8em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1.2em;
      }
    }

    &__sub-title-wrapper {
      border: 0 solid $site-red-color;
      border-width: 0 0 0 8px;
      padding: 0 0 0 0.2em;
    }

    &__sub-title {
      font-size: 1.6em;
      font-weight: 600;
      color: $default-text-color;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.2em;
        border-width: 0 0 0 6px;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.1em;
        border-width: 0 0 0 4px;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1em;
      }
    }

    &__sub-description {
      font-weight: bold;
      color: $default-text-color;
      font-size: 0.96em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 0.84em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 0.78em;
      }

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.72em;
        border-width: 0 0 0 6px;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.66em;
        border-width: 0 0 0 4px;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.6em;
      }
    }

    &__cities-list {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: flex-start;
      list-style: none;
      margin: 0.8em 0 0;

      @media (max-width: $medium-width-breakpoint) {
        margin: 0 0 0.3em;
      }
    }

    &__city-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      &:not(:last-of-type) {
        margin: 0 0.5em 0 0;
      }
    }

    &__city-button {
      color: $gray-text-color;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        border: 1px solid $site-red-color;
        border-radius: 3px;
        color: $site-red-color;
      }

      &,
      &--selected {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.4em;
        padding: 0.5em;
        background: none;
        outline: none;
        -webkit-tap-highlight-color:  transparent;
        border: none;
        user-select: none;
        min-width: 35px;
        min-height: 35px;
        border: 1px solid white;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.3em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 1.2em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 1.1em;
        }
      }

      &--selected {
        color: $site-red-color;
        text-decoration: underline;
        cursor: default;
      }
    }

    &__photos-container {
      &--7,
      &--14 {
        width: 100%;
        display: grid;
        column-gap: 1.8em;
        row-gap: 1.8em;

        @media (max-width: $medium-less-width-breakpoint) {
          column-gap: 1em;
          row-gap: 1em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          column-gap: 0.7em;
          row-gap: 0.7em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          column-gap: 0.15em;
          row-gap: 0.15em;
        }
      }

      &--7 {
        grid-template:
          "photo_1 photo_2 photo_3"
          "photo_1 photo_4 photo_5"
          "photo_6 photo_7 photo_5";

        @media (max-width: $small-width-breakpoint) {
          grid-template:
            "photo_1 photo_2"
            "photo_1 photo_3"
            "photo_4 photo_4"
            "photo_7 photo_5"
            "photo_6 photo_5";
        }
      }

      &--14 {
        grid-template:
          "photo_1 photo_2 photo_3"
          "photo_1 photo_4 photo_5"
          "photo_6 photo_7 photo_5"
          "photo_8 photo_9 photo_10"
          "photo_8 photo_11 photo_12"
          "photo_13 photo_14 photo_12";

        @media (max-width: $small-width-breakpoint) {
          grid-template:
            "photo_1 photo_2"
            "photo_1 photo_3"
            "photo_4 photo_4"
            "photo_7 photo_5"
            "photo_6 photo_5"
            "photo_8 photo_9"
            "photo_8 photo_10"
            "photo_11 photo_11"
            "photo_14 photo_12"
            "photo_13 photo_12";
        }
      }
    }

    &__photo {
      &--1,
      &--2,
      &--3,
      &--4,
      &--5,
      &--6,
      &--7,
      &--8,
      &--9,
      &--10,
      &--11,
      &--12,
      &--13,
      &--14 {
        cursor: pointer;
        transform: scale(1, 1);
        transition: $default-transition;

        &:hover {
          transform: scale(1.04, 1.04);
        }
      }

      &--1 {
        grid-area: photo_1;
      }
      &--2 {
        grid-area: photo_2;
      }
      &--3 {
        grid-area: photo_3;
      }
      &--4 {
        grid-area: photo_4;
      }
      &--5 {
        grid-area: photo_5;
      }
      &--6 {
        grid-area: photo_6;
      }
      &--7 {
        grid-area: photo_7;
      }
      &--8 {
        grid-area: photo_8;
      }
      &--9 {
        grid-area: photo_9;
      }
      &--10 {
        grid-area: photo_10;
      }
      &--11 {
        grid-area: photo_11;
      }
      &--12 {
        grid-area: photo_12;
      }
      &--13 {
        grid-area: photo_13;
      }
      &--14 {
        grid-area: photo_14;
      }

      &__image {
        display: block;
        height: 100%;
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
  }

  &__section-ruler {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right, $site-red-color, #ff4b00);
    width: 100%;
    height: 3px;
    border: none;
    margin: 0;

    &__image {
      max-width: 1em;
    }
  }
}
