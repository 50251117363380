@import '../color_variables.scss';
@import '../size_variables';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$default-animation-period: 0.6s;
$default-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.31, 1.78);

$content-max-width: 75em;

$background-dark-color: #212121;

.experimental-class {
  margin: 6em 0 0;

  @media (max-width: 1030px) {
    margin: 5em 0 0;
  }
  @media (max-width: 910px) {
    margin: 8em 0 0;
  }
  @media (max-width: 750px) {
    margin: 4.3em 0 0;
  }
  @media (max-width: 490px) {
    margin: 3.7em 0 0;
  }

  &,
  &--promotion-enalbed {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    // background: #212930;
    background: radial-gradient(#353535, $background-dark-color);
    flex: 1;
    min-height: 10em;
  }

  &--promotion-enalbed {
    margin: 11em 0 0;

    @media (max-width: 1030px) {
      margin: 10em 0 0;
    }
    @media (max-width: 910px) {
      margin: 13em 0 0;
    }
    @media (max-width: 750px) {
      margin: 9.3em 0 0;
    }
    @media (max-width: 490px) {
      margin: 8.7em 0 0;
    }
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    z-index: 1;
    max-width: $content-max-width;
    padding: 3.5em 0.8em 3em;
    color: white;
    flex: 1;

    @media (max-width: $large-width-breakpoint) {
      padding: 3.5em 0.8em 3em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      padding: 3em 1.8em;
    }
    @media (max-width: $high-width-breakpoint) {
      padding: 2.5em 1.8em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      padding: 2.5em 1.2em;
    }
    @media (max-width: $medium-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      padding: 2.5em 1em;
    }
    @media (max-width: $small-width-breakpoint) {
      padding: 1.5em 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      padding: 1.2em 0.8em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 1.2em 0.4em;
    }
  }

  &__title {
    font-size: 2.2em;
    font-weight: 700;
    // text-transform: uppercase;

    @media (max-width: $large-width-breakpoint) {
      font-size: 2.1em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      font-size: 2em;
    }
    @media (max-width: $high-width-breakpoint) {
      font-size: 1.8em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      font-size: 1.7em;
    }
    @media (max-width: $medium-width-breakpoint) {
      align-self: center;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.6em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 1.45em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1.3em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 1.2em;
      text-align: center;
    }
  }

  &__message {
    margin: 0.5em 0 0;
    font-size: 1.4em;
    padding: 0 0 0 0.4em;
    border: 0 solid $site-red-color;
    border-left-width: 4px;
    text-align: justify;
    font-weight: 300;

    @media (max-width: $large-width-breakpoint) {
      font-size: 1.35em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      font-size: 1.3em;
    }
    @media (max-width: $high-width-breakpoint) {
      font-size: 1.2em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      font-size: 1.15em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.1em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 1.05em;
      border-left-width: 3px;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1em;
      border-left-width: 2px;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.95em;
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100vh;
  }

  &__entries-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 2em 0 0;

    @media (max-width: $high-width-breakpoint) {
      margin: 1.5em 0 0;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      margin: 1em 0 0;
    }
    @media (max-width: $very-small-width-breakpoint) {
      margin: 0.5em 0 0;
    }
  }

  &__warning-message {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    align-self: center;
    margin: 3em 0 0;
    max-width: 40em;
    background: white;
    border-radius: 5px;
    font-size: 1em;

    @media (max-width: $large-width-breakpoint) {
      font-size: 0.95em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      font-size: 0.9em;
      margin: 2.5em 0 0;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      margin: 2em 0 0;
    }
    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.85em;
      margin: 1.5em 0 0;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.75em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: justify;
      font-size: 1.1em;
      font-weight: 600;
      flex: 1;
      color: $site-red-color;
      padding: 0.5em 1em;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: $site-red-color;
      color: white;
      padding: 0.8em;
      border-radius: 5px 0 0 5px;
      font-size: 1.8em;
    }
  }

  &__select-service {
    &__header {
      &,
      &--highlighted {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        transition: $default-animation-period background;
        background: transparent;
        border-radius: 3px;
        padding: 0.2em 0 0.2em 0.4em;
      }

      &--highlighted {
        background: white;
        cursor: pointer;
      }

      &__icon {
        margin: 0 0.5em 0 0;
        color: $background-dark-color;
      }

      &__text {
        color: white;

        &,
        &--highlighted {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          transition: $default-animation-period color;
          font-size: 1.5em;

          @media (max-width: $large-width-breakpoint) {
            font-size: 1.4em;
          }
          @media (max-width: $extra-high-width-breakpoint) {
            font-size: 1.35em;
          }
          @media (max-width: $high-width-breakpoint) {
            font-size: 1.3em;
          }
          @media (max-width: $medium-plus-width-breakpoint) {
            font-size: 1.25em;
          }
          @media (max-width: $medium-less-width-breakpoint) {
            font-size: 1.2em;
          }
          @media (max-width: $small-width-breakpoint) {
            font-size: 1.1em;
          }
          @media (max-width: $very-small-width-breakpoint) {
            font-size: 1.05em;
          }
          @media (max-width: $tiny-width-breakpoint) {
            font-size: 1em;
          }
        }

        &--highlighted {
          color: $background-dark-color;
        }

         & &--undelined {
          text-decoration: underline;
        }
      }

      &__service-text {
        color: $site-red-color;
        margin: 0 0 0 0.5em;
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
      }
    }

    &__wrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 1em 0 0;

      @media (max-width: $medium-less-width-breakpoint) {
        justify-content: space-around;
      }
    }

    &__option {
      &,
      &--selected {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        background: none;
        outline: none;
        -webkit-tap-highlight-color:  transparent;
        border: none;
        user-select: none;
        min-width: 35px;
        min-height: 35px;
        margin: 0 0 0.5em;
        padding: 1em 2em;
        cursor: pointer;
        border-radius: 5px;

        @media (max-width: $high-width-breakpoint) {
          font-size: 0.75em;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          font-size: 0.7em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 0.65em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.6em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.55em;
        }
      }

      &--selected {
        background: white;
      }

      &:disabled {
        cursor: default;
      }

      &__img-wrapper {
        position: relative;
        height: 1.155 * 12em;
        width: 12em;
        font-size: 1em;
      }

      &__img-detail {
        position: absolute;
        display: block;
        object-fit: contain;
        width: 100%;
        height: 100%;
        transform-origin: center;
        transform: scale(1.02) rotate(5deg);
        will-change: transform;
        transition: $default-animation-period transform;
      }

      &:not(:disabled):hover &__img-detail,
      &--selected &__img-detail {
        transform: scale(1.06) rotate(10deg);
      }

      &__unavailable {
        position: absolute;
        top: 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        clip-path: polygon(50% 0,100% 25%,100% 75%,50% 100%,0 75%,0 25%);
        background: #282828b5;

        &__text {
          font-weight: 700;
          color: #b3b3b3;
          text-transform: uppercase;
          font-size: 1.1em;
        }
      }

      &__img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        clip-path: polygon(50% 0,100% 25%,100% 75%,50% 100%,0 75%,0 25%);
      }

      &__text {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.2em;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: white;
        margin: 1em 0 0;
        width: (12em / 1.2);
        transition: $default-animation-period color;
        flex: 1;
      }

      &:disabled &__text {
        color: #888888;
      }
      &:not(:disabled):hover &__text,
      &--selected &__text {
        color: $site-red-color;
      }
    }
  }

  &__form {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0.5em 0 0;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      transition: $default-animation-period background;
      background: transparent;
      border-radius: 3px;
      // padding: 0.2em 0 0.2em 0.4em;
      padding: 0;

      &__text {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        transition: $default-animation-period color;
        color: white;
        text-decoration: underline;
        font-size: 1.5em;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.4em;
        }
        @media (max-width: $extra-high-width-breakpoint) {
          font-size: 1.35em;
        }
        @media (max-width: $high-width-breakpoint) {
          font-size: 1.3em;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          font-size: 1.25em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 1.2em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 1.1em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.05em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 1em;
        }
      }
    }

    &__wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 1em 0 0;
    }

    &__warning-message {
      margin: 0.75em 0;
      align-self: flex-start;

      @media (max-width: $small-width-breakpoint) {
        width: 100%;
      }
    }

    &__submit-button {
      background: none;
      color: $site-red-color;
      cursor: default;

      &,
      &--enabled {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1.3em;
        padding: 0.4em 1.2em;
        margin: 0;
        font-weight: 400;
        border-radius: 3px;
        min-width: 35px;
        min-height: 35px;
        border: 1px solid $site-red-color;
        transition: $default-transition;
        outline: none;
        -webkit-tap-highlight-color:  transparent;
        user-select: none;
        align-self: flex-start;
        transform-origin: left;

        @media (max-width: $high-width-breakpoint) {
          font-size: 1.25em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 1.2em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 1.15em;
          width: 100%;
          padding: 0.4em 0;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.1em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 1em;
        }
      }

      &--enabled {
        background: $site-red-color;
        border: 1px solid $site-red-color;
        color: white;
        cursor: pointer;

        &:hover {
          color: $site-red-color;
          background: white;
          transform: scale(1.05, 1.05);
        }
      }
    }

    &__checkbox-input-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin: 1em 0;
      user-select: none;
      border: 1px solid #9e9fa1;
      border-radius: 2px;
      padding: 0.4em;

      @media (max-height: $medium-height-breakpoint) {
        margin: 0.5em 0;
      }

      @media (max-width: $small-width-breakpoint) {
        margin: 8px 0;
      }
    }

    &__checkbox-input {
      margin: 0 0.5em 0 0;
      cursor: pointer;
      min-width: 25px;
      min-height: 25px;

      @media (max-width: 960px) {
        font-size: 1.1em;
      }
    }

    &__checkbox-input-label {
      cursor: pointer;
      text-align: justify;

      @media (max-width: 960px) {
        font-size: 0.9em;
      }
    }
  }

  &__main-message {
    padding: 0.5em 1em;
    border: 1px solid white;
    border-radius: 5px;
    margin: 2em 0 0;

    &__text {
      color: white;
      font-size: 1.5em;
      text-align: justify;
      font-weight: 300;
    }
  }
}
