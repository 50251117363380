@import '../color_variables.scss';

.preloader {
  &__container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: white;
  }

  &__image {
    max-width: 100%;
  }
}
