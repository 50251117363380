@import '../../color_variables.scss';
@import '../../size_variables';

$unselected-border-color: #9e9fa1;
$unselected-text-color: #9e9fa1;

$options-side-margin: 5px;

$options-medium-width-breakpoint: 900px;

.disc-question {
  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    margin: 0;

    @media (max-width: $small-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    cursor: pointer;
    background: none;
    padding: 15px 20px;
    border: 1px solid $unselected-border-color;
    margin: 0;
    color: $unselected-text-color;
    user-select: none;
    font-size: 1.12em;
    min-width: 15px;
    min-height: 5px;
    outline: none;
    text-align: center;
    margin: 0;
    border-radius: 3px;
    height: 3.06em;

    @media (max-width: $small-width-breakpoint) {
      &:not(:first-of-type) {
        margin: 4px 0 0;
      }
    }

    @media (min-width: $small-width-breakpoint) {
      margin: 0 $options-side-margin;

      &:first-of-type {
        margin: 0 $options-side-margin 0 0;
      }

      &:last-of-type {
        margin: 0 0 0 $options-side-margin;
      }
    }

    &__score,
    &__text {
      text-align: center;
    }

    &__score {
      font-size: 1.1em;
      font-weight: bold;
    }

    &__text {
      width: 100%;
    }

    &.disc-score {
      &-4 {
        border-color: $site-red-color;
        color: $site-red-color;
      }

      &-3 {
        border-color: darken(desaturate($site-red-color, 33), 5);
        color: darken(desaturate($site-red-color, 33), 5);
      }

      &-2 {
        border-color: darken(desaturate($site-red-color, 66), 10);
        color: darken(desaturate($site-red-color, 66), 10);
      }

      &-1 {
        border-color: darken(desaturate($site-red-color, 100), 15);
        color: darken(desaturate($site-red-color, 100), 15);
      }
    }
  }
}
