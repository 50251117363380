@import '../constants.scss';
@import '../size_variables.scss';
@import '../color_variables.scss';

$error-color: #d43f3a;
$loading-color: #5f5f5f;
$warning-color: #ea5460;
$light-text-color: #777;


.overlay-window {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  z-index: 6;
  will-change: transform;
  top: 0;
  left: 0;

  @media (max-width: $smaller-width-breakpoint) {
    align-items: stretch;
  }

  &__container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    background: white;
    margin: 0.4em;
    border-radius: 8px;
    box-shadow: 3px 3px 3px #65656580;
    max-width: 98%;

    @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 0.95em;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.9em;
      border-radius: 4px;
    }

    @media (max-width: $smaller-width-breakpoint) {
      margin: 6.2em 0.4em 0;
      padding: 0.8em 0.5em !important;
    }
  }
}
