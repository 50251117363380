@import '../color_variables';

$animation-start-delay: 3s;
$shake-max-rotation: 20deg;

@keyframes chat-overlay-button-slide-in {
  from {
    opacity: 0;
    transform: scale(0.5) translateY(100%);
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes shake {
  from {
    border-radius: 50%;
    transform: rotate(0);
  }

  4% {
    border-radius: 0.4em;
    transform: rotate(0);
  }

  8% {
    border-radius: 0.4em;
    transform: rotate($shake-max-rotation);
  }

  12% {
    border-radius: 0.4em;
    transform: rotate(-$shake-max-rotation);
  }

  16% {
    border-radius: 0.4em;
    transform: rotate($shake-max-rotation);
  }

  20% {
    border-radius: 0.4em;
    transform: rotate(0);
  }

  24% {
    border-radius: 50%;
    transform: rotate(0);
  }

  to {
    border-radius: 50%;
    transform: rotate(0);
  }
}

@keyframes chat-overlay-button-still {
  from {
    border-radius: 50%;
    transform: rotate(0);
  }

  to {
    border-radius: 50%;
    transform: rotate(0);
  }
}

.chat-overlay-button {
  position: fixed;
  background: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  z-index: 2;
  will-change: transform;
  bottom: 0;
  right: 0;
  animation: chat-overlay-button-slide-in 0.8s cubic-bezier(0.13, 0.54, 0.48, 1.41) $animation-start-delay both;

  &__button {
    position: relative;
    bottom: 0.8em;
    right: 1em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 3.5em;
    height: 3.5em;
    background: #4dc247;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 2px 2px 5px 0px #000000b0;
    border: 2px solid white;
    animation: shake 4.5s cubic-bezier(0.13, 0.54, 0.48, 1.41) infinite both;
    transform-origin: center center;
    border-radius: 50%;
    font-size: 1em;

    @media (max-width: 1080px) {
      font-size: 0.95em;
    }
    @media (max-width: 870px) {
      font-size: 0.9em;
    }
    @media (max-width: 600px) {
      font-size: 0.85em;
    }
    @media (max-width: 470px) {
      font-size: 0.8em;
    }
    @media (max-width: 360px) {
      font-size: 0.75em;
    }

    &:hover {
      animation: chat-overlay-button-still 6s cubic-bezier(0.13, 0.54, 0.48, 1.41) both;
    }

    &__icon {
      color: white;
      font-size: 2em;
    }
  }
}
